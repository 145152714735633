import { SELECTABLE_PUBLIC_LANGUAGES } from "../../config/i18n";
import { useAppSelector } from "../../redux";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { HapticEffect, SupportedLanguagesEnum } from "../../interfaces";
import { iosGenerateHaptic } from "../../tools/ios";
import { Drawer } from "@mui/material";
import CustomIcon from "../CustomIcon";
import CustomRadio from "../CustomRadio";
import styled from "styled-components";

export default function LanguagePanel({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: Function;
}) {
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const roomAllowedLanguages = useAppSelector((state) => state.user.cache.currentRoom.data?.allowedLanguages);
  const { appLang, setAppLang, setAppLangTemp, t } = useAppLang();

  const availableLanguages = currentRoomId && !!roomAllowedLanguages.length
    ? roomAllowedLanguages
    : SELECTABLE_PUBLIC_LANGUAGES;

  function handleChange(lng: SupportedLanguagesEnum) {
    if (currentRoomId) {
      setAppLangTemp(lng);
    } else {
      setAppLang(lng);
    }
    iosGenerateHaptic(HapticEffect.SELECTION);
  }

  return (
    <PanelContainer
      anchor="left"
      open={isVisible}
      onClose={() => onClose()}
    >
      <div className="header">
        <h5>{t("common:lang.selectLanguage")}</h5>
        <CustomIcon
          iconName="close_alt"
          color="#FFFFFF"
          color2="#212121"
          onClick={() => onClose()}
        />
      </div>
      {Object.entries({
        [SupportedLanguagesEnum.EN]: t("common:lang.english"),
        [SupportedLanguagesEnum.FR]: t("common:lang.french"),
        [SupportedLanguagesEnum.ES]: t("common:lang.spanish"),
        [SupportedLanguagesEnum.IT]: t("common:lang.italian"),
      })
        .map(([lang, text], index) => {
          return availableLanguages.includes(lang as SupportedLanguagesEnum) ? (
            <CustomRadio
              key={index}
              selectColor='#FFC408'
              textStyle={{ color: "#fff", fontWeight: 700 }}
              onChange={() => handleChange(lang as SupportedLanguagesEnum)}
              checked={lang === appLang}
              title={text}
            />
          ) : null;
        })
      }
    </PanelContainer>
  );
}

const PanelContainer = styled(Drawer)`
  width: 100%;

  .MuiDrawer-paper {
    background: #ce0868;
    width: 100%;
    padding: 71px 24px;
    box-sizing: border-box;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 27px;

    h5 {
      font-family: "Inter";
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.00011em;
      color: #ffffff;
      margin: 0;
    }
  }
`;
