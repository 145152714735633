import { Component } from "react";
import styled from "styled-components";
import LogoHeader from "../../components/app/headers/LogoHeader";
import BottomNavbar from "../../components/app/BottomNavbar";
import LetterSVG from "../../assets/images/illustrations/letter.svg";
import BrainEyesSVG from "../../assets/images/illustrations/brain-eyes.svg";
import { t } from "i18next";
import { CustomButton } from "../../components/global";
import { completeMagicLink } from "../../services/firebase";
import Loader from "../../components/Loader";
import { displayToast } from "../../components/app/AppToast";
import { getMagicLink } from "../../domains/auth/endpoints/getMagicLink";
import i18n from "../../config/i18n";
import { SupportedLanguagesEnum } from "../../interfaces";

class MagicLinkPage extends Component {
  state = {
    btnLoading: false,
    loading: true,
    status: "retry",
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    const emailStorage = localStorage.getItem("emailForSignIn");
    const emailState = this.props.location?.state?.email;
    const emailFromUrl = this.getEmailFromUrl();

    const interfaceLanguage =
      i18n.resolvedLanguage || SupportedLanguagesEnum.EN;

    if (!emailStorage && !!emailFromUrl)
      localStorage.setItem("emailForSignIn", this.getEmailFromUrl());

    if (!!emailState && !emailStorage && !emailFromUrl) {
      await getMagicLink({ email: emailState, language: interfaceLanguage });
      localStorage.setItem("emailForSignIn", emailState);
      this.setState({ status: "sent", loading: false });
    } else if (
      window.location.href.includes("apiKey") &&
      window.location.href.includes("oobCode")
    ) {
      try {
        await completeMagicLink(window.location.href);
      } catch (error) {
        this.setState({ loading: false });
      }
    } else {
      displayToast(
        t("error:default"),
        "error"
      );
      this.props.history.replace("/login");
    }
  }

  handleClick = async () => {
    this.setState({ loading: true, btnLoading: true });
    const emailStorage = localStorage.getItem("emailForSignIn");

    if (!!emailStorage) {
      await getMagicLink(emailStorage);

      setTimeout(() => {
        this.setState({
          status: "sent",
          loading: false,
          btnLoading: false,
        });
      }, 500);
    } else {
      this.props.history.replace("/signup/pre");
    }
  };

  getEmailFromUrl = () => {
    const url = window.location.href;
    const email = url.split("?email=")[1];
    return email;
  };

  render() {
    const emailState = this.props.location?.state?.email;
    const isSent = this.state.status === "sent";

    return (
      <Wrapper>
        <LogoHeader />

        {this.state.loading ? (
          <Loader />
        ) : (
          <Container>
            <img src={isSent ? LetterSVG : BrainEyesSVG} alt='letter' />
            <h3>{isSent ? t("Check your mailbox") : "Oops!"}</h3>
            <p>
              {isSent ? (
                <span>
                  {t("Weve just sent you a temporary login code to")}
                  <span className='email-color'>{` ${emailState}`}</span>
                </span>
              ) : (
                t("This magical link has expired")
              )}
            </p>
            {this.state.status !== "sent" && (
              <CustomButton
                onClick={() => this.handleClick()}
                className={this.state.btnLoading ? "loading disabled" : "pink"}
              >
                {t("Get a new link")}
              </CustomButton>
            )}
          </Container>
        )}

        <BottomNavbar />
      </Wrapper>
    );
  }
}

export default MagicLinkPage;

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #ecf0f5;
`;

const Container = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 21px 40px;
  text-align: center;

  img {
    margin: 24px 0 48px;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.025em;
    color: #212121;
    margin: 0;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #212121;
    margin: 8px 0 24px;

    .email-color {
      color: #ce0868;
    }
  }
`;
