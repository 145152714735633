import { ReactNode } from "react";
import { Link } from "react-router-dom";
import SettingButton from "./SettingButton";

export default function SettingButtonLink({
  to,
  children,
  className,
  iconName,
  iconScale,
  icon,
  onClick,
}: {
  to: string | {};
  children?: ReactNode;
  className?: string;
  iconName?: string;
  iconScale?: number;
  icon?: ReactNode;
  onClick?: Function;
}) {
  return (
    <Link to={to}>
      <SettingButton
        className={className}
        iconName={iconName}
        iconScale={iconScale}
        icon={icon}
        onClick={onClick}
        inputChild={(
          // TODO: replace with SVG icon
          <div
            style={{
              width: 24,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <svg
              width='8'
              height='15'
              viewBox='0 0 8 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1.32031L7 7.32031L1 13.3203'
                stroke='#90A4AE'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        )}
      >
        {children}
      </SettingButton>
    </Link>
  );
};
