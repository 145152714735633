import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { useSearchJournals } from "../../domains/search/hooks/useSearchJournals";
import { ItemDataEventListName, ItemDataVariant, ItemsDataEvent } from "../../interfaces";
import { convertContentToItemData, gtmItemsData } from "../../tools/reactgaEvents";
import { JournalModel } from "../../domains/journal/journal.types";
import Loader from "../Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import JournalButton from "../JournalButton";
import { NoResultText, SearchResultsListWrapper } from "./layout";
import { t } from "i18next";

export default function SearchResultsJournals() {
  const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
  const history = useHistory();
  const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
  const searcher = useSearchJournals(searchValueParam);

  useEffect(() => {
    for (const list of [searcher.fallback, searcher.results]) {
      if (list.length) {
        gtmItemsData(
          ItemsDataEvent.VIEW_ITEM_LIST,
          convertContentToItemData(
            list,
            ItemDataEventListName.SEARCH,
            ItemDataVariant.JOURNALS,
          )
        );
      }
    }
  }, [searcher.fallback, searcher.results]);

  if (!searchValueParam && !filtersApplied) {
    if (searcher.isLoadingFallback) {
      return (
        <FadeIn>
          <Loader loaderOnly />
        </FadeIn>);
    }

    return (
      <FadeIn key="fallback">
        <SearchResultsListWrapper>
          {searcher.fallback.map((journal: JournalModel) => {
            return (
              <JournalButton
                key={journal._id}
                journal={journal}
                onClick={() => history.push(`/journal/${journal.uid}`)}
              />
            );
          })}
        </SearchResultsListWrapper>
      </FadeIn>
    );
  }

  if (searcher.isLoadingResults) {
    return (
      <FadeIn>
        <Loader loaderOnly />
      </FadeIn>
    );
  }

  if (!searcher.results.length) {
    return <NoResultText>{t("common:noSearchResults")}</NoResultText >;
  }

  return (
    // NOTE: specific key to force fade when switching results
    <FadeIn key="results">
      <InfiniteScroll
        dataLength={searcher.results.length}
        next={() => searcher.searchMore()}
        hasMore={searcher.results.length < searcher.total}
        pullDownToRefresh={false}
        scrollableTarget="content-ref"
        loader={null}
      >
        <SearchResultsListWrapper>
          {searcher.results.map((journal: JournalModel) => {
            return (
              <JournalButton
                key={journal._id}
                journal={journal}
                onClick={() => history.push(`/journal/${journal.uid}`)}
              />
            );
          })}
          {searcher.isLoadingResults && <Loader loaderOnly />}
        </SearchResultsListWrapper>
      </InfiniteScroll>
    </FadeIn>
  );
}