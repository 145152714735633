import { Component } from "react";
import styled from "styled-components";
import GoogleLogo from "../../assets/images/google_logo.svg";
import AppleLogo from "../../assets/images/apple_logo_white.svg";
import { t } from "i18next";

// TODO: remove (deprecated)
class NetworkLoginButton extends Component {
  returnIcon(icon) {
    switch (icon) {
      case "google":
        return GoogleLogo;

      case "apple":
        return AppleLogo;

      default:
        return GoogleLogo;
    }
  }

  render() {
    const { onClick, color, backgroundColor, style } = this.props;
    return (
      <NetworkButton
        type='button'
        onClick={onClick}
        color={color}
        backgroundColor={backgroundColor}
        style={style}
        className={this.props.className}
      >
        <NetworkLogo src={this.returnIcon(this.props.icon)} alt='' />
        <span>
          {this.props.className?.includes("loading")
            ? t("Loading")
            : this.props.text}
        </span>
      </NetworkButton>
    );
  }
}

export default NetworkLoginButton;

const NetworkLogo = styled.img`
  height: 18px;
  transform: translateY(-1px);
`;

const NetworkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &.loading {
    background: #e0e0e0;
    pointer-events: none;
    color: #9e9e9e;

    img {
      opacity: 0.5;
    }
  }

  box-shadow: none;
  outline: none;
  border: none;
  box-sizing: border-box;
  background: ${(props) => props.backgroundColor ?? "#fff"};
  border-radius: 100px;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.color ?? "#212121"};
  font-family: "Inter";
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
`;
