import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NectarModel } from "./nectar.types";

interface ReducerState {
  cache: {
    list: NectarModel[];
    total: number;
  };
  currentNectarIndex: number;
}

const initialState: ReducerState = {
  cache: {
    list: [],
    total: 0,
  },
  currentNectarIndex: -1,
};

export const slice = createSlice({
  name: "nectars",
  initialState,
  reducers: {
    memorizeNectarsList: (state, action: PayloadAction<{ list: NectarModel[]; total: number }>) => {
      state.cache = action.payload;
    },
    setSwipeIndex: (state, action: PayloadAction<number>) => {
      state.currentNectarIndex = action.payload;
    },
    clearSwipeIndex: (state) => {
      state.currentNectarIndex = -1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  memorizeNectarsList,
  setSwipeIndex,
  clearSwipeIndex,
} = slice.actions;

export default slice.reducer;
