import { useEffect, useState } from "react";
import { SupportedLanguagesEnum } from "../../interfaces";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import styled from "styled-components";
import Lottie from "lottie-react";
import BlenderAnimation from "../../assets/animations/new_foryou.json";
import FadeIn from "react-fade-in/lib/FadeIn";

// TODO: Localize.
const FORYOU_TITLE = {
  en: (
    <span>
      Get the latest publications
      <br />
      <b>recommended for you</b>, based on
      <br />
      <b>your reading</b> and that of <b>the community.</b>
    </span>
  ),
  fr: (
    <span>
      Obtiens les dernières publications <br />
      <b>recommandées pour toi</b>, à partir de <br />
      <b>ta lecture</b> et celle de <b>la communauté.</b>
    </span>
  ),
};

// TODO: Localize.
export const BLENDER_LINES = {
  en: [
    <span>
      Hang tight, we rustle up <br /> some <strong>shots of science </strong>for
      you
    </span>,
    "A drop of science...",
    "A dash of clinical protocol...",
    "Distilling information...",
  ],
  fr: [
    <span>
      Ton jus de science personnalisé est en{" "}
      <strong>cours de préparation</strong>
    </span>,
    "Un concentré de science...",
    "Un soupçon de protocole clinique...",
    "Distillation d'informations...",
  ],
};

const LINES_TIME = 2500;

export default function ForYouBlender() {
  const { appLang } = useAppLang();
  const [step, setStep] = useState(0);

  useEffect(() => {
    const linesCount = BLENDER_LINES.en.length;
    setTimeout(() => {
      setStep(prevStep => (prevStep + 1) % linesCount);
    }, step % linesCount === 0 ? LINES_TIME + 1000 : LINES_TIME);
  }, [step]);

  return (
    <Container className="fy-blender-container">
      <FlavorText>
        {(FORYOU_TITLE as { [key: string]: any })[appLang]}
      </FlavorText>
      <Lottie
        animationData={BlenderAnimation}
        loop={true}
        autoplay={true}
      />
      <FadeIn key={step}>
        <FlavorText>
          {((BLENDER_LINES as { [key: string]: any })[appLang] ?? (BLENDER_LINES as { [key: string]: any })[SupportedLanguagesEnum.EN])[step]}
        </FlavorText>
      </FadeIn>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  padding: calc(64px + var(--safe-area-top)) 44px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const FlavorText = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.002px;
`;
