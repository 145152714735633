import { ReactNode, SyntheticEvent, useState } from "react";
import styled from "styled-components";
import CustomIcon from "../CustomIcon";
import { t } from "i18next";
import CustomRadio from "../CustomRadio";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
	SearchContextEnum,
	SearchFilterPublicationDateEnum,
	SearchFilterPublicationTypeEnum,
	SearchFilterScoringEnum,
	defaultSearchFilters,
} from "../../domains/search/search.types";
import { applyFilters, memorizeFacets, resetFilters } from "../../domains/search/search.reducer";
import { CustomButton } from "../global";

export default function SearchFiltersPanel(props: {
	currentContext: SearchContextEnum;
	onClose: Function;
	onChange?: Function;
}) {
	const dispatch = useAppDispatch();
	const { ARTICLES, JOURNALS, VIDEOS, NECTARS } = SearchContextEnum;
	const searchFilters = useAppSelector((state) => state.search.searchFilters);
	const searchFacets = useAppSelector((state) => state.search.searchFacets);
	// const previousSearchFacets = useAppSelector((state) => state.search.previousSearchFacets);
	const [newFilters, setNewFilters] = useState({ ...searchFilters });

	function getChangedFilterKeys() {
		return Object.keys(searchFilters).filter((key) => newFilters[key] !== searchFilters[key]);
	}

	function handleChangeFilterScoring(value: SearchFilterScoringEnum) {
		setNewFilters({ ...newFilters, scoring: value });
	}

	function handleChangeFilterPublicationDate(value: SearchFilterPublicationDateEnum) {
		setNewFilters({ ...newFilters, publicationDate: value });
	}

	function handleChangeFilterPublicationType(value: SearchFilterPublicationTypeEnum) {
		setNewFilters({ ...newFilters, publicationType: value });
	}

	function handleChangeFilterImpactFactor(value: number) {
		setNewFilters({ ...newFilters, impactFactor: value });
	}

	function handleClose(e: SyntheticEvent) {
		e.preventDefault();
		props.onClose && props.onClose();
	}

	function handleApplyFilters(e: SyntheticEvent) {
		e.preventDefault();
		const changedFilterKeys = getChangedFilterKeys();
		if (changedFilterKeys.length) {
			// Memorize facets for filters that just changed
			const obj: any = {};
			for (const key of changedFilterKeys) {
				obj[key] = searchFacets[key];
			}
			dispatch(memorizeFacets(obj));

			dispatch(applyFilters(newFilters));
			props.onChange && props.onChange();
		}
		setTimeout(() => { // NOTE: necessary to refresh store before closing
			props.onClose && props.onClose();
		}, 0);
	}

	function handleResetFilters(e: SyntheticEvent) {
		e.preventDefault();
		setNewFilters(defaultSearchFilters);
		dispatch(resetFilters());
		props.onChange && props.onChange();
		setTimeout(() => { // NOTE: necessary to refresh store before closing
			props.onClose && props.onClose();
		}, 0);
	}

	return (
		<Container>
			<PanelHeader>
				<span className="main-heading">{t("search:filter.title")}</span>
				<CustomIcon
					className="close-button"
					onClick={handleClose}
					iconName="close_alt"
					color="#313B42"
				/>
			</PanelHeader>

			<PanelContent>
				{[ARTICLES, VIDEOS, NECTARS].includes(props.currentContext) &&
					<>
						<SectionHeading>{t("search:filter.category.sortBy")}</SectionHeading>
						<fieldset>
							{[
								{ text: t("search:filter.option.mostRelevant"), value: SearchFilterScoringEnum.MOST_RELEVANT },
								{ text: t("search:filter.option.mostRecent"), value: SearchFilterScoringEnum.MOST_RECENT },
								{ text: t("search:filter.option.mostViews"), value: SearchFilterScoringEnum.MOST_VIEWED },
							].map(({ text, value }) => {
								return (
									<FilterRadio
										key={value}
										checked={newFilters.scoring === value}
										onChange={() => handleChangeFilterScoring(value)}
									>{text}</FilterRadio>
								);
							})}
						</fieldset>
						<Separator />
					</>
				}

				{[ARTICLES, VIDEOS].includes(props.currentContext) &&
					<>
						<SectionHeading>{t("search:filter.category.publicationDate")}</SectionHeading>
						<fieldset>
							{[
								{ text: t("search:filter.option.all"), value: SearchFilterPublicationDateEnum.NO_FILTER, count: null },
								{ text: t("search:filter.option.lessThanOneYear"), value: SearchFilterPublicationDateEnum.LESS_THAN_1_YEAR, count: searchFacets?.publicationDate["< 1 year"] },
								{ text: t("search:filter.option.lessThanTwoYears"), value: SearchFilterPublicationDateEnum.LESS_THAN_2_YEARS, count: searchFacets?.publicationDate["< 2 years"] },
								{ text: t("search:filter.option.lessThanThreeYears"), value: SearchFilterPublicationDateEnum.LESS_THAN_3_YEARS, count: searchFacets?.publicationDate["< 3 years"] },
								{ text: t("search:filter.option.moreThanThreeYears"), value: SearchFilterPublicationDateEnum.MORE_THAN_3_YEARS, count: searchFacets?.publicationDate["> 3 years"] },
							]
								.filter(({ value, count }) => {
									return newFilters.publicationDate === value || ![0, undefined].includes(count);
								})
								.map(({ text, value, count }) => {
									return (
										<FilterRadio
											key={value}
											checked={newFilters.publicationDate === value}
											onChange={() => handleChangeFilterPublicationDate(value)}
										>{`${text}${count !== null ? " (" + (count ?? 0) + ")" : ""}`}</FilterRadio>
									);
								})}
						</fieldset>
						<Separator />
					</>
				}

				{[ARTICLES].includes(props.currentContext) &&
					<>
						<SectionHeading>{t("search:filter.category.publicationType")}</SectionHeading>
						<fieldset>
							{[
								{ text: t("search:filter.option.all"), value: SearchFilterPublicationTypeEnum.NO_FILTER, count: null },
								...[
									{ text: t("articles.type.caseReport"), value: SearchFilterPublicationTypeEnum.CASE_REPORT, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.CASE_REPORT] },
									{ text: t("articles.type.clinicalStudy"), value: SearchFilterPublicationTypeEnum.CLINICAL_STUDY, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.CLINICAL_STUDY] },
									{ text: t("articles.type.commentary"), value: SearchFilterPublicationTypeEnum.COMMENTARY, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.COMMENTARY] },
									{ text: t("articles.type.guideline"), value: SearchFilterPublicationTypeEnum.GUIDELINE, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.GUIDELINE] },
									{ text: t("articles.type.letterEditorial"), value: SearchFilterPublicationTypeEnum.LETTER_EDITORIAL, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.LETTER_EDITORIAL] },
									{ text: t("articles.type.metaAnalysis"), value: SearchFilterPublicationTypeEnum.META_ANALYSIS, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.META_ANALYSIS] },
									{ text: t("articles.type.originalResearch"), value: SearchFilterPublicationTypeEnum.ORIGINAL_RESEARCH, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.ORIGINAL_RESEARCH] },
									{ text: t("articles.type.perspective"), value: SearchFilterPublicationTypeEnum.PERSPECTIVE, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.PERSPECTIVE] },
									{ text: t("articles.type.review"), value: SearchFilterPublicationTypeEnum.REVIEW, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.REVIEW] },
									{ text: t("articles.type.shortCommunication"), value: SearchFilterPublicationTypeEnum.SHORT_COMMUNICATION, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.SHORT_COMMUNICATION] },
									{ text: t("articles.type.studyProtocol"), value: SearchFilterPublicationTypeEnum.STUDY_PROTOCOL, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.STUDY_PROTOCOL] },
									{ text: t("articles.type.systematicReviewMetaAnalysis"), value: SearchFilterPublicationTypeEnum.SYSTEMATIC_REVIEW_META_ANALYSIS, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.SYSTEMATIC_REVIEW_META_ANALYSIS] },
									{ text: t("articles.type.technicalReport"), value: SearchFilterPublicationTypeEnum.TECHNICAL_REPORT, count: searchFacets?.publicationType[SearchFilterPublicationTypeEnum.TECHNICAL_REPORT] },
								].sort((a, b) => a.text.localeCompare(b.text))
							]
								.filter(({ value, count }) => {
									return newFilters.publicationType === value || ![0, undefined].includes(count);
								})
								.map(({ text, value, count }) => {
									return (
										<FilterRadio
											key={value}
											checked={newFilters.publicationType === value}
											onChange={() => handleChangeFilterPublicationType(value)}
										>{`${text}${count !== null ? " (" + (count ?? 0) + ")" : ""}`}</FilterRadio>
									);
								})}
						</fieldset>
						<Separator />
					</>
				}

				{[ARTICLES, JOURNALS].includes(props.currentContext) &&
					<>
						<SectionHeading>{t("search:filter.category.impactFactor")}</SectionHeading>
						<fieldset>
							{[
								{ text: t("search:filter.option.all"), value: 0, count: null },
								{ text: t("> 1"), value: 1, count: searchFacets?.impactFactor["> 1"] },
								{ text: t("> 3"), value: 3, count: searchFacets?.impactFactor["> 3"] },
								{ text: t("> 10"), value: 10, count: searchFacets?.impactFactor["> 10"] },
								{ text: t("> 50"), value: 50, count: searchFacets?.impactFactor["> 50"] },
							]
								.filter(({ value, count }) => {
									return newFilters.impactFactor === value || ![0, undefined].includes(count);
								})
								.map(({ text, value, count }) => {
									return (
										<FilterRadio
											key={value}
											checked={newFilters.impactFactor === value}
											onChange={() => handleChangeFilterImpactFactor(value)}
										>{`${text}${count !== null ? " (" + (count ?? 0) + ")" : ""}`}</FilterRadio>
									);
								})}
						</fieldset>
						<Separator />
					</>
				}
			</PanelContent>

			<PanelFooter>
				<CustomButton className="secondary" onClick={handleResetFilters}>{t("common:action.reset")}</CustomButton>
				<CustomButton className="pink" onClick={handleApplyFilters}>{t("common:action.apply")}</CustomButton>
			</PanelFooter>
		</Container>
	);
}

const SectionHeading = (props: { children: ReactNode }) => <span className="sub-heading">{props.children}</span>;

const FilterRadio = (props: { children: ReactNode, checked: boolean, onChange: Function }) => (
	<CustomRadio
		checked={props.checked}
		onChange={props.onChange}
		title={props.children}
		textStyle={{
			fontFamily: "Inter",
			fontSize: 14,
			fontWeight: 500,
		}}
	/>
);

const Container = styled.div`
    box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100%;
    height: 100dvh;
		overflow-y: auto;
    padding: calc(70px + var(--safe-area-top)) 15px 168px;
    background-color: #F9F9F9;
`;

const PanelHeader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	box-sizing: border-box;
	width: 100%;
	padding: calc(15px + var(--safe-area-top)) 15px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F9F9F9;

	.main-heading {
			font-family: Inter;
			font-size: 16px;
			font-weight: 700;
			line-height: 11px;
	}

	.close-button {
			padding: 3px;
			svg {
					width: 26px;
					height: 26px;
			}
	}
`;

const PanelContent = styled.div`
	padding: 0 6px;

	.sub-heading {
			display: block;
			margin-bottom: 24px;
			font-family: Inter;
			font-size: 14px;
			font-weight: 600;
			line-height: 10px;
	}

	fieldset {
			padding: 0;
			border: none;
			display: flex;
			flex-direction: column;
			gap: 8px;

			.--custom-checkbox {
					.MuiFormControlLabel-root {
							margin: 0;
							.MuiRadio-root {
									padding: 0;
									padding-right: 12px;
							}
					}
					p {
							margin: 0;
					}
			}
	}
`;

const PanelFooter = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	gap: 10px;
	padding: 23px 21px calc(36px + var(--safe-area-bottom));
	background-color: white;
`;

const Separator = styled.div`
    margin: 24px 0;
    border-top: 1px solid #4C5861;

    &:last-of-type {
        display: none;
    }
`;