import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { useCurrentUser } from "./useCurrentUser";
import { NectarModel } from "../../nectar/nectar.types";
import { likeContent } from "../endpoints/likeContent";
import { unlikeContent } from "../endpoints/unlikeContent";
import { preflightUser } from "../utils/preflightUser";
import { setUser } from "../../../redux/user/user.reducer";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { iosGenerateHaptic, iosPlaySound } from "../../../tools/ios";
import { HapticEffect, ItemDataEventListName, ItemDataVariant, ItemsDataEvent, SoundEffect } from "../../../interfaces";
import { convertContentToItemData, gtmItemsData } from "../../../tools/reactgaEvents";

export function useLikeNectar(nectar: NectarModel | null, gtmItemListName?: ItemDataEventListName) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const [isReady, setReady] = useState(false);
  const isLikedStore = currentUser?.likedContent?.some((content: { slug: string }) => {
    return content.slug === nectar?.slug;
  });
  const [isLikedOptimistic, setLikedOptimistic] = useState(isLikedStore);
  const [isFetching, setFetching] = useState(false);

  // Setup hook and state once nectar is loaded
  useEffect(() => {
    if (nectar) {
      setLikedOptimistic(isLikedStore);
      setReady(true);
    }
  }, [nectar]);

  useEffect(() => {
    if (isReady && !isFetching) {
      if (isLikedOptimistic !== isLikedStore) {
        updateStatus();
      }
    }
  }, [isLikedOptimistic, isLikedStore]);

  async function toggleLike() {
    try {
      if (!nectar) throw new Error("Invalid nectar.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.likeContent"),
      });
      if (isRedirected) return;

      setLikedOptimistic(!isLikedOptimistic);
      iosGenerateHaptic(HapticEffect.SUCCESS);
      displayToast(t(!isLikedOptimistic
        ? "you have liked a nectar"
        : "you have unliked a nectar"
      ), "success");

      if (!isLikedOptimistic) {
        iosPlaySound(SoundEffect.LIKE);
        if (gtmItemListName) {
          gtmItemsData(
            ItemsDataEvent.NECTAR_LIKE,
            convertContentToItemData(
              [nectar],
              gtmItemListName,
              ItemDataVariant.NECTAR,
            )
          );
        }
      }
    } catch (error) {
      console.error("Couldn't like/unlike nectar.", error);
      displayToast(t("error:default"));
    }
  }

  async function updateStatus() {
    try {
      if (!nectar) throw new Error("Invalid nectar.");

      setFetching(true);
      const [{ likedContent }] = isLikedOptimistic
        ? await likeContent(nectar?._id)
        : await unlikeContent(nectar?._id);

      setFetching(false);
      dispatch(setUser({ ...currentUser, likedContent }));
    } catch (error) {
      console.error("Couldn't update likes.", error);
    }
  }

  return {
    isLiked: isLikedOptimistic,
    toggleLike,
  };
}
