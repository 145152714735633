import styled from "styled-components";
import { ReactComponent as Hand } from "../../assets/icons/gpt/disclaimer-hand.svg";
import { SyntheticEvent, useEffect, useState } from "react";
import { t } from "i18next";

export default function GPTDisclaimer(props: { visible: boolean }) {
	const [visible, setVisible] = useState(props.visible ?? false);

	useEffect(() => setVisible(props.visible), [props.visible]);

	function handleAccept(e: SyntheticEvent) {
		e.preventDefault();
		setVisible(false);
		localStorage.setItem("acceptedJuisciGPTRules", JSON.stringify(true));
	}

	if (!visible) return null;

	return (
		<>
			<Overlay />
			<Modal>
				<ModalCorner><Hand /></ModalCorner>
				<div className="heading">{t("juisciGpt:disclaimer.title")}</div>
				<div className="text">{t("juisciGpt:disclaimer.body")}</div>
				<button onClick={handleAccept}>{t("common:action.accept")}</button>
			</Modal>
		</>
	);
}

const Overlay = styled.div`
	position: absolute;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	background-color: #212121;
	opacity: 0.9;
`;

const Modal = styled.div`
	position: absolute;
	margin: 50dvh 50px;
	transform: translateY(-50%);
	z-index: 101;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
	background-color: #EEF822;
	opacity: 0.5;
	animation: fadeIn 0.2s ease-in forwards;

	.heading {
		padding: 20px;
		text-align: center;
		font-family: Inter;
		font-size: 26px;
		font-weight: 500;
		color: #212121;
	}

	.text {
		padding: 20px;
		border: 1px solid #2121211A;
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.02em;
		color: #212121;
	}

	button {
		width: 100%;
		padding: 20px;
		border: none;
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
		background: none;
		font-family: Inter;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		color: #212121;
	}
`;

const ModalCorner = styled.div`
	position: absolute;
	top: -16px;
	right: -16px;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: #212121;
	box-shadow: 2px 2px 4px 0px #2121211A;
	display: flex;
	align-items: center;
	justify-content: center;
`;