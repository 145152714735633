import { createSlice } from "@reduxjs/toolkit";
import { PlaylistModel } from "./playlist.types";
import { getPublicPlaylists } from "./endpoints/getPublicPlaylists";
import { getKOLPlaylists } from "./endpoints/getKOLPlaylists";

interface ReducerState {
  cache: {
    recommended: PlaylistModel[];
    kol: PlaylistModel[];
  };
}

const initialState: ReducerState = {
  cache: {
    recommended: [],
    kol: [],
  },
};

export const slice = createSlice({
  name: "playlists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        getPublicPlaylists.matchFulfilled,
        (state, { payload }) => {
          state.cache.recommended = payload.docs;
        },
      )
      .addMatcher(
        getKOLPlaylists.matchFulfilled,
        (state, { payload }) => {
          state.cache.kol = payload.docs;
        },
      );
  },
});

export default slice.reducer;
