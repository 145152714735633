import { CSSProperties, forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ label, isActive, onClick }, ref) => (
    <TabButton
      ref={ref}
      className={`tab-switcher-button ${isActive ? "active" : ""}`}
      isActive={isActive}
      onClick={onClick}
    >
      {label}
    </TabButton>
  )
);

interface TabSwitcherProps {
  tabs: string[];
  onTabChange: (tabIndex: number) => void;
  activeTab?: number;
  noSlider?: boolean;
}

export default function TabSwitcher({
  tabs,
  onTabChange,
  activeTab = 0,
  noSlider,
}: TabSwitcherProps) {
  const [sliderStyle, setSliderStyle] = useState<CSSProperties>({ width: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [startingTab] = useState(activeTab);

  useEffect(() => {
    if (containerRef.current) {
      const totalPadding = 6;
      const tabWidthPercentage = 100 / tabs.length;
      const tabWidthWithPadding =
        tabWidthPercentage -
        (totalPadding / containerRef.current.offsetWidth) * 100;
      const tabLeftWithPadding =
        activeTab * tabWidthPercentage +
        (totalPadding / 2 / containerRef.current.offsetWidth) * 100;

      setSliderStyle((prevStyle) => ({
        ...prevStyle,
        width: `${tabWidthWithPadding}%`,
        left: `${tabLeftWithPadding}%`,
      }));

      if (activeTab !== startingTab) {
        // Change transition duration when switching tab the first time
        setSliderStyle((prevStyle) => ({
          ...prevStyle,
          transitionDuration: "0.1s",
        }));
      }
    }
  }, [activeTab, tabs.length]);

  const handleTabClick = (index: number) => {
    onTabChange(index);
  };

  return (
    <TabSwitcherContainer ref={containerRef} className="tab-switcher-container">
      {tabs.map((tab, index) => (
        <Tab
          key={tab}
          label={tab}
          isActive={index === activeTab}
          onClick={() => handleTabClick(index)}
        />
      ))}
      {!noSlider && <Slider className="tab-switcher-button active" style={sliderStyle} />}
    </TabSwitcherContainer>
  );
}

const TabSwitcherContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  position: relative;
  border: 1px solid #bcbcbc;
  border-radius: 25px;
  padding: 3px;
  width: max-content;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  padding: 7px 16px;
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
  color: ${(props) => (props.isActive ? "white" : "#BCBCBC")};
  font-size: 16px;
  font-family: "Inter";
  font-weight: 700;
  min-width: 100px;
  white-space: nowrap;
`;

const Slider = styled.div`
  position: absolute;
  top: 3px;
  background-color: #000;
  border-radius: 20px;
  transition-property: left;
  transition-duration: 0;
  transition-timing-function: ease;
  height: calc(100% - 6px);
`;
