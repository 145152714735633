import { ReactChildren, ReactNode } from "react";
import styled from "styled-components";
import GoogleLogo from "../../assets/images/google_logo.svg";
import AppleLogo from "../../assets/images/apple_logo_white.svg";

export default function AuthProviderButton({ children, provider, onClick }: {
  children: ReactNode | ReactChildren;
  provider: "apple" | "google";
  onClick: Function;
}) {
  return (
    <Container
      style={{
        background: provider === "apple"
          ? "#212121"
          : "#fff",
        color: provider === "apple"
          ? "#fff"
          : "#212121",
      }}
      onClick={() => onClick(provider)}
    >
      {provider === "apple" && <img src={AppleLogo} alt={provider} />}
      {provider === "google" && <img src={GoogleLogo} alt={provider} />}
      {/*  />
        <span>
          {this.props.className?.includes("loading")
            ? t("Loading")
            : this.props.text}
        </span> */}
      {children}
    </Container>
  );
}

// const NetworkLogo = styled.img`
//   height: 18px;
//   transform: translateY(-1px);
// `;

const Container = styled.button`
  width: 100%;
  padding: 16px 24px;
  border: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #FFFFFF;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;

  img {
    height: 18px;
  }
`;

//   &.loading {
//     background: #e0e0e0;
//     pointer-events: none;
//     color: #9e9e9e;

//     img {
//       opacity: 0.5;
//     }
//   } */

