import { SupportedLanguagesEnum } from "../../../interfaces";
import { searchApi } from "../search.api";

interface QueryParams {
  search: string;
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  scoring?: string;
  afterDate?: string;
  beforeDate?: string;
}

const api = searchApi.injectEndpoints({
  endpoints: (build) => ({
    searchVideos: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: "/content/search-v2",
          params: {
            ...params,
            content_format: "video",
          },
        };
      },
      transformResponse: (response: { hits: { hits: [], total: number, facets: [] } }) => {
        const { hits, total, facets } = response.hits;

        const docs = hits.map(({ _source }: {
          _source: {
            id: string;
            view: { videoFormat: string };
          };
        }) => {
          return {
            ..._source.view,
            id: _source.id,
            content: {
              videoFormat: _source.view.videoFormat,
            },
          };
        });

        return { docs, meta: { total, facets } };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchVideosQuery,
  useLazySearchVideosQuery,
} = api;
