import { t } from "i18next";
import NoResultDraw from "../../assets/images/illustrations/search_no_results.svg";
import i18n from "../../config/i18n";
import { SupportedLanguagesEnum } from "../../interfaces";
import styled from "styled-components";

export default function SearchNoResults() {
  return (
    <Container>
      <img src={NoResultDraw} alt="" />
      <p>
        {t("Sorry We havent found any medical content related to your request")}
      </p>
      <p>
        {t(
          "You can also scan and summarize automatically your own publications by using the Blend feature"
        )}
        <br />
        <a
          className="link"
          target="_blank"
          href={
            i18n.resolvedLanguage === SupportedLanguagesEnum.FR
              ? "https://www.juisci.com/fr/mixez"
              : "https://www.juisci.com/blend"
          }
        >
          {t("Discover more here")}
        </a>
      </p>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 50px;

  img {
    display: block;
    margin: auto;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: #212121;
    & .link {
    color: #ff8800;
    font-weight: 500;
    text-decoration: underline;
    }
  }
`;
