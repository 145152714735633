import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { checkEmailExists } from "../../domains/auth/endpoints/checkEmailExists";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { loginWithApple, loginWithGoogle } from "../../services/firebase";
import { isNativeAndroid } from "../../tools/android";
import LogoHeader from "../../components/app/headers/LogoHeader";
import BottomNavbar from "../../components/app/BottomNavbar";
import Loader from "../../components/Loader";
import AuthProviderButton from "../../components/signup/AuthProviderButton";
import { CustomButton } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { Trans } from "react-i18next";

export default function PreSignupPage() {
  const history = useHistory();
  const { t } = useAppLang();
  const [isLoading, setLoading] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
    (async function () {
      try {
        window.scrollTo(0, 0);

        const isAuthentified = await isUserAuthentified();
        if (isAuthentified) return history.replace("/profile");

        setLoading(false);
      } catch (error) {
        console.error("Couldn't mount pre-signup page.", error);
        displayToast(t("error:default"));
      }
    })();
  }, []);

  function handleSignupWithProvider(provider: "google" | "apple") {
    if (provider === "apple") return loginWithApple();
    if (provider === "google") return loginWithGoogle();
  }

  async function handleSubmit(e: SyntheticEvent) {
    try {
      e.preventDefault();
      localStorage.removeItem("emailForSignIn");
      const { isExisting, massImportNeedsOnboarding } = await checkEmailExists(
        email
      );
      if (!isExisting) {
        history.push({
          pathname: "/signup",
          state: { email },
        });
      } else if (massImportNeedsOnboarding) {
        history.push({
          pathname: "/signup/magiclink",
          state: { email },
        });
      } else {
        displayToast(t("error:emailExists"));
      }
    } catch (error) {
      console.error("Couldn't signup with email.", error);
      displayToast(t("error:default"));
      throw error;
    }
  }

  if (isLoading) return <Loader />;

  return (
    <Wrapper>
      <LogoHeader />

      <Container>
        <h1>{t("signup:pre.title")}</h1>
        <span className="subtitle">{t("signup:pre.subtitle")}</span>

        <Content>
          <AuthProviderButton
            provider="google"
            onClick={handleSignupWithProvider}
          >
            {t("signup:action.signupWithGoogle")}
          </AuthProviderButton>

          {!isNativeAndroid && (
            <AuthProviderButton
              provider="apple"
              onClick={handleSignupWithProvider}
            >
              {t("signup:action.signupWithApple")}
            </AuthProviderButton>
          )}

          <DividerWithText>{t("or")}</DividerWithText>

          <form onSubmit={handleSubmit}>
            <CustomInput
              type="email"
              placeholder={t("common:form.field.email")}
              value={email}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />

            <CustomButton type="submit" disabled={!email.length}>
              {t("signup:action.signupWithEmail")}
            </CustomButton>
          </form>

          <Credits>
            <Trans
              i18nKey="signup:pre.disclaimer"
              className="credits"
              components={{
                b: <b />,
                termsLink: (
                  <Link
                    to={{
                      pathname: "/terms",
                      state: { isPush: true, hideTitle: true },
                    }}
                  ></Link>
                ),
                policyLink: (
                  <Link
                    to={{
                      pathname: "/policy",
                      state: { isPush: true, hideTitle: true },
                    }}
                  ></Link>
                ),
              }}
            />
          </Credits>
        </Content>
      </Container>

      <BottomNavbar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #ecf0f5;
`;

const Container = styled.div`
  flex: 1;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #212121;
  }

  .subtitle {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #212121;
  }
`;

const Content = styled.div`
  margin: 37px 0 51px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const DividerWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;
  color: #4c5861;

  &::before,
  &:after {
    content: "";
    border-top: 1px solid #4c5861;
    flex: 1;
  }
`;

const Credits = styled.div`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: center;
  color: #212121;

  a {
    text-decoration: underline;
    color: #ff8800;
  }
`;
