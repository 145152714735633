import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux";
import axios from "axios";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { SpecialtyModel } from "../../domains/app/app.types";
import { ArticleModel } from "../../domains/article/article.types";
import { VideoFormat } from "../../domains/video/video.types";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { SearchFilterPublicationTypeEnum } from "../../domains/search/search.types";
import { getArticleBySlug } from "../../domains/article/endpoints/getArticleBySlug";
import { getRelatedArticles } from "../../domains/article/endpoints/getRelatedArticles";
import { synthesizeArticleSpeech } from "../../domains/article/endpoints/synthesizeArticleSpeech";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { useOnScreen } from "../../domains/app/hooks/useOnScreen";
import { useLikeArticle } from "../../domains/user/hooks/useLikeArticle";
import { useSaveArticle } from "../../domains/user/hooks/useSaveArticle";
import { useShareArticle } from "../../domains/user/hooks/useShareArticle";
import {
  clearSwipeIndex,
  clearSwipeList,
  setSwipeIndex,
} from "../../domains/article/article.reducer";
import styled from "styled-components";
import {
  convertContentToItemData,
  gtmItemsData,
} from "../../tools/reactgaEvents";
import {
  HapticEffect,
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
  SupportedLanguagesEnum,
} from "../../interfaces";
import {
  iosGenerateHaptic,
  iosSetBackgroundColor,
  isNativeIOS,
} from "../../tools/ios";
import { renderPublicationDate } from "../../tools/utils";
import { ArticleTypeHeading, Flex, RoundIcon } from "../../components/global";
import SpecialtyBadges from "../../components/app/SpecialtyBadges";
import ExpandableText from "../../components/app/ExpandableText";
import CustomIcon from "../../components/CustomIcon";
import Loader from "../../components/Loader";
import ImageViewer, {
  CustomIndicators,
} from "../../components/post/ImageViewer";
import SwipeDetector from "../../components/SwipeDetector";
import ArticleCard from "../../components/cards/ArticleCard";
import PlaylistPanel from "../../components/app/PlaylistPanel";
import ArticleExternalLink from "../../components/ArticleExternalLink";
import AudioPlayer from "../../components/media/AudioPlayer";
import { Carousel } from "react-responsive-carousel";
import disableScroll from "disable-scroll";
import { displayToast } from "../../components/app/AppToast";
import { ReactComponent as CloseAltIcon } from "../../assets/icons/close_alt.svg";
import { ReactComponent as StrawberryIcon } from "../../assets/icons/straw.svg";
import { ReactComponent as GrapesIcon } from "../../assets/icons/grapes.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/apple.svg";
import { ReactComponent as RaspberryIcon } from "../../assets/icons/rasp.svg";
import { ReactComponent as CherryIcon } from "../../assets/icons/cherry.svg";
import { ReactComponent as PineappleIcon } from "../../assets/icons/pineapple.svg";
import { ReactComponent as LemonIcon } from "../../assets/icons/lemon.svg";
import { ReactComponent as BlenderIcon } from "../../assets/icons/blender.svg";
import { ReactComponent as AvocadoIcon } from "../../assets/icons/avocado.svg";
import { ReactComponent as PeachIcon } from "../../assets/icons/peach.svg";
import { ReactComponent as AuthorIcon } from "../../assets/icons/user-fill.svg";

export default function PostPage() {
  // Router
  const history = useHistory();
  const location = useLocation<{
    article?: ArticleModel;
    sponsorised: boolean;
    playlist: PlaylistModel;
  }>();
  const params = useParams<{ slug: string }>();
  // Store
  const dispatch = useAppDispatch();
  const swipeList = useAppSelector((state) => state.articles.cache.swipeList);
  const currentArticleIndex = useAppSelector(
    (state) => state.articles.currentArticleIndex
  );
  // State
  const [isLoading, setLoading] = useState(true);
  const [article, setArticle] = useState<ArticleModel | null>(null);
  const [isAudioLoading, setAudioLoading] = useState(false);
  const [relatedArticles, setRelatedArticles] = useState<ArticleModel[]>([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isBackgroundExpanded, setBackgroundExpanded] = useState(false);
  const [isMethodologyExpanded, setMethodologyExpanded] = useState(false);
  const [isLimitationsExpanded, setLimitationsExpanded] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [isWaitingActionResult, setWaitingActionResult] = useState(false);
  const [memLiked, setMemLiked] = useState<boolean | null>(null);
  const [memSaved, setMemSaved] = useState<boolean | null>(null);
  const [wasShared, setWasShared] = useState(false);
  const [isShareHighlighted, setShareHighlighted] = useState(false);
  const [wasShareHighlighted, setWasShareHighlighted] = useState(false);
  // Refs
  const dummyTopRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  // Custom
  const { appLang, t } = useAppLang();
  const isHeaderOnScreen = useOnScreen(headerRef, true);
  const { isLiked, toggleLike } = useLikeArticle(
    article,
    ItemDataEventListName.ARTICLE_PAGE
  );
  const { isSaved, toggleSaveToPlaylists } = useSaveArticle(article);
  const shareArticle = useShareArticle(article);
  const articleFirstSpecialty = article?.medical_specialties[0] as SpecialtyModel;

  useEffect(() => {
    (async function () {
      try {
        window.scrollTo(0, 0);
        handlePageScroll();
        window.addEventListener("scroll", handlePageScroll);

        // Define GTM audio percentages that trigger the event
        localStorage.setItem(
          "gtm_audio_percent_targets",
          JSON.stringify([3, 25, 50, 75, 80])
        );

        if (localStorage.getItem("_nb_views_tm") === null) {
          localStorage.setItem("numberViews", "0");
        }
        localStorage.setItem("_nb_views_tm", JSON.stringify(new Date()));

        const isAuthentified = await isUserAuthentified();

        if (!isAuthentified) {
          const isRedirected = preflightGuest();
          if (isRedirected) return;
        }

        const loadedArticle = await loadArticle();
        setArticle(loadedArticle);
        setLoading(false);
        await loadRelatedArticles(loadedArticle);
      } catch (error) {
        console.error("Couldn't mount.", error);
        displayToast(t("error:default"));
      }
    })();

    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, [params.slug]);

  useEffect(() => {
    if (article) {
      gtmItemsData(
        ItemsDataEvent.VIEW_ITEM,
        convertContentToItemData(
          [article],
          ItemDataEventListName.ARTICLE_PAGE,
          ItemDataVariant.ARTICLE
        )
      );

      checkAudioExists();
    }
  }, [article]);

  function preflightGuest() {
    const numberViews = localStorage.getItem("numberViews");

    if (numberViews && parseInt(numberViews) >= 1) {
      localStorage.setItem("authRedirectPath", location.pathname);
      history.replace("/login/prev");
      return true;
    }

    localStorage.setItem(
      "numberViews",
      numberViews !== null ? JSON.stringify(parseInt(numberViews) + 1) : "1"
    );
    return false;
  }

  async function loadArticle() {
    try {
      if (location?.state?.article) {
        return location.state.article;
      }

      // Check if the current slug belongs to the swipe list and load cached article
      if (swipeList.length) {
        const swipeListSlugs = swipeList.map(
          (article: ArticleModel) => article.slug
        );
        const slugIndex = swipeListSlugs.findIndex(
          (slug: string) => slug === params.slug
        );
        if (slugIndex !== -1) {
          dispatch(setSwipeIndex(slugIndex)); // Restore swipe action if index was lost
          return swipeList[slugIndex];
        } else {
          dispatch(clearSwipeIndex()); // Clear swipe index and prevent swipe action
        }
      }

      setLoading(true);
      return await getArticleBySlug(params.slug);
    } catch (error) {
      console.error("Couldn't fetch article.", error);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        displayToast(t("article.error.not_found"), "error");
        history.replace("/discovery");
      } else {
        throw error;
      }
    }
  }

  async function loadRelatedArticles(sourceArticle: {
    medicalSpecialties: SpecialtyModel[];
  }) {
    try {
      if (location?.state?.sponsorised) {
        setRelatedArticles(
          location?.state?.playlist?.playlist.filter((el: any) => {
            return el._id !== article?._id;
          })
        );
        return;
      }
      const data = await getRelatedArticles(
        params.slug,
        (sourceArticle?.medicalSpecialties as SpecialtyModel[])?.[0]?.uid
      );
      setRelatedArticles(data);
    } catch (error) {
      console.error("Couldn't get related articles.", error);
    }
  }

  async function checkAudioExists() {
    try {
      if (!article)
        throw new Error("No article. Can't check speech or synthesize.");
      if (!article.speech) {
        setAudioLoading(true);
        const { speech } = await synthesizeArticleSpeech(article.slug, appLang);
        setArticle({ ...article, speech });
        setAudioLoading(false);
      }
    } catch (error) {
      console.error("Couldn't synthesize article speech.", error);
    }
  }

  function getMetricBonus(currentValue: boolean, memValue: boolean | null) {
    if (memValue !== null && currentValue !== memValue) {
      return !!currentValue ? 1 : -1;
    }
    return 0;
  }

  function handlePageScroll() {
    setScrollProgress(
      (window.innerHeight + window.scrollY) /
      document.documentElement.scrollHeight
    );
  }

  function handlePlayPause() {
    iosGenerateHaptic(HapticEffect.SELECTION);
    if (!audioPlayerRef.current) return;

    if (audioPlayerRef.current.paused) {
      gtmItemsData(
        ItemsDataEvent.AUDIO_PLAYED,
        convertContentToItemData(
          [article],
          ItemDataEventListName.ARTICLE_PAGE,
          ItemDataVariant.ARTICLE
        )
      );
      audioPlayerRef.current.play();
    } else {
      audioPlayerRef.current.pause();
    }
  }

  function handleExit() {
    iosGenerateHaptic(HapticEffect.SELECTION);
    dispatch(clearSwipeList());
    dispatch(clearSwipeIndex());
    const searchParams = new URLSearchParams(location.search);
    if (location.key && !searchParams.get("notificationCenter"))
      history.goBack();
    else {
      history.replace("/discovery");
    }
  }

  async function handleSave({
    add,
    remove,
  }: {
    add: string[];
    remove: string[];
  }) {
    if (memSaved === null) setMemSaved(isSaved);
    await toggleSaveToPlaylists({ add, remove });

    if (!isSaved) {
      // If a save was just done
      setTimeout(() => {
        setShareHighlighted(true);
        setWasShareHighlighted(true);
        setTimeout(() => setShareHighlighted(false), 500);
      }, 1000);
    }
  }

  async function handleLike(e: SyntheticEvent) {
    if (isWaitingActionResult) return;

    if (memLiked === null) setMemLiked(isLiked);

    setWaitingActionResult(true);
    await toggleLike();
    setWaitingActionResult(false);

    if (!isLiked) {
      // If a like just happened
      setShareHighlighted(true);
      setWasShareHighlighted(true);
      setTimeout(() => setShareHighlighted(false), 500);
    }
  }

  async function handleShare() {
    shareArticle();
    setWasShared(true);
  }

  function handleNavigatePrevious() {
    if (
      swipeList.length > 0 &&
      currentArticleIndex !== -1 &&
      currentArticleIndex > 0
    ) {
      const prevArticle = swipeList[currentArticleIndex - 1];
      history.replace(`/post/${prevArticle.slug}`);
      dispatch(setSwipeIndex(currentArticleIndex - 1));
    }
  }

  function handleNavigateNext() {
    if (
      swipeList.length > 0 &&
      currentArticleIndex !== -1 &&
      currentArticleIndex < swipeList.length - 1
    ) {
      const nextArticle = swipeList[currentArticleIndex + 1];
      history.replace(`/post/${nextArticle.slug}`);
      dispatch(setSwipeIndex(currentArticleIndex + 1));
    }
  }

  return (
    <>
      {/* NOTE: Left here in case header comes back. */}
      <div ref={dummyTopRef} />

      {isLoading ? (
        <Loader />
      ) : (
        <SwipeDetector
          onSwipeLeft={handleNavigateNext}
          onSwipeRight={handleNavigatePrevious}
        >
          <Wrapper>
            <Header ref={headerRef}>
              <div className='top'>
                <div>
                  <time>
                    {renderPublicationDate(article?.publication_date)}
                  </time>
                  <div className='badges'>
                    <SpecialtyBadges
                      specialties={
                        article?.medical_specialties
                          ?.slice(0, 4)
                          // NOTE: hotfix for corrupted data, remove later.
                          .filter((el) => !!el) ?? []
                      }
                    />
                  </div>
                </div>
                {isHeaderOnScreen && <CloseAltIcon onClick={handleExit} />}
              </div>

              <div>
                <ArticleTypeHeading>
                  {t(
                    `articleTypes.${article?.article_type ??
                    SearchFilterPublicationTypeEnum.CLINICAL_STUDY
                    }`
                  )}
                </ArticleTypeHeading>
                <h1>{article?.title}</h1>
              </div>
            </Header>

            <StickyHeader
              className={!isHeaderOnScreen ? "floating" : ""}
              style={{
                top: dummyTopRef.current?.clientHeight ?? 0,
              }}
            >
              {!isHeaderOnScreen && (
                <>
                  <div
                    style={{
                      height: "var(--safe-area-top)",
                      background: "#ecf0f5",
                    }}
                  />
                  <ProgressBar progress={scrollProgress} />
                </>
              )}
              <ActionsContainer>
                <ActionItem>
                  <RoundIcon>
                    <CustomIcon
                      iconName='marker'
                      style={{
                        background: isSaved
                          ? "linear-gradient(180deg, #FFD000 0%, #FFA100 100%)"
                          : "#90A4AE",
                      }}
                      onClick={() => setPlaylistsPanelVisible(true)}
                    />
                  </RoundIcon>
                  <span>
                    {Math.max(
                      0,
                      (article?.metrics?.saveds ?? 0) +
                      getMetricBonus(isSaved, memSaved)
                    )}
                  </span>
                </ActionItem>

                <ActionItem>
                  <RoundIcon>
                    <CustomIcon
                      iconName='heart'
                      style={{
                        background: isLiked ? "#FF8800" : "#90A4AE",
                      }}
                      onClick={handleLike}
                    />
                  </RoundIcon>
                  <span>
                    {Math.max(
                      0,
                      (article?.metrics?.likes ?? 0) +
                      getMetricBonus(isLiked, memLiked)
                    )}
                  </span>
                </ActionItem>

                <ActionItem>
                  <RoundIcon>
                    <CustomIcon
                      iconName={
                        wasShareHighlighted ? "whatsapp" : "share_plane"
                      } // shareIconSize > 0 whatsapp
                      style={{
                        background: wasShareHighlighted ? "#1fb557" : "#90A4AE",
                        transition: "all 0.2s ease-in-out",
                      }}
                      scale={isShareHighlighted ? 1.5 : 1}
                      onClick={handleShare}
                    />
                  </RoundIcon>
                  <span>
                    {Math.max(
                      0,
                      article?.metrics?.shares ?? 0 + (wasShared ? 1 : 0)
                    )}
                  </span>
                </ActionItem>

                <BigActionsContainer>
                  <div
                    className={`${isHeaderOnScreen ? "big-actions" : "small-actions"
                      }`}
                  >
                    <AudioPlayer
                      article={article}
                      trackNumber={swipeList.length}
                      trackIndex={currentArticleIndex}
                      onPrevious={handleNavigatePrevious}
                      onNext={handleNavigateNext}
                    />

                    {isHeaderOnScreen ? (
                      <>
                        {article?.video && (
                          <VideoIcon
                            scale={1.2}
                            onClick={() =>
                              history.push(
                                article.video.videoFormat === VideoFormat.STORY
                                  ? `/video/story/${article.video.slug}`
                                  : `/video/${article.video.slug}`
                              )
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className='end-icons'>
                        {article?.video ? (
                          <VideoIcon
                            onClick={() =>
                              history.push(
                                article.video.videoFormat === VideoFormat.STORY
                                  ? `/video/story/${article.video.slug}`
                                  : `/video/${article.video.slug}`
                              )
                            }
                          />
                        ) : (
                          <div />
                        )}
                        <CloseAltIcon onClick={handleExit} />
                      </div>
                    )}
                  </div>
                </BigActionsContainer>
              </ActionsContainer>
            </StickyHeader>

            <JournalLink
              to={"/journal/" + article?.journal?.uid}
              onClick={() =>
                gtmItemsData(
                  ItemsDataEvent.VIEW_ORIGINAL_PAPER,
                  convertContentToItemData(
                    [article],
                    ItemDataEventListName.ARTICLE_PAGE,
                    ItemDataVariant.ARTICLE
                  )
                )
              }
            >
              <span className='from'>{t("Original Publication from")}</span>
              <span className='journal-name'>
                {article?.journal?.name}
                <CustomIcon iconName='link' />
              </span>
            </JournalLink>

            <ContentWrapper>
              <ContentPartWrapper>
                {!!article?.conclusion?.length && (
                  <section>
                    <h2>
                      <LemonIcon />
                      {t("Conclusion")}
                    </h2>
                    <ul>
                      {article?.conclusion
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                    </ul>
                  </section>
                )}

                {article?.background && (
                  <section>
                    <h2>
                      <GrapesIcon />
                      {t("Background")}
                    </h2>
                    <ExpandableText
                      isExpanded={isBackgroundExpanded}
                      setExpand={setBackgroundExpanded}
                    >
                      {article?.background}
                    </ExpandableText>
                  </section>
                )}

                {article?.objectives && (
                  <section>
                    <h2>
                      <PineappleIcon />
                      {t("Objectives")}
                    </h2>
                    <ul>
                      {article?.objectives
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                    </ul>
                  </section>
                )}

                {!!article?.images?.length ? (
                  <section>
                    <h2 style={{ alignItems: "flex-start" }}>
                      <AppleIcon />
                      {t("Figures")}
                    </h2>
                  </section>
                ) : (
                  <section style={{ padding: 0 }} />
                )}
              </ContentPartWrapper>

              {/* NOTE: Break content flow to make image carousel work. */}
              {!!article?.images?.length && (
                <ImageCarousel
                  images={
                    article?.images?.filter((el) => !!el) ?? // NOTE: hotfix for corrupted data, remove later.
                    []
                  }
                />
              )}

              <ContentPartWrapper>
                <section style={{ padding: 0 }} />
                {article?.methodology && (
                  <section>
                    <h2>
                      <RaspberryIcon />
                      {t("Methodology")}
                    </h2>
                    <ExpandableText
                      isExpanded={isMethodologyExpanded}
                      setExpand={setMethodologyExpanded}
                    >
                      {article?.methodology}
                    </ExpandableText>
                  </section>
                )}

                {article?.results && (
                  <section>
                    <h2>
                      <CherryIcon />
                      {t("Results")}
                    </h2>
                    <ul>
                      {article?.results
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                    </ul>
                  </section>
                )}

                {article?.limitations && (
                  <section>
                    <h2>
                      <AvocadoIcon />
                      {t("Limitations")}
                    </h2>
                    <ExpandableText
                      isExpanded={isLimitationsExpanded}
                      setExpand={setLimitationsExpanded}
                    >
                      {article?.limitations}
                    </ExpandableText>
                  </section>
                )}

                {article?.disclosure && (
                  <section>
                    <h2 style={{ alignItems: "flex-start" }}>
                      <PeachIcon />
                      {t("Disclosure Statement")}
                    </h2>
                    <p>{article?.disclosure}</p>
                  </section>
                )}

                {article?.primary_author && (
                  <section>
                    <h2>
                      <BlenderIcon />
                      {t("Authors")}
                    </h2>
                    <AuthorList>
                      {[article.primary_author, ...article.authors]
                        .filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((author, index) => {
                          return (
                            <div className='author-item' key={index}>
                              <AuthorIcon />
                              <span>{author}</span>
                            </div>
                          );
                        })}
                    </AuthorList>
                  </section>
                )}

                {article?.keywords && (
                  <section>
                    <h2>
                      <StrawberryIcon />
                      {t("Keywords")}
                    </h2>
                    <p>
                      {article?.keywords
                        ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                        .map((keyword, index) => (
                          <span key={"keyword" + keyword + index}>
                            {keyword}
                            {index <
                              (article?.keywords?.length as number) - 1 && ", "}
                          </span>
                        ))}
                    </p>
                  </section>
                )}

                {article?.edito && (
                  <section>
                    <ArticleExternalLink href={article.edito} isEditorial />
                  </section>
                )}

                {article?.doi && (
                  <section>
                    <ArticleExternalLink href={article?.doi} />
                  </section>
                )}
              </ContentPartWrapper>
            </ContentWrapper>

            {!!relatedArticles.length && (
              <RelatedArticlesWrapper>
                <h2>
                  {location?.state?.sponsorised ? (
                    `${t("Playlist")} ${location?.state?.playlist?.company?.name
                    }'s playlist`
                  ) : (
                    <>
                      {t("Refill about")}
                      <br />
                      {appLang === SupportedLanguagesEnum.FR
                        ? "aeouiAEOUI".includes(
                          getItemTranslation(articleFirstSpecialty)[0]
                        )
                          ? "d'"
                          : "de "
                        : " "}
                      {getItemTranslation(articleFirstSpecialty)}
                    </>
                  )}
                </h2>
                <CardList>
                  {relatedArticles
                    ?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later.
                    .map((article) => (
                      <ArticleCard
                        key={article._id}
                        article={article}
                        gtmItemListName={ItemDataEventListName.ARTICLE_PAGE}
                      />
                    ))}
                </CardList>
              </RelatedArticlesWrapper>
            )}
          </Wrapper>
        </SwipeDetector>
      )}

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={article}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={handleSave}
      />
    </>
  );
}

function VideoIcon({ scale, onClick }: { scale?: number; onClick: Function }) {
  return (
    <RoundIcon
      style={{
        background: "linear-gradient(180deg, #FFD000 0%, #FFA100 100%)",
      }}
    >
      <CustomIcon
        iconName='videoPublication'
        className='video-icon'
        onClick={onClick}
        scale={scale || 1}
      />
    </RoundIcon>
  );
}

function ImageCarousel({ images }: { images: { _id: string; url: string }[] }) {
  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <>
      {images?.length > 0 && (
        <>
          <div
            onClick={() => {
              setImageViewerOpen(true);
              disableScroll.on();
              if (isNativeIOS) iosSetBackgroundColor("#191919");
            }}
          >
            <Carousel
              autoFocus={false}
              autoPlay={false}
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
              interval={1e12}
              stopOnHover={true}
              infiniteLoop={true}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              centerMode={false}
              selectedItem={imageIndex}
              onChange={(to) => setImageIndex(to)}
            >
              {images.map(
                (figure: { _id: string; url: string }, index: number) => (
                  <img
                    key={figure?._id + index}
                    src={figure?.url}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                )
              )}
            </Carousel>
          </div>
          <ImageViewer
            open={isImageViewerOpen}
            imgs={images}
            imageIndex={imageIndex}
            updateIndex={(to: number) => setImageIndex(to)}
            onClose={() => {
              setImageViewerOpen(false);
              disableScroll.off();
            }}
          />
          <div
            style={{
              width: "fit-content",
              margin: "15px auto",
            }}
          >
            {images?.map(
              (_, index) =>
                index < 14 && (
                  <CustomIndicators
                    key={"indicatorsImageView" + index}
                    active={imageIndex === index}
                  />
                )
            )}
          </div>
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  margin-bottom: 0;
  background: white;
  font-family: "Inter";
`;

const Header = styled.div`
  position: relative;
  padding: calc(40px + var(--safe-area-top)) 22px 22px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #ecf0f5;
  font-family: Inter;
  color: #212121;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }

    .badges {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }

  time {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.48px;
  }

  h1 {
    margin: 10px 0 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.6px;
  }
`;

const StickyHeader = styled.div`
  box-sizing: border-box;
  width: 100%;

  &.floating {
    position: fixed;
    z-index: 10;
  }
`;

const ProgressBar = styled.div<{ progress: number }>`
  background: linear-gradient(
    to right,
    #ffc408 0 ${(props) => props.progress * 100}%,
    #fff 0
  );
  width: 100%;
  height: 13px;
`;

const ActionsContainer = styled.div`
  position: relative;
  padding: 10px 22px 16px;
  border-radius: 0 0 20px 20px;
  background: #ecf0f5;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BigActionsContainer = styled.div`
  flex-grow: 1;

  .small-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    .end-icons {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }
  }

  & .big-actions {
    position: absolute;
    bottom: -24px;
    right: 22px;
    display: flex;
    gap: 10px;

    .play-icon,
    .video-icon {
      width: 60px !important;
      height: 60px !important;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 115%; /* 13.8px */
    color: #90a4ae;
  }
`;

const JournalLink = styled(Link)`
  margin: 16px 0 24px;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Inter;
  color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4c5861);

  .from {
    font-size: 10px;
    font-weight: 300;
    line-height: 6px; /* 60% */
  }

  .journal-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px; /* 50% */
  }
`;

const ContentWrapper = styled.div`
  padding: 0 32px 100px;
`;

const ContentPartWrapper = styled.div`
  section {
    padding: 40px 0 24px;
    border-bottom: 1px solid #d2dce2;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border: none;
    }
  }

  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: Inter;
    font-size: 30px;
    font-weight: 900;
    line-height: 100%; /* 30px */
    color: var(--JUISCI-Black-Matters, #212121);

    svg {
      width: 30px;
      height: 30px;
    }
  }

  p,
  ul,
  .LinesEllipsis {
    margin: 24px 0;
    color: var(--JUISCI-Black-Matters, #212121);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.002px;
    word-break: break-word;
  }

  .LinesEllipsis:last-of-type {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 24px;

    li {
      ::before {
        content: "• ";
        color: #f42cab;
      }
    }
  }
`;

const AuthorList = styled.div`
  margin: 24px 0 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 6px;

  .author-item {
    display: flex;
    align-items: center;

    &::after {
      content: ",";
    }

    &:last-child::after {
      content: ""; /* Removes the comma for the last author */
    }

    svg {
      margin-right: 3px;
    }

    span {
      color: #212121;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const RelatedArticlesWrapper = styled.div`
  padding: 10px 8px calc(16px + var(--safe-area-bottom));
  background: linear-gradient(
    180deg,
    #ff699c 0%,
    #fe5763 26.99%,
    #ff8a00 51.99%,
    #fdb955 77.51%,
    #ffc408 100%
  );

  h2 {
    font-family: "Work Sans";
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
