import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticleModel } from "./article.types";

interface ReducerState {
  cache: {
    list: ArticleModel[];
    mainSpecialty: ArticleModel[];
    secondarySpecialty: ArticleModel[];
    swipeList: ArticleModel[];
  };
  currentArticleIndex: number;
}

const initialState: ReducerState = {
  cache: {
    list: [],
    mainSpecialty: [],
    secondarySpecialty: [],
    swipeList: [],
  },
  currentArticleIndex: -1,
};

export const slice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    memorizeArticlesList: (state, action: PayloadAction<ArticleModel[]>) => {
      state.cache.list = action.payload;
    },
    memorizeMainSpecialtyArticles: (state, action: PayloadAction<ArticleModel[]>) => {
      state.cache.mainSpecialty = action.payload;
    },
    memorizeSecondarySpecialtyArticles: (state, action: PayloadAction<ArticleModel[]>) => {
      state.cache.secondarySpecialty = action.payload;
    },
    memorizeSwipeList: (state, action: PayloadAction<ArticleModel[]>) => {
      state.cache.swipeList = action.payload;
    },
    clearMainSpecialtyArticles: (state) => {
      state.cache.mainSpecialty = [];
    },
    clearSecondarySpecialtyArticles: (state) => {
      state.cache.secondarySpecialty = [];
    },
    clearSwipeList: (state) => {
      state.cache.swipeList = [];
    },
    setSwipeIndex: (state, action: PayloadAction<number>) => {
      state.currentArticleIndex = action.payload;
    },
    clearSwipeIndex: (state) => {
      state.currentArticleIndex = -1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  memorizeArticlesList,
  memorizeMainSpecialtyArticles,
  memorizeSecondarySpecialtyArticles,
  memorizeSwipeList,
  clearMainSpecialtyArticles,
  clearSecondarySpecialtyArticles,
  clearSwipeList,
  setSwipeIndex,
  clearSwipeIndex,
} = slice.actions;

export default slice.reducer;
