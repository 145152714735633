import React, { Component } from "react";
import styled from "styled-components";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import { store } from "../../redux";
import Loader from "../../components/Loader";
import JuisciPattern from "../../assets/images/mobile-pattern.svg";
import { t } from "i18next";
import JuisciBacground from "../../assets/images/mobile-pattern.svg";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { getVideoStoriesList } from "../../domains/video/endpoints/getVideoStoriesList";
import i18n from "../../config/i18n";

const OFFSET_VALUE = 30;

class VideoStoryListPage extends Component {
  contentRef = React.createRef(null);
  state = {
    isLoading: false,
    storyList: [],
    offset: OFFSET_VALUE,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const stories = this.props.videos.cache.videoStories;

    // Re-fetch if not initialize or not enough (valid stories)
    if (!stories.length || this.getValidStories().length < OFFSET_VALUE) {
      if (stories.length === 0) {
        // Only display loader if there is no video
        this.setState({ isLoading: true });
      }
      await this.props.getVideoStoriesList({
        offset: 0,
        limit: 100,
        language: i18n.resolvedLanguage,
      });
    }
    this.setState({ isLoading: false });

    // Use refreshed story list
    this.setState({
      storyList: [...this.getValidStories().slice(0, OFFSET_VALUE)],
    });

    if (localStorage.getItem("videoStoryListPageScrollPosition")) {
      const scrollPosition = parseInt(
        localStorage.getItem("videoStoryListPageScrollPosition")
      );
      if (scrollPosition) this.contentRef?.current.scrollTo(0, scrollPosition);
      localStorage.removeItem("videoStoryListPageScrollPosition");
    }
  }

  componentWillUnmount() {
    localStorage.setItem(
      "videoStoryListPageScrollPosition",
      this.contentRef?.current?.scrollTop
    );
  }

  // Fix: undefined videos
  getValidStories() {
    const list = this.props.videos.cache.videoStories;
    return list.filter((video) => !!video);
  }

  handleAddNext() {
    console.log("fetch...");

    this.setState(
      {
        storyList: [
          ...this.state.storyList,
          ...store
            .getState().videos.cache.videoStories.slice(
              this.state.offset,
              this.state.offset + OFFSET_VALUE
            ),
        ],
        offset: this.state.offset + OFFSET_VALUE,
      },
      () => console.log("fetch next", this.state.storyList)
    );
  }

  // Groupe les éléments du tableau en sous-tableaux de taille `groupSize`
  groupBy(array, groupSize) {
    const groups = [];
    for (let i = 0; i < array.length; i += groupSize) {
      groups.push(array.slice(i, i + groupSize));
    }
    return groups;
  }

  render() {
    const stories = this.props.videos.cache.videoStories;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <PageContainer backgroundImage={JuisciPattern}>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>{t("Videos")}</HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <div ref={this.contentRef} className='content' id='content-ref'>
          <InfiniteScroll
            dataLength={this.state.storyList.length}
            next={() => {
              this.handleAddNext();
            }}
            hasMore={true}
            pullDownToRefresh={false}
            scrollableTarget='content-ref'
          >
            {this.groupBy(this.state.storyList, 2).map((pair, index) => (
              <div
                key={`${index}-${pair[0]._id}--story-group`}
                className='grid'
              >
                {pair.map(
                  (story) =>
                    story && (
                      <div
                        key={story._id + "--story-homevideocard"}
                        className='story-card'
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/video/story/" + story.slug,
                            state: {
                              story,
                              stories,
                            },
                          })
                        }
                      >
                        <img src={story.apiVideo.thumbnail} alt='' />
                        <p>{story.title}</p>
                      </div>
                    )
                )}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </PageContainer>
    );
  }
}

function mapState(state) {
  return {
    videos: state.videos,
    user: state.user,
  };
}

const mapDispatch = {
  getVideoStoriesList: getVideoStoriesList.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(VideoStoryListPage);

const PageContainer = styled.div`
  background-image: linear-gradient(
      123.08deg,
      rgba(255, 255, 255, 0.97) 0%,
      rgba(236, 240, 245, 0.97) 40%,
      rgba(251, 200, 28, 0.97) 150%,
      rgba(252, 196, 8, 0.97) 200%
    ),
    url(${(props) => props.backgroundImage});
  height: 100vh;
  overflow: hidden;
  padding: 0;

  .content {
    padding: 21px;
    height: calc(100dvh - var(--safe-area-top) - 90px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    justify-items: center;
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 4px;
  }

  .story-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 9/16;

      object-fit: cover;
      border: 2px solid #ffc408;
      border-radius: 10px;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0.8) 0%,
          rgba(240, 240, 240, 0.9) 100%
        ),
        url(${JuisciBacground}) !important;
      background-size: contain;
      background-color: #e5e5e5;
    }

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 130%;
      text-align: center;
      color: #212121;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;

    span {
      color: #ce0868;
    }
  }

  .page-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 21px;
  }
`;
