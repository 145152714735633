import { ChangeEvent, ReactChildren, ReactNode, SyntheticEvent } from "react";
import CustomIcon from "../CustomIcon";
import { Avatar, CustomButton, Spacer } from "../global";
import styled from "styled-components";
import { t } from "i18next";

export default function CustomAvatarUploader({
  avatarUrl,
  buttonText,
  onChange,
  onClear,
  disableUpload = false,
}: {
  avatarUrl: string;
  buttonText: string;
  onChange: Function;
  onClear?: Function;
  disableUpload?: boolean;
}) {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const file = e.target.files[0];
      const blob = URL.createObjectURL(file);

      setTimeout(() => compressImage(file, blob), 500);

      // Reset image when switching to default then back to upload the same image
      e.target.value = "";
    }
  }

  async function compressImage(file: File, blob: string) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      const img = document.createElement("img");
      img.src = blob;

      img.onload = () => {
        const canvas = document.createElement("canvas");

        // Avatar dimensions
        const MAX_WIDTH = 600;
        const MAX_HEIGHT = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);

        const dataurl = canvas.toDataURL("image/jpeg");
        onChange && onChange(dataurl);
      }
    }
  }

  return (
    <Container>
      <div className='avatar-section'>
        {avatarUrl ? (
          <ProfilePicture src={avatarUrl} />
        ) : (
          <ProfilePictureEmpty />
        )}

        {onClear && (
          <div className='delete-icon' onClick={() => onClear()}>
            <CustomIcon iconName='cross_circle_big' increase />
          </div>
        )}
      </div>

      <div style={{ height: 20 }} />

      {disableUpload ? null : (
        <UploadAvatarButton disabled={disableUpload} onChange={handleChange}>
          {buttonText ?? t("edit_profile.avatar.title")}
        </UploadAvatarButton>
      )}
    </Container>
  );
}

export function UploadAvatarButton({ children, disabled, onChange }: {
  children: ReactNode | ReactChildren;
  disabled: boolean;
  onChange: Function;
}) {
  return (
    <div style={{ position: "relative" }}>
      <input
        disabled={disabled}
        onChange={(e) => onChange(e)}
        type='file'
        accept='image/*'
        style={{
          opacity: 0,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
        }}
      />
      <UploadButton
        className={`${disabled ? "disabled" : ""}`}
        disabled={disabled}
      >
        <CustomIcon iconName='camera' />
        {children}
      </UploadButton>
    </div>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .avatar-section {
    position: relative;
    width: 200px;
    height: 200px;
    margin: auto;

    .delete-icon {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
`;

const ProfilePicture = styled(Avatar)`
  align-self: center;
  border: none;
  box-shadow: none;
  width: 200px;
  height: 200px;
`;

const ProfilePictureEmpty = styled.div`
  align-self: center;
  border: none;
  box-shadow: none;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #f9f9f9;
`;

const UploadButton = styled(CustomButton)`
  display: flex;
  justify-content: center;
  gap: 10px;

  &:disabled {
    svg {
      stroke: #90a4ae;
      fill: #90a4ae;
    }
  }
`;
