import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { useRoomPreflightTemp } from "../../domains/room/hooks/useRoomPreflightTemp";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import { HeaderBoxSimple, HeaderLinks, HeaderLogoTitleTagline, NavHome, NavLang } from "../../components/app/headers/layout";
import { RoomPageTab, RoomPageWrapper, RoomTabSwitcher } from "../../components/room/layout";
import Loader from "../../components/Loader";
import disableScroll from "disable-scroll";
import ForYouMainSlider from "../../components/forYou/ForYouMainSlider";
import { useLazyGetForYouContentQuery } from "../../domains/forYou/endpoints/getForYouContent";
import { gtmItemsData } from "../../tools/reactgaEvents";
import { ItemDataEventListName, ItemDataVariant, ItemsDataEvent } from "../../interfaces";
import { displayToast } from "../../components/app/AppToast";
import { clearRecommendedContent, memorizeRecommendedContent } from "../../domains/forYou/forYou.reducer";
import styled from "styled-components";

export default function RoomForYouPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const dispatch = useAppDispatch();
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const recommendedContent = useAppSelector((state) => state.forYou.cache.recommendedContent);
  const { appLang, t } = useAppLang();
  const [isLoadingContent, setLoadingContent] = useState(!recommendedContent.length);
  const { isLoadingRoom, roomData, preflightUserRoom } = useRoomPreflightTemp(roomId);
  const [getForYouContent, {
    isFetching: isFetchingContent,
  }] = useLazyGetForYouContentQuery();

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentRoomId) {
      scrollTo(0, 0);

      if (!recommendedContent.length) {
        loadRecommendedContent(true);
      }
    }

    return () => {
      // NOTE: Clear cache to force refetch when back from other sections than For You
      if (!history.location.pathname.startsWith("/room/foryou")) {
        dispatch(clearRecommendedContent());
        localStorage.removeItem("foryou_carousel_index");
      }
    }
  }, [currentRoomId, appLang]);

  useEffect(() => {
    if (recommendedContent.length) {
      setLoadingContent(true);
      loadRecommendedContent(true);
    }
  }, [appLang]);

  function loadRecommendedContent(resetList = false) {
    try {
      getForYouContent({
        limit: 10,
        offset: resetList ? 0 : recommendedContent.length,
        language: appLang,
      })
        .unwrap()
        .then((docs) => {
          const newList = resetList ? docs : [...recommendedContent, ...docs];
          dispatch(memorizeRecommendedContent(newList));
          handleGTM(docs);
          setLoadingContent(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load recommended content.");
      displayToast(t("error:default"));
    }
  }

  function handleGTM(docs: any[]) {
    const items = docs.map((content) => {
      const item: any = {
        item_list_name: ItemDataEventListName.FORYOU,
        item_id: content._id,
        item_category: content.medical_specialties?.[0]?.uid,
        item_category2: "standard",
        quantity: 1,
      };

      if ("apiVideo" in content) {
        // Video
        item.name = content.title;
        item.item_brand = content.company?.name;
        item.item_variant = ItemDataVariant.VIDEO_STORY;
      } else if ("answer" in content) {
        // Nectar
        item.name = content.question;
        item.item_brand = content.organisations?.[0]?.name;
        item.item_variant = ItemDataVariant.NECTAR;
      } else {
        // Article
        item.name = content.title;
        item.item_brand = content.journal?.name;
        item.item_variant = ItemDataVariant.ARTICLE;
      }
      return item;
    });

    gtmItemsData(ItemsDataEvent.VIEW_ITEM_LIST, items);
  }
  function handleSlideChange(newIndex: number) {
    if (newIndex >= recommendedContent.length - 3) {
      if (recommendedContent.length < 50 && !isFetchingContent) {
        loadRecommendedContent();
      }
    }
  }

  if (isLoadingRoom) return <Loader />;

  return (
    <Wrapper
      style={{
        backgroundColor: roomData?.primaryColor ?? "",
        gap: 0,
      }}
    >
      <SafeAreaTopWrapper>
        <HeaderBoxSimple>
          <HeaderLinks><NavHome /></HeaderLinks>
          <HeaderLogoTitleTagline
              logo={roomData?.organisation?.logo?.url || ''}
              title={roomData?.name || ''}
              tagline={roomData?.organisation.name || ''}
            />
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxSimple>

        <RoomTabSwitcher activeTab={RoomPageTab.FORYOU} />
      </SafeAreaTopWrapper>

      <ForYouMainSlider
        contentList={recommendedContent}
        isLoading={isLoadingContent}
        // TODO: improve this part
        isLocked={isLoadingContent}
        onSlideChange={handleSlideChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(RoomPageWrapper)`
  .story-cover-container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .fy-blender-container {
    padding-top: 48px;
  }
`;
