import { t } from "i18next";
import { displayToast } from "../../app/AppToast";
import ConfirmModal from "../../app/ConfirmModal";

export default function DownloadAppModal({ isVisible, onClose }: {
  isVisible: boolean;
  onClose: Function;
}) {
  function handleConfirm() {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as unknown as { MSStream: any }).MSStream;
    const isAndroid = /Android/.test(navigator.userAgent) && !(window as unknown as { MSStream: any }).MSStream;

    if (isIOS)
      window.location.href =
        "https://apps.apple.com/fr/app/juisci/id1588725828";
    else if (isAndroid)
      window.location.href = `https://play.google.com/store/apps/details?id=com.juisci.app&hl=us&gl=US`;
    else displayToast(t("error:default"));

    onClose();
  }

  return (
    <ConfirmModal
      isVisible={isVisible}
      promptText={t("Download Juisci Text")}
      onConfirm={handleConfirm}
      onCancel={onClose}
      cancelText={t("Maybe later")}
      confirmText={t("Install app")}
    />
  );
}
