import { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { useLazyGetUserQuery } from "../../redux/user/user.service";
import { ContentFormatsEnum } from "../../interfaces/content.interface";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { UserModel } from "../../domains/user/user.types";
import { getUserPlaylists } from "../../domains/playlist/endpoints/getUserPlaylists";
import { getUserById } from "../../domains/user/endpoints/getUserById";
import { getUserLastArticle } from "../../domains/user/endpoints/getUserLastArticle";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import styled from "styled-components";
import ProfileHeader from "../../components/app/headers/ProfileHeader";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import { Trans } from "react-i18next";
import { displayToast } from "../../components/app/AppToast";
import { gtmBasicEvent } from "../../tools/reactgaEvents";
import CustomIcon from "../../components/CustomIcon";
import LearningActivity from "../../components/gamification/LearningActivity";
import ShareActivity from "../../components/gamification/ShareActivity";
import PreviewCard from "../../components/profile/PreviewCard";
import ShareSheet from "../../components/ShareSheet";
import ProfilePicture from "../../components/ProfilePicture";
import UserConnectButton from "../../components/app/UserConnectButton";
import ExpandableText from "../../components/app/ExpandableText";
import TabSwitcher from "../../components/app/TabSwitcher";
import { Tab, Tabs } from "@mui/material";
import moment from "moment";
import { iosPlaySound } from "../../tools/ios";
import {
  GTMBasicEvent,
  SoundEffect,
  SupportedLanguagesEnum,
} from "../../interfaces";
import { ReactComponent as LinkIcon } from "../../assets/icons/link-simple.svg";

enum MainTab { INFO, LIBRARY }
enum ContentTab { ARTICLES, VIDEOS, NECTARS }

export default function ProfilePage({ isMe }: { isMe: boolean }) {
  // Router
  const history = useHistory();
  const location = useLocation();
  const { id: userId } = useParams<{ id?: string }>();
  // State
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserModel | null>(null);
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  const [lastReadArticle, setLastReadArticle] = useState<any>(null);
  const [isBioTextExpanded, setBioTextExpanded] = useState(false);
  // Derived
  const searchParams = new URLSearchParams(location.search);
  const activeMainTab = parseInt(searchParams.get("mainTab") ?? MainTab.INFO.toString());
  const activeContentTab = parseInt(searchParams.get("contentTab") ?? ContentTab.ARTICLES.toString());
  const likedArticles = user?.likes ?? [];
  const likedVideos =
    user?.likedContent.filter(
      (content: { content_format: ContentFormatsEnum }) => content.content_format === ContentFormatsEnum.VIDEO
    ) ?? [];
  const likedNectars =
    user?.likedContent.filter(
      (content: { content_format: ContentFormatsEnum }) => content.content_format === ContentFormatsEnum.NECTAR
    ) ?? [];
  // Query
  const [getUser] = useLazyGetUserQuery();
  // Custom
  const { appLang, t } = useAppLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    disableScroll.off();

    initialize();
  }, [userId]);

  async function initialize() {
    const { isRedirected } = await preflightUser({
      history,
      onboardingMessage: t("error:notOnboarded.default"),
      memorizePath: true,
    });
    if (isRedirected) return;

    await fetchProfile();
  }

  async function fetchProfile() {
    try {
      let userData;
      if (isMe) {
        const result = await getUser({});
        if (!result.data) {
          throw new Error("No user data returned from getUser.");
        }
        userData = result.data;
      } else if (userId) {
        userData = await getUserById(userId);
        gtmBasicEvent(GTMBasicEvent.VIEW_PROFILE);
      } else {
        throw new Error("No user ID provided");
      }

      setUser(userData);
      setPlaylists(await getUserPlaylists(userData.uid));
      setLastReadArticle(await getUserLastArticle(userData.uid));
    } catch (error) {
      console.error("Error fetching profile:", error);
      displayToast(t("error:default"));
      history.goBack(); // TODO: handle exit paths.
    } finally {
      setIsLoading(false);
    }
  }

  function updateMainTabIndex(index: number) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("mainTab", index.toString());
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }

  function updateContentTabIndex(index: number) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("contentTab", index.toString());
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }

  function handleShareGameProfile() {
    // const profile = this.props.user?.user;
    const location = window.location.protocol + "//" + window.location.host;

    const shareText =
      appLang === SupportedLanguagesEnum.FR
        ? "Je t’invite à rejoindre Juisci et à profiter d'un contenu illimité en cliquant sur ce lien."
        : "I invite you to join Juisci and benefit from unlimited content by clicking on this link.";

    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: shareText,
          // url: `${location}/user/game/activity/${profile?.uid}`,
          url: `${location}/discovery`,
        })
        .then(() => {
          iosPlaySound(SoundEffect.SHARE);
          displayToast(t("toast.success.profile_shared_me"), "success");
        })
        .catch(() => { });
    } else {
      displayToast(t("toast.fail.browser"));
    }
  }

  return (
    <Wrapper>
      <ProfileHeader user={user} />

      <ShareSheet type="profile" />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TopWrapper>
            <UserInfoWrapper>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <ProfilePicture
                  user={user}
                  height={52}
                  withBorder={true}
                  withShadow={true}
                />
                <div>
                  <div style={{ marginBottom: "5px" }}>
                    <h2>{user?.firstname}</h2>
                    <h1>{user?.lastname}</h1>
                  </div>
                  <div>
                    {user?.profession && <h4>{getItemTranslation(user?.profession)}</h4>}
                    {user?.main_specialty && <h4>{getItemTranslation(user?.main_specialty)}</h4>}
                    <h4 style={{ marginTop: 8 }}>
                      {t("Joined on")} {moment(user?.meta.creationDate).calendar()}
                    </h4>
                  </div>
                </div>
              </div>
              {!isMe && !!user && (
                <div>
                  <UserConnectButton
                    user={user}
                    style={{
                      padding: "7px 12px",
                      fontSize: 14,
                      background: "#CE0868",
                      color: "#FFFFFF",
                      display: "inline-block",
                    }}
                  />
                </div>
              )}
            </UserInfoWrapper>
            <UserDescriptionWrapper>
              {user?.link && (
                <a
                  className="user-link"
                  target="_blank"
                  href={user.link}
                  style={{ marginBottom: "10px" }}
                >
                  <LinkIcon />
                  {user.link}
                </a>
              )}
              {user?.bio ? (
                <ExpandableText
                  isExpanded={isBioTextExpanded}
                  setExpand={setBioTextExpanded}
                >
                  {user?.bio}
                </ExpandableText>
              ) : isMe ? (
                <MissingProfileInfo
                  emoji="🖋️"
                  text={t("profile.addBio")}
                  link="/settings/edit/profile"
                />
              ) : (
                <p>{t("profile.noBio")}</p>
              )}
            </UserDescriptionWrapper>
          </TopWrapper>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 20px",
            }}
          >
            <TabSwitcher
              tabs={[t("profile.information"), t("profile.library")]}
              onTabChange={updateMainTabIndex}
              activeTab={activeMainTab}
            />
          </div>

          <BodyWrapper>
            {activeMainTab === MainTab.INFO && (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                  }}
                >
                  {isMe && // NOTE: Remove this guard once game activity page works with other users
                    <LearningActivity
                      isMe={isMe}
                      onClick={
                        () =>
                          isMe
                            ? history.push("/profile/game/activity")
                            : history.push("/user/game/activity/" + user?.uid) // TODO: Page is a mess with other users
                      }
                    />
                  }

                  {isMe && (
                    <ShareActivity onClick={handleShareGameProfile} />
                  )}
                </div>

                <hr />

                <h3>{t("Medical specialties")}</h3>

                <SpecialtyTagWrapper>
                  {user?.main_specialty ||
                    (user?.medical_specialties &&
                      user.medical_specialties.length > 0) ? (
                    <>
                      {user?.main_specialty && (
                        <SpecialtyTag primary>
                          {getItemTranslation(user.main_specialty)}
                        </SpecialtyTag>
                      )}
                      {user?.medical_specialties?.map((specialty) => (
                        <SpecialtyTag key={specialty._id}>
                          {getItemTranslation(specialty)}
                        </SpecialtyTag>
                      ))}
                    </>
                  ) : isMe ? (
                    <MissingProfileInfo
                      emoji="🏷️"
                      text={t("addSpeciaties")}
                      link="/settings/edit/profile"
                    />
                  ) : (
                    <p>{t("profile.no_spe")}</p>
                  )}
                </SpecialtyTagWrapper>

                <hr />

                {/* TODO: Organisations/workplace doesn't work */}
                {/* <h3>{t("profile.workplace")}</h3>
                {user?.organisations ? (
                  <div>{user.organisations}</div>
                ) : isMe ? (
                  <MissingProfileInfo
                    emoji="🏢"
                    text="Add your workplace" // TODO: i18n
                    link="/settings/edit/profile"
                  />
                ) : (
                  <p>{t("No location specified yet")}</p>
                )}

                <hr /> */}

                <h3>{t("profile.awards")}</h3>
                {user?.awards && user.awards.length > 0 ? (
                  user.awards.map((award) => (
                    <AwardsWrapper key={award._id}>
                      <span>{award.label}</span>
                      <p>{award.content}</p>
                    </AwardsWrapper>
                  ))
                ) : isMe ? (
                  <MissingProfileInfo
                    emoji="🏆"
                    text={t("profile.add_award")}
                    link="/settings/edit/profile"
                  />
                ) : (
                  <p>{t("profile.no_awards")}</p>
                )}
              </>
            )}
            {activeMainTab === MainTab.LIBRARY && (
              <>
                <h3>{t("profile.lastread")}</h3>
                {lastReadArticle && lastReadArticle.status !== 404 ? (
                  <PreviewCard content={lastReadArticle} disabled />
                ) : (
                  <p>{t("profile.no_lastread")}</p>
                )}

                <hr />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <h3 style={{ margin: 0 }}>
                    {isMe ? t("My Journals") : t("Followed journals")}
                  </h3>
                  {user?.journals && user?.journals?.length > 0 ? (
                    <Link
                      className="see-all"
                      to={{
                        pathname: "/profile/journals",
                        state: {
                          journals: user?.journals,
                        },
                      }}
                    >
                      {t("common:action.seeAll")}
                    </Link>
                  ) : null}
                </div>

                {user?.journals && user?.journals?.length > 0 ? (
                  <HScrollContainer className="scrollbar-hidden">
                    {isMe && (
                      <Link
                        to="/search/journals"
                        style={{ textDecoration: "none" }}
                      >
                        <AddJournalsButton>
                          <CustomIcon
                            iconName="search"
                            color="white"
                            style={{ marginBottom: 3 }}
                          />
                          <span>{t("Add you favorite journals")}</span>
                        </AddJournalsButton>
                      </Link>
                    )}

                    {user.journals.map((journal) => (
                      <Link key={journal?._id} to={`/journal/${journal?.uid}`}>
                        <JournalCard>
                          <img src={journal?.image?.url} />
                        </JournalCard>
                      </Link>
                    ))}
                  </HScrollContainer>
                ) : isMe ? (
                  <MissingProfileInfo
                    emoji="🔍"
                    text={t("Add you favorite journals")}
                    link="/search/journals"
                  />
                ) : (
                  <p>{t("No saved journals for this user")}</p>
                )}

                <hr />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <h3 style={{ margin: 0 }}>
                    {isMe ? (
                      t("profile.myplaylists")
                    ) : (
                      // TODO: missing an "s"
                      <Trans i18nKey="profile.playlists">
                        Playlist de {user?.firstname}
                      </Trans>
                    )}
                  </h3>
                  {playlists?.length > 0 ? (
                    <Link
                      className="see-all"
                      to={{
                        // TODO: fix link when on someone else's profile
                        pathname: "/profile/playlist",
                        state: {
                          journals: user?.journals,
                        },
                      }}
                    >
                      {t("common:action.seeAll")}
                    </Link>
                  ) : null}
                </div>

                {playlists?.length > 0 ? (
                  <HScrollContainer className="scrollbar-hidden">
                    {isMe && (
                      <Link
                        to="/profile/playlist/create"
                        style={{ textDecoration: "none" }}
                      >
                        <AddPlaylistsButton>
                          <CustomIcon
                            iconName="plus"
                            color="white"
                            style={{ marginBottom: 5 }}
                          />
                          <span>{t("profile.create_playlist")}</span>
                        </AddPlaylistsButton>
                      </Link>
                    )}

                    {playlists.map((playlist) => (
                      <Link
                        key={playlist?._id}
                        to={`/profile/playlist/${playlist?._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <ProfilePlaylistCard>
                          <div>
                            <h4>{playlist?.title}</h4>
                            {/* TODO: add or remove "s" by checking length of array - how to do that with i18n? */}
                            <h5>{playlist?.playlist?.length} publications</h5>
                          </div>
                        </ProfilePlaylistCard>
                      </Link>
                    ))}
                  </HScrollContainer>
                ) : isMe ? (
                  <MissingProfileInfo
                    emoji="➕"
                    text={t("profile.create_first_playlist")}
                    link="/profile/playlist/create"
                  />
                ) : (
                  <p>{t("No playlist for this user")}</p>
                )}

                <hr />

                <h3>
                  {isMe ? (
                    t("profile.mylikes")
                  ) : (
                    <Trans i18nKey="profile.likes">
                      Articles liked by {user?.firstname}
                    </Trans>
                  )}
                </h3>

                <ContentTabs
                  variant="fullWidth"
                  centered
                  value={activeContentTab}
                  onChange={(_, index: number) => {
                    console.log(index);
                    updateContentTabIndex(index);
                  }}
                >
                  <Tab label={t("Publications")} />
                  <Tab label={t("Videos")} />
                  <Tab label={t("Nectars")} />
                </ContentTabs>

                {activeContentTab === ContentTab.ARTICLES && (
                  <ContentList style={{ padding: 0 }}>
                    {likedArticles?.length > 0 ? (
                      likedArticles.map((article) => (
                        <PreviewCard content={article} />
                      ))
                    ) : isMe ? (
                      <MissingProfileInfo
                        emoji="❤️"
                        text={t("profile.likeFirstArticle")}
                        link="/search/articles"
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      <p style={{ marginTop: "20px" }}>
                        {t("profileNoArticleLiked")}
                      </p>
                    )}
                  </ContentList>
                )}

                {activeContentTab === ContentTab.VIDEOS && (
                  <ContentList>
                    {likedVideos?.length > 0 ? (
                      likedVideos.map((video) => (
                        <PreviewCard content={video} />
                      ))
                    ) : isMe ? (
                      <MissingProfileInfo
                        emoji="🎥"
                        text={t("profile.likeFirstVideo")}
                        link="/search/videos"
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      <p style={{ marginTop: "20px" }}>
                        {t("profileNoVideoLiked")}
                      </p>
                    )}
                  </ContentList>
                )}

                {activeContentTab === ContentTab.NECTARS && (
                  <ContentList>
                    {likedNectars?.length > 0 ? (
                      likedNectars.map((nectar) => (
                        <PreviewCard content={nectar} />
                      ))
                    ) : isMe ? (
                      <MissingProfileInfo
                        emoji="🍯"
                        text={t("profile.likeFirstNectar")}
                        link="/search/nectars"
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      <p style={{ marginTop: "20px" }}>
                        {t("profileNoNectarLiked")}
                      </p>
                    )}
                  </ContentList>
                )}
              </>
            )}
          </BodyWrapper>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background-color: white;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;

  h1 {
    color: #212121;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }

  h2 {
    color: #212121;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 300;
    margin: 0;
  }

  h4 {
    color: #212121;
    font-family: "Inter";
    font-size: 11px;
    font-weight: 300;
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;
    gap: 8px;

    color: #212121;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-decoration-line: underline;
    margin: 0;
  }

  p {
    color: #212121;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
`;

const UserInfoWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
`;

const UserDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    line-height: 1.5;
  }
`;

const BodyWrapper = styled.div`
  padding: 20px 20px calc(20px + var(--safe-area-bottom));

  h3 {
    color: #212121;
    font-family: "Inter";
    font-size: 21px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
  }

  p {
    margin: 0;
  }

  a.user-link, a.see-all {
    font-family: "Inter";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }

  hr {
    margin: 30px 0;
  }
`;

const SpecialtyTagWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SpecialtyTag = styled.div<{ primary?: boolean }>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${({ primary }) => (primary ? "#fff3ce" : "#FFFFFF")};
  border: ${({ primary }) => (primary ? "none" : "1px solid #ECF0F5")};
  width: max-content;
  font-family: "Inter";
  font-size: 11px;
  font-weight: 400;
  color: #ff9419;
`;

const AwardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 8px;
  padding: 8px 0;

  span {
    color: #212121;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
  }

  p {
    color: #000;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: stretch;
  gap: 10px;
  padding: 0px 20px;
  margin: 0 -20px; // NOTE: Negative margins to negate page padding
`;

const AddJournalsButton = styled.div`
  height: 70px;
  min-width: 100px;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(180deg, #ff699c 0%, #fe5763 100%);
  box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`;

const JournalCard = styled.div`
  height: 70px;
  min-width: 100px;
  border-radius: 10px;
  overflow: hidden;
  background: #ecf0f5;
  box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 80px;
    max-height: 40px;
    object-fit: contain;
  }
`;

const AddPlaylistsButton = styled.div`
  height: 100px;
  min-width: 120px;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(180deg, #ff699c 0%, #fe5763 100%);
  box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`;

const ProfilePlaylistCard = styled.div`
  height: 100px;
  width: 120px;
  border-radius: 10px;
  overflow: hidden;
  background: #ecf0f5;
  box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.1);
  display: flex;

  div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 8px;
  }

  h4 {
    color: #212121;
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 700;
    margin: 0;
  }

  h5 {
    color: #212121;
    text-align: center;
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    margin: 0;
  }
`;

function MissingProfileInfo({ emoji, text, link, style }: {
  emoji: string;
  text: string;
  link: string;
  style?: React.CSSProperties;
}) {
  return (
    <StyledMissingProfileInfo
      to={link}
      style={{ textDecoration: "none", ...style }}
    >
      <span>{emoji}</span> <p>{text}</p>
    </StyledMissingProfileInfo>
  );
};

const StyledMissingProfileInfo = styled(Link)`
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px dashed #bcbcbc;
  gap: 20px;

  span {
    font-size: 30px;
    opacity: 0.3;
  }

  p {
    color: #bcbcbc;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
`;

const ContentTabs = styled(Tabs)`
  margin: 0 -20px;
  .MuiButtonBase-root {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 5e-5em;
    text-transform: capitalize;
    color: #90a4ae;

    &.Mui-selected {
      color: #fe5763;
      font-weight: 700;
    }
  }
  
  .MuiTabs-indicator {
    height: 1.5px;
    background-color: #fe5763;
  }
`;

const ContentList = styled.div`
  min-height: 250px;
  padding: 0 20px;
`;