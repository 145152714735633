import { ArticleModel } from "../article.types";
import { getArticleBySlug } from "../endpoints/getArticleBySlug";
import { store } from "../../../redux";
import {
  memorizeArticlesList,
  memorizeMainSpecialtyArticles,
  memorizeSecondarySpecialtyArticles,
  memorizeSwipeList
} from "../article.reducer";

function getArticleIndexInList(articleSlug: string, list: ArticleModel[]) {
  const swipeListSlugs = list.map((article: ArticleModel) => article.slug);
  const slugIndex = swipeListSlugs.findIndex((slug: string) => slug === articleSlug);
  return slugIndex;
}

export async function refreshCachedArticle(articleSlug: string) {
  try {
    const cache = store.getState().articles.cache;

    const listIndex = getArticleIndexInList(articleSlug, cache.list);
    const mainSpeIndex = getArticleIndexInList(articleSlug, cache.mainSpecialty);
    const secSpeIndex = getArticleIndexInList(articleSlug, cache.secondarySpecialty);
    const swipeIndex = getArticleIndexInList(articleSlug, cache.swipeList);

    if ([listIndex, mainSpeIndex, secSpeIndex, swipeIndex].some(index => index !== -1)) {
      const data = await getArticleBySlug(articleSlug);

      if (listIndex !== -1) {
        const copyList = [...cache.list];
        copyList.splice(listIndex, 1, data)
        store.dispatch(memorizeArticlesList(copyList));
      }
      if (mainSpeIndex !== -1) {
        const copyList = [...cache.mainSpecialty];
        copyList.splice(mainSpeIndex, 1, data)
        store.dispatch(memorizeMainSpecialtyArticles(copyList));
      }
      if (secSpeIndex !== -1) {
        const copyList = [...cache.secondarySpecialty];
        copyList.splice(secSpeIndex, 1, data)
        store.dispatch(memorizeSecondarySpecialtyArticles(copyList));
      }
      if (swipeIndex !== -1) {
        const copyList = [...cache.swipeList];
        copyList.splice(swipeIndex, 1, data)
        store.dispatch(memorizeSwipeList(copyList));
      }
    }
  } catch (error) {
    console.error("Couldn't refresh cached article.", error);
  }
}