import { SupportedLanguagesEnum } from "../../../interfaces";
import { searchApi } from "../search.api";

interface QueryParams {
  search: string;
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
}

const api = searchApi.injectEndpoints({
  endpoints: (build) => ({
    searchPlaylists: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: "/playlist/search-v2",
          params: {
            ...params,
            status: "published",
            visibility: "public",
          },
        };
      },
      transformResponse: (response: { hits: { hits: [], total: number, facets: [] } }) => {
        const { hits, total, facets } = response.hits;

        const docs = hits.map(({ _source }: {
          _source: {
            id: string;
            core: { meta: { creationDate: string } };
            filters: { sponsored: boolean };
          };
        }) => {
          return {
            ..._source.core,
            id: _source.id,
            publicationDate: _source.core.meta.creationDate,
            sponsored: _source.filters.sponsored,
          };
        });

        return { docs, meta: { total, facets } };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchPlaylistsQuery,
  useLazySearchPlaylistsQuery,
} = api;
