import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import BottomNavbar from "../../components/app/BottomNavbar";
import LogoHeader from "../../components/app/headers/LogoHeader";
import { CustomButton } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import disableScroll from "disable-scroll";
import { resetPassword } from "../../domains/auth/endpoints/resetPassword";
import { t } from "i18next";
import Illustration from "../../assets/images/illustrations/brain-eyes.svg";

export default function ResetPage() {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    disableScroll.on();
  }, []);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    resetPassword({ email }).then(() => {
      history.push("/login/reset/confirm");
    });
  }

  return (
    <Wrapper>
      <LogoHeader />

      <Container>
        <form onSubmit={handleSubmit}>
          <LoginImage className="illustration" alt="" src={Illustration} />
          <LoginTitle>{t("login:resetPassword.title")}</LoginTitle>
          <LoginSubtitle>{t("login:resetPassword.text")}</LoginSubtitle>
          <CustomInput
            placeholder="Email address"
            type="email"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            required
          />
          <CustomButton
            style={{ width: "100%", marginTop: "20px" }}
            type="submit"
          >
            {t("Reset your password")}
          </CustomButton>
        </form>
      </Container>

      <BottomNavbar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  flex: 1;
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginTitle = styled.div`
  color: #212121;
  font-family: "Inter";
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 10px;
`;

const LoginSubtitle = styled.div`
  font-family: "Roboto";
  color: #212121;
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  text-align: center;
  width: 70%;
  margin: 0 auto 30px;
`;

const LoginImage = styled.img`
  display: block;
  margin: 20px auto 0;
`;
