import { History } from "history";
import { isUserAuthentified } from "./isUserAuthentified";
import { isUserOnboarded } from "./isUserOnboarded";
import { store } from "../../../redux";
import { getCurrentUser } from "../endpoints/getCurrentUser";
import { setUser } from "../../../redux/user/user.reducer";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";

export async function preflightUser({
  history,
  onboardingMessage,
  memorizePath,
  authOnly,
}: {
  history: History;
  onboardingMessage?: string;
  memorizePath?: boolean;
  authOnly?: boolean,
}) {
  localStorage.removeItem("authRedirectPath");
  const isAuthentified = await isUserAuthentified();

  let user = store.getState().user.user;
  if (!user && isAuthentified) {
    store.dispatch(setUser(await getCurrentUser()));
    user = store.getState().user.user;
  }
  const isOnboarded = isAuthentified ? isUserOnboarded(user) : false;

  if (!isAuthentified) {
    displayToast(t("error:notLoggedIn"));
    if (memorizePath) {
      localStorage.setItem("authRedirectPath", history.location.pathname + history.location.search);
    }
    history.replace("/login");
  } else if (!authOnly && !isOnboarded) {
    if (onboardingMessage) displayToast(onboardingMessage);
    history.replace("/signup/onboarding");
  }

  return {
    isAuthentified,
    isOnboarded,
    isRedirected: authOnly
      ? !isAuthentified
      : !isAuthentified || !isOnboarded,
  }
}
