import { SupportedLanguagesEnum } from "../../../interfaces";
import { playlistsApi } from "../playlists.api";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  medicalSpecialties?: string;
  tags?: string;
}

export const api = playlistsApi.injectEndpoints({
  endpoints: (build) => ({
    getKOLPlaylists: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: "/playlist/kol",
          params: {
            limit: params.limit,
            offset: params.offset,
            language: params.language,
            medical_specialties: params.medicalSpecialties,
            tags: params.tags,
            status: "published",
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getKOLPlaylists,
} = api.endpoints;

export const {
  useLazyGetKOLPlaylistsQuery
} = api;
