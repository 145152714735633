import { SupportedLanguagesEnum } from "../../../interfaces";
import { NectarModel } from "../nectar.types";
import { nectarApi } from "../nectar.api";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  tags?: string;
  medicalSpecialties?: string;
}

const defaultQueryParams: QueryParams = {
  limit: 20,
  offset: 0,
  language: SupportedLanguagesEnum.EN,
};

interface ResponsePayload {
  docs: NectarModel[];
  total: number;
}

const api = nectarApi.injectEndpoints({
  endpoints: (build) => ({
    getNectarsList: build.query<ResponsePayload, QueryParams>({
      query: (params = defaultQueryParams) => {
        return {
          url: "/nectar",
          params,
        };
      },
      transformResponse: (response: { docs: NectarModel[], meta: { total: number } }) => {
        return { docs: response.docs, total: response.meta.total };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNectarsListQuery,
  useLazyGetNectarsListQuery,
} = api;
