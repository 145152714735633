import { SupportedLanguagesEnum } from "../../../interfaces";
import { ArticleModel } from "../article.types";
import { articlesApi } from "../article.api";
import { sortArticleImages } from "../utils/sortArticleImages";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  medicalSpecialties?: string;
  journal?: string;
}

interface ResponsePayload {
  docs: ArticleModel[];
  total: number;
  meta: any; // TEMP: remove when reducer cleaned;
}

const api = articlesApi.injectEndpoints({
  endpoints: (build) => ({
    getArticlesList: build.query<ResponsePayload, QueryParams>({
      query: (params) => {
        const transformedParams: any = { ...params };
        if (params.medicalSpecialties) {
          transformedParams.medical_specialties = params.medicalSpecialties;
          delete transformedParams.medicalSpecialties;
        }

        return {
          url: "/article",
          params: {
            ...transformedParams,
            status: "published",
            visibility: "public",
          },
        };
      },
      transformResponse: (response: { docs: [], meta: { total: number } }) => {
        const docs = response.docs.map((article: ArticleModel) => {
          return sortArticleImages(article);
        });
        return { docs, total: response.meta.total, meta: response.meta };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetArticlesListQuery,
  useLazyGetArticlesListQuery,
} = api;
