import { Component } from "react";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import styled from "styled-components";
import { Spacer } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import disableScroll from "disable-scroll";
import { getPlaylistById } from "../../domains/playlist/endpoints/getPlaylistById";
import { renamePlaylist } from "../../domains/playlist/endpoints/renamePlaylist";
import { deletePlaylist } from "../../domains/playlist/endpoints/deletePlaylist";
import { sharePlaylist } from "../../domains/playlist/endpoints/sharePlaylist";
import { removeArticleFromPlaylist } from "../../domains/playlist/endpoints/removeArticleFromPlaylist";
import { removeContentFromPlaylist } from "../../domains/playlist/endpoints/removeContentFromPlaylist";
import { Row, Col } from "react-grid-system";
import CustomIcon from "../../components/CustomIcon";
import Loader from "../../components/Loader";
import { displayToast } from "../../components/app/AppToast";
import { connect } from "react-redux";
import { userApi } from "../../redux/user/user.service";
import i18n from "../../config/i18n";
import { BottomSheet } from "react-spring-bottom-sheet";
import PreviewCard from "../../components/profile/PreviewCard";
import ProfileHeader from "../../components/app/headers/ProfileHeader";
import ShareBtn from "../../assets/icons/share-btn.svg";
import SaveHeader from "../../components/app/headers/SaveHeader";
import { t } from "i18next";
import { iosPlaySound } from "../../tools/ios";
import { SoundEffect, SupportedLanguagesEnum } from "../../interfaces";
import ShareSheet from "../../components/ShareSheet";

class PlaylistPage extends Component {
  state = {
    playlist: undefined,
    showSheet: false,
    editMode: false,
  };

  async componentDidMount() {
    const playlistId = this.props.match.params.id;
    disableScroll.off();
    window.scrollTo(0, 0);

    const { isRedirected } = await preflightUser({ history: this.props.history, authOnly: true });
    if (isRedirected) return;

    // Get playlist informations
    getPlaylistById(playlistId)
      .then((playlist) => this.setState({ playlist }))
      .catch((error) => {
        displayToast(t("error:default"));
        console.error("Couldn't load playlist.", error);
        this.props.history.replace("/profile");
      });
  }

  componentDidUpdate() {
    const { userLoaded } = this.state;

    if (!!this.state.playlist && userLoaded) disableScroll.off();
  }

  async deleteFromPlaylist(content) {
    const playlistId = this.props.match.params.id;

    if (
      content.content_format === "Video" ||
      content.content_format === "Nectar"
    )
      await removeContentFromPlaylist(playlistId, content._id);
    else await removeArticleFromPlaylist(playlistId, content.slug);

    this.setState({
      playlist: {
        ...this.state.playlist,
        playlistContent: this.state.playlist.playlistContent.filter(
          (el) => el._id !== content._id
        ),
      },
    });

    this.props.getPlaylists();
    getPlaylistById(playlistId).then((playlist) => {
      this.setState({ playlist });
    });

    displayToast(
      t(
        content.content_format === "Video"
          ? "this video has been removed from your playlist"
          : content.content_format === "Nectar"
            ? "this nectar has been removed from your playlist"
            : "this article has been removed from your playlist"
      ),
      "success"
    );
  }

  async handleSharePlaylist() {
    const { title } = this.state.playlist;
    const _id = this.props.match.params.id;
    const my_uid = this.props.user?.user?.uid;

    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.sharePlaylist"),
    });
    if (isRedirected) return;

    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text:
            i18n.resolvedLanguage === SupportedLanguagesEnum.FR
              ? `Découvrez ma playlist ${title} sur Juisci !`
              : `Discover my playlist ${title} on Juisci now!`,
          url: `/profile/playlist/${_id}?shared=${my_uid ?? "null"}`,
        })
        .then(() => {
          displayToast(t("playlist shared"), "success");
          iosPlaySound(SoundEffect.SHARE);
          sharePlaylist(_id);
        })
        .catch((err) => {
          throw err;
        });
    } else {
      displayToast(t("toast.fail.browser"));
    }
  }

  async handleSubmit() {
    try {
      const playlist_id = this.props.match.params.id;
      const isMe = this.state.playlist?.user?._id === this.props?.user?.user?._id;

      if (isMe) {
        await renamePlaylist(playlist_id, this.state.playlist.title);
        await this.props.getPlaylists();
        displayToast(t("Playlist name updated"), "success");
        this.setState({ editMode: false });
      }
    } catch (error) {
      console.error("Couldn't rename playlist.", error);
      displayToast(t("error:default"));
    }
  }

  handleDeletePlaylist() {
    const playlist_id = this.props.match.params.id;
    const isMe = this.state.playlist?.user?._id === this.props?.user?.user?._id;

    if (isMe) {
      this.setState({ isDeleting: true });
      deletePlaylist(playlist_id).then(() =>
        this.props.getPlaylists().then(() => {
          this.props.history.replace("/profile?mainTab=1");
          displayToast(t("toast.success.playlist_delete"), "success");
        })
      );
    }
  }

  render() {
    const { showSheet, editMode, playlist } = this.state;

    if (!playlist) return <Loader />;

    const playlistContent = [
      ...this.state.playlist.playlist,
      ...this.state.playlist.playlistContent,
    ];

    const isMe = playlist?.user?._id === this.props?.user?.user?._id;

    return (
      <Container>
        {editMode ? (
          <SaveHeader
            title={t("Edit playlist")}
            saveReady={true}
            onSaveClick={this.handleSubmit.bind(this)}
          />
        ) : (
          <ProfileHeader user={isMe ? this.props.user.user : this.state.user} />
        )}
        <PageContent>
          {editMode ? (
            <CustomInput
              value={playlist.title}
              onInput={(e) =>
                this.setState({
                  playlist: {
                    ...playlist,
                    title: e.target.value,
                  },
                })
              }
              placeholder={t("Playlist name")}
            />
          ) : (
            <PageHeader>
              <div className='col'>
                <CustomIcon iconName='playlist' />
                <h3>{this.state.playlist.title}</h3>
              </div>
              <div onClick={() => this.setState({ showSheet: true })}>
                <img src={ShareBtn} alt='' />
              </div>
            </PageHeader>
          )}

          {!editMode && playlistContent.length === 0 ? (
            <p style={{ margin: "16px auto", display: "block" }}>
              {t("Save your favorite content in this playlist")}
            </p>
          ) : (
            playlistContent?.map((content) => {
              return (
                <PreviewCard
                  key={content._id + "--profile-card-key"}
                  disabled={!isMe}
                  content={{ ...content }}
                  onRemove={() => this.deleteFromPlaylist(content)}
                />
              );
            })
          )}
        </PageContent>

        <ShareSheet type='playlist' />

        <BottomSheet
          expandOnContentDrag
          open={showSheet}
          onDismiss={() =>
            setTimeout(() => this.setState({ showSheet: false }), 100)
          }
        >
          <SheetWrapper>
            {/* EDIT */}
            {isMe && (
              <Row
                style={{ margin: 0, opacity: this.state.isDeleting ? 0.5 : 1 }}
                gutterWidth={45}
                onClick={() =>
                  !this.state.isDeleting &&
                  this.setState({ editMode: true, showSheet: false })
                }
              >
                <Col xs={1}>
                  <CustomIcon iconName='edit_pen' color='#212121' />
                </Col>
                <Col xs={10}>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 600,
                      margin: 0,
                      marginTop: -5,
                    }}
                  >
                    {t("playlist.sheet.edit.title")}
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {t("playlist.sheet.edit.subline")}
                  </p>
                </Col>
              </Row>
            )}

            <Spacer />

            {/* SHARE */}
            <Row
              style={{ margin: 0, opacity: this.state.isDeleting ? 0.5 : 1 }}
              gutterWidth={45}
              onClick={() =>
                !this.state.isDeleting && this.handleSharePlaylist()
              }
            >
              <Col xs={1}>
                <CustomIcon iconName='share-alt' scale={0.8} color='#212121' />
              </Col>
              <Col xs={10}>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                    margin: 0,
                    marginTop: -5,
                  }}
                >
                  {t("Share this playlist")}
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: 400,
                    margin: 0,
                  }}
                >
                  {t("playlist.sheet.share.subline")}
                </p>
              </Col>
            </Row>

            <Spacer />

            {/* DELETE */}
            {isMe && (
              <Row
                style={{ margin: 0, opacity: this.state.isDeleting ? 0.5 : 1 }}
                gutterWidth={45}
                onClick={this.handleDeletePlaylist.bind(this)}
              >
                <Col xs={1}>
                  <CustomIcon iconName='trash' color='#212121' increase />
                </Col>
                <Col xs={10}>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 600,
                      margin: 0,
                      marginTop: -5,
                    }}
                    className={this.state.isDeleting ? "loading" : ""}
                  >
                    {t("playlist.sheet.delete.title")}
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {t("playlist.sheet.delete.subline")}
                  </p>
                </Col>
              </Row>
            )}
          </SheetWrapper>
        </BottomSheet>
      </Container>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}
const mapDispatch = {
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(PlaylistPage);

const Container = styled.div`
  min-height: 100dvh;
  background: #F7F8FC;
`;

const PageContent = styled.div`
  padding: 40px 20px 50px 20px;
`;

const PageHeader = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;

  .col {
    display: flex;
    align-items: start;
    gap: 16px;

    .--custom-icon {
      transform: translateY(5px) !important;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: -0.00017em;
      color: #212121;
      margin: 0;
    }
  }
`;

const SheetWrapper = styled.div`
  padding: 55px 5px;
  min-height: ${window.innerHeight / 3}px;
`;
