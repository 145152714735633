import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { BottomSheet } from "react-spring-bottom-sheet";
import CustomIcon from "../CustomIcon";
import styled from "styled-components";
import { t } from "i18next";

export default function OptionsPanel({
  isOpen,
  onDismiss,
  isContentSaved,
  isContentLiked,
  onSaveContent,
  onLikeContent,
  onShareContent,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  isContentSaved: boolean;
  isContentLiked: boolean;
  onSaveContent: Function;
  onLikeContent: Function;
  onShareContent: Function;
}) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  return (
    <BottomSheet
      expandOnContentDrag
      open={isOpen}
      onDismiss={() => onDismiss()}
    >
      <CloseButtonWrapper>
        <CustomIcon iconName="close_alt" onClick={() => onDismiss()} />
      </CloseButtonWrapper>

      <InnerContainer>
        <OptionRow
          className={isContentSaved ? "fill" : ""}
          onClick={async () => {
            const { isRedirected } = await preflightUser({
              history,
              onboardingMessage: t("error:notOnboarded.saveContent"),
            });
            if (isRedirected) return;
            onSaveContent();
          }}
        >
          <CustomIcon iconName={isContentSaved ? "save.fill" : "save"} />
          <div>
            <span className="label">
              {isContentSaved ? t("Modify") : t("Save")}
            </span>
            <span className="help">
              {isContentSaved
                ? t("Add or remove this article from your playlists")
                : t("overlays.dots.save.subline")}
            </span>
          </div>
        </OptionRow>

        <OptionRow
          className={isContentLiked ? "fill" : ""}
          onClick={() => {
            if (currentUser) {
              onLikeContent();
            } else {
              history.push("/login");
            }
          }}
        >
          <CustomIcon iconName={isContentLiked ? "heart" : "heart_outlined"} />
          <div>
            <span className="label">
              {isContentLiked ? t("Unlike") : t("Like")}
            </span>
            <span className="help">{t("overlays.dots.like.subline")}</span>
          </div>
        </OptionRow>

        <OptionRow onClick={() => onShareContent()}>
          <CustomIcon iconName="share-alt" />
          <div>
            <span className="label">{t("Share")}</span>
            <span className="help">{t("overlays.dots.share.subline")}</span>
          </div>
        </OptionRow>
      </InnerContainer>
    </BottomSheet>
  );
}

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const InnerContainer = styled.div`
  padding: 55px 25px;
  min-height: ${window.innerHeight / 3}px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: #212121;
  fill: #212121;
  font-family: "Inter";

  &.fill {
    color: #ff699c;
    svg path {
      fill: #ff699c;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .label {
    display: block;
    font-size: 17px;
    font-weight: 600;
  }

  .help {
    font-size: 15px;
    font-weight: 400;
  }
`;
