import { t } from "i18next";
import { displayToast } from "../components/app/AppToast";
import { store } from "../redux";
import { addArticleToPlaylist } from "../domains/playlist/endpoints/addArticleToPlaylist";
import { removeArticleFromPlaylist } from "../domains/playlist/endpoints/removeArticleFromPlaylist";
import { addContentToPlaylist } from "../domains/playlist/endpoints/addContentToPlaylist";
import { removeContentFromPlaylist } from "../domains/playlist/endpoints/removeContentFromPlaylist";
import { setPlaylists, setSaves, setUser } from "../redux/user/user.reducer";
import {
  convertContentToItemData,
  gtmItemsData,
} from "../tools/reactgaEvents";
import { iosGenerateHaptic, iosPlaySound } from "../tools/ios";
import {
  HapticEffect,
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
  SoundEffect,
} from "../interfaces";
import { PlaylistModel } from "../domains/playlist/playlist.types";
import { VideoModel, VideoFormat } from "../domains/video/video.types";
import { ArticleModel } from "../domains/article/article.types";
import { NectarModel } from "../domains/nectar/nectar.types";
import { UserModel } from "../domains/user/user.types";
import { ContentFormatsEnum, IContent } from "../interfaces/content.interface";
import { userApi } from "../redux/user/user.service";
import { likeArticle } from "../domains/user/endpoints/likeArticle";
import { unlikeArticle } from "../domains/user/endpoints/unlikeArticle";

// NOTE: This whole file will be removed from the project once the video interaction hooks are done.

export async function saveContentToPlaylists(
  savedContent: VideoModel | ArticleModel | NectarModel,
  selectedPlaylistsIds: string[],
  gtmItemListName: ItemDataEventListName | null = null
) {
  const isVideoContent = (savedContent as VideoModel).content_format === "Video";
  const isNectarContent = (savedContent as NectarModel).content_format === "Nectar";
  const isArticleContent = !isVideoContent && !isNectarContent;
  const userPlaylists = store.getState().user.playlists as PlaylistModel[];

  let contentId: string;
  if (isNectarContent) contentId = (savedContent as NectarModel)._id;
  else contentId = (savedContent as VideoModel | ArticleModel)._id;

  // Get playlists where the content was added
  const playlistsToAdd = [
    ...userPlaylists
      .filter((element) => {
        const { _id, playlist, playlistContent } = element;
        const idPool = isArticleContent
          ? playlist.map((pl) => pl._id)
          : playlistContent;
        return (
          !idPool?.find((id) => id === contentId) &&
          selectedPlaylistsIds?.includes(_id)
        );
      })
      .map((element) => element._id),
  ];

  // Get playlists the content was removed from
  const playlistsToRemove = [
    ...userPlaylists
      .filter(({ _id, playlist, playlistContent }) => {
        const idPool = isArticleContent
          ? playlist.map((pl) => pl._id)
          : playlistContent;
        return (
          idPool?.find((id) => id === contentId) &&
          !selectedPlaylistsIds?.includes(_id)
        );
      })
      .map(({ _id }) => _id),
  ];

  // Fetch GTM if content is added to any playlist
  if (isArticleContent && playlistsToAdd.length && !!gtmItemListName)
    gtmItemsData(
      ItemsDataEvent.ARTICLE_SAVE,
      convertContentToItemData(
        [savedContent],
        gtmItemListName,
        ItemDataVariant.ARTICLE
      )
    );
  else if (isVideoContent && playlistsToAdd.length && !!gtmItemListName)
    gtmItemsData(
      ItemsDataEvent.VIDEO_SAVE,
      convertContentToItemData(
        [savedContent],
        gtmItemListName,
        (savedContent as VideoModel).videoFormat === VideoFormat.STORY
          ? ItemDataVariant.VIDEO_STORY
          : ItemDataVariant.VIDEO_ARTICLE
      )
    );
  else if (isNectarContent && playlistsToAdd.length && !!gtmItemListName)
    gtmItemsData(
      ItemsDataEvent.NECTAR_SAVE,
      convertContentToItemData(
        [savedContent],
        gtmItemListName,
        ItemDataVariant.NECTAR
      )
    );

  // API requests
  if (isArticleContent) {
    for (const playlistId of playlistsToAdd) {
      addArticleToPlaylist(playlistId, savedContent.slug);
    }
    for (const playlistId of playlistsToRemove) {
      removeArticleFromPlaylist(playlistId, savedContent.slug);
    }
  } else {
    for (const playlistId of playlistsToAdd) {
      try {
        await addContentToPlaylist(playlistId, contentId);
      } catch (error) {
        displayToast(t("error:default"));
        return;
      }
    }
    for (const playlistId of playlistsToRemove) {
      try {
        await removeContentFromPlaylist(playlistId, contentId);
      } catch (error) {
        displayToast(t("error:default"));
        return;
      }
    }
  }

  // Make new playlists for the store
  const newPlaylists = [...userPlaylists];

  for (const playlistId of playlistsToAdd) {
    const playlistIndex = newPlaylists.findIndex(
      (playlist) => playlist._id === playlistId
    );

    if (isArticleContent) {
      newPlaylists[playlistIndex] = {
        ...newPlaylists[playlistIndex],
        playlist: [
          ...newPlaylists[playlistIndex].playlist,
          savedContent as ArticleModel,
        ],
      };
    } else {
      newPlaylists[playlistIndex] = {
        ...newPlaylists[playlistIndex],
        playlistContent: [
          ...newPlaylists[playlistIndex].playlistContent,
          contentId,
        ],
      };
    }
  }

  for (const playlistId of playlistsToRemove) {
    const playlistIndex = newPlaylists.findIndex(
      (playlist) => playlistId === playlist._id
    );

    if (isArticleContent) {
      newPlaylists[playlistIndex] = {
        ...newPlaylists[playlistIndex],
        playlist: [
          ...newPlaylists[playlistIndex].playlist.filter(
            (content) => content._id !== contentId
          ),
        ],
      };
    } else {
      newPlaylists[playlistIndex] = {
        ...newPlaylists[playlistIndex],
        playlistContent: [
          ...newPlaylists[playlistIndex].playlistContent.filter(
            (videoId) => videoId !== contentId
          ),
        ],
      };
    }
  }

  // Make new saves for the store
  const userSaves = store.getState().user.saves as string[];
  const newSaves =
    selectedPlaylistsIds.length > 0
      ? // If any playlist is checked, add the content ID to the saves (using Set avoid duplicates)
      Array.from(new Set([...userSaves, contentId]))
      : // If no playlist is checked, remove the content ID from the saves
      userSaves.filter((contentId) => contentId !== contentId);

  // Dispatch saves changes to the store
  store.dispatch(setSaves(newSaves));
  store.dispatch(setPlaylists(newPlaylists));

  // Set flags
  const playlistsWithContent = newPlaylists.filter((playlist) => {
    if (isArticleContent) {
      return playlist.playlist.some(
        (content) => content._id === contentId
      );
    } else {
      return playlist.playlistContent.includes(contentId);
    }
  });

  const contentIsInPlaylist = playlistsWithContent.length > 0;
  const contentWasAdded = playlistsToAdd.length > 0;
  const contentWasRemoved = playlistsToRemove.length > 0;

  // User notifications & side effects
  if (playlistsToAdd.length) {
    displayToast(
      t(
        isArticleContent
          ? "one more article was saved to your playlist"
          : isVideoContent
            ? "create video playlist success"
            : "create nectar playlist success"
      ),
      "success"
    );

    iosPlaySound(SoundEffect.SAVE);
  } else if (playlistsToRemove.length) {
    displayToast(
      t(
        isArticleContent
          ? "this article has been removed from your playlist"
          : isVideoContent
            ? "This video is no longer part of your playlist"
            : "This nectar is no longer part of your playlist"
      ),
      "success"
    );
  }

  return {
    contentIsInPlaylist,
    contentWasAdded,
    contentWasRemoved,
    playlistsToAdd,
    playlistsToRemove,
  };
}

export function likeContent(
  likedContent: IContent | ArticleModel,
  isContentLiked: boolean,
  gtmItemListName: ItemDataEventListName | null = null
) {
  const user = store.getState().user.user as UserModel;

  const isArticleContent = !(likedContent as IContent)?.content_format;

  if (isArticleContent) {
    // Make new likes for the store
    const newLikes = isContentLiked
      ? // If any playlist is checked, add the content to the likes
      [user.likes.filter((el) => el._id !== likedContent._id), likedContent]
      : // If no playlist is checked, remove the content from the likes
      user.likes.filter((el) => el._id !== likedContent._id);

    // Dispatch likes changes to the store
    store.dispatch(
      setUser({
        ...user,
        likes: newLikes as ArticleModel[],
      })
    );

    // User notifications & side effects
    iosGenerateHaptic(HapticEffect.SUCCESS);

    if (isContentLiked) {
      iosPlaySound(SoundEffect.LIKE);
      displayToast(t("toast.success.liked"), "success");
      likeArticle(likedContent._id);

      gtmItemsData(
        ItemsDataEvent.ARTICLE_LIKE,
        convertContentToItemData(
          [likedContent],
          gtmItemListName,
          ItemDataVariant.ARTICLE
        )
      );
    } else {
      displayToast(t("toast.success.unliked"), "success");
      unlikeArticle(likedContent._id);
    }
  } else {
    const newLikes = isContentLiked
      ? [
        user.likedContent.filter((el) => el._id !== likedContent._id),
        likedContent,
      ]
      : user.likedContent.filter((el) => el._id !== likedContent._id);

    store.dispatch(
      setUser({
        ...user,
        likedContent: newLikes as IContent[],
      })
    );

    iosGenerateHaptic(HapticEffect.SUCCESS);

    store.dispatch(
      userApi.endpoints.patchUserContent.initiate({
        slug: likedContent.slug,
        action: isContentLiked ? "like" : "unlike",
      })
    );

    if (isContentLiked) {
      iosPlaySound(SoundEffect.LIKE);
      displayToast(t("toast.success.liked_content"));

      if (
        (likedContent as unknown as VideoModel).content_format === ContentFormatsEnum.VIDEO
      )
        gtmItemsData(
          ItemsDataEvent.VIDEO_LIKE,
          convertContentToItemData(
            [likedContent],
            gtmItemListName,
            (likedContent as unknown as VideoModel)?.videoFormat === VideoFormat.STORY
              ? ItemDataVariant.VIDEO_STORY
              : ItemDataVariant.VIDEO_ARTICLE
          )
        );
      else if (
        (likedContent as IContent).content_format === ContentFormatsEnum.NECTAR
      )
        gtmItemsData(
          ItemsDataEvent.NECTAR_LIKE,
          convertContentToItemData(
            [likedContent],
            gtmItemListName,
            ItemDataVariant.NECTAR
          )
        );
    } else {
      displayToast(t("toast.success.unliked_content"), "success");
    }
  }

  return {
    isContentLiked,
    increment: isContentLiked ? 1 : -1,
  };
}
