import { CSSProperties, MouseEvent, useState } from "react";
import { ArticleModel } from "../../domains/article/article.types";
import { useLikeArticle } from "../../domains/user/hooks/useLikeArticle";
import { useSaveArticle } from "../../domains/user/hooks/useSaveArticle";
import { useShareArticle } from "../../domains/user/hooks/useShareArticle";
import { ItemDataEventListName } from "../../interfaces";
import styled from "styled-components";
import { t } from "i18next";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { StoryCoverBody, StoryCoverContainer, StoryCoverFooter, StoryCoverHeader, StoryCoverTitle, StoryCoverType } from "./layout";
import { CardActions, CardAuthor, CardDate, CardJournal, CardTags } from "../cards/layout";

export default function StoryCoverArticle({
  article,
  style,
  onClick,
  onOptionsOpen,
  onOptionsClose,
}: {
  article: ArticleModel;
  style?: CSSProperties;
  onClick?: Function;
  onOptionsOpen?: Function;
  onOptionsClose?: Function;
}) {
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const { isLiked, toggleLike } = useLikeArticle(article, ItemDataEventListName.FORYOU);
  const { isSaved, toggleSaveToPlaylists } = useSaveArticle(article);
  const shareArticle = useShareArticle(article);

  return (
    <>
      <Container
        className="story-cover-container"
        style={style}
        onClick={() => onClick && onClick()}
      >
        <StoryCoverHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "8px",
            }}
          >
            <CardDate date={article.publication_date} />
            <CardTags
              contentLabel="article"
              tags={article.medical_specialties}
            />
          </div>
          <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
              onOptionsOpen && onOptionsOpen();
            }}
          />
        </StoryCoverHeader>

        <StoryCoverBody>
          <StoryCoverType>{t(article.article_type ?? "Clinical Study")}</StoryCoverType>
          <StoryCoverTitle lines={8}>{article.title}</StoryCoverTitle>
        </StoryCoverBody>

        <StoryCoverFooter>
          <CardJournal
            journalName={article.journal?.name}
            journalLogo={article.journal?.image?.url}
          />
          <CardAuthor
            primaryAuthor={article.primary_author}
            authors={article.authors}
          />
        </StoryCoverFooter>
      </Container>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onDismiss={() => {
          setOptionsPanelVisible(false);
          onOptionsClose && onOptionsClose();
        }}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareArticle}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={article}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({
          add,
          remove,
        }: {
          add: string[];
          remove: string[];
        }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const Container = styled(StoryCoverContainer)`
  background: linear-gradient(180deg, #FFC408 0%, rgba(255, 255, 255, 0.00) 100%), #F7F6F2;
`;
