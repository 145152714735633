import { SupportedLanguagesEnum } from "../../../interfaces";
import { VideoFormat } from "../video.types";
import { videosApi } from "../videos.api";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  medicalSpecialties?: string;
  tags?: string;
}

const api = videosApi.injectEndpoints({
  endpoints: (build) => ({
    getVideoArticlesList: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: "/video",
          params: {
            limit: params.limit,
            offset: params.offset,
            language: params.language,
            medicalSpecialties: params.medicalSpecialties,
            tags: params.tags,
            videoFormat: VideoFormat.ARTICLE,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getVideoArticlesList,
} = api.endpoints;

export const {
  useGetVideoArticlesListQuery,
  useLazyGetVideoArticlesListQuery,
} = api;
