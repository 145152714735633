import { Component } from "react";
import styled from "styled-components";
import { t } from "i18next";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton, Spacer } from "../../components/global";
import { setPassword } from "../../domains/auth/endpoints/setPassword";
import { displayToast } from "../../components/app/AppToast";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack } from "../../components/app/headers/layout";
import { auth, fbLogout, loginEmailPassword } from "../../services/firebase";
import { iosAuthSignOut } from "../../tools/ios";
import { androidSignOut } from "../../tools/android";

class NewPasswordPage extends Component {
  state = {
    password: "",
    confirmPassword: "",
    showPassword: false,
    btnLoading: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // redirect if not logged in
    auth.onAuthStateChanged((user) => {
      if (!user) this.props.history.push("/discovery");
    });
  }

  handleClick = async () => {
    this.setState({ btnLoading: true });
    if (this.state.password !== this.state.confirmPassword)
      displayToast(t("common:form.error.passwords"));
    else if (this.state.password.length < 6)
      displayToast(t("common:form.error.passwordLength"));
    else {
      await setPassword({
        password: this.state.password,
      });

      await loginEmailPassword(
        localStorage.getItem("emailForSignIn"),
        this.state.password
      );
      localStorage.removeItem("emailForSignIn");
    }
    this.setState({ btnLoading: false });
  };

  onLeavePage() {
    // We deconnect the user from the app if he leaves the page
    fbLogout();
    androidSignOut();
    iosAuthSignOut();
  }

  render() {
    return (
      <div>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack onClick={() => this.onLeavePage()} /></HeaderLinks>
          <HeaderLogo />
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <PageWrapper>
          <h3>{t("Create your password")}</h3>
          <p>{t("common:fillInInformation")}</p>
          <Spacer height='16px' />

          <CustomInput
            onInput={(e) => this.setState({ password: e.target.value })}
            type={this.state.showPassword ? "text" : "password"}
            placeholder={t("common:form.field.password")}
            name='password'
            // style={{ marginBottom: "20px" }}
            required
            filled={this.state.password}
            suffixClick={() =>
              this.setState({ showPassword: !this.state.showPassword })
            }
            suffix={
              <p className='show-hide-button'>
                {this.state.showPassword ? t("signup.hide") : t("signup.show")}
              </p>
            }
          />
          <Spacer height='16px' />
          <CustomInput
            onInput={(e) => this.setState({ confirmPassword: e.target.value })}
            type={this.state.showPassword ? "text" : "password"}
            placeholder={t("common:form.field.confirmPassword")}
            name='confirmPassword'
            // style={{ marginBottom: "20px" }}
            required
            filled={this.state.confirmPassword}
            suffixClick={() =>
              this.setState({ showPassword: !this.state.showPassword })
            }
            suffix={
              <p className='show-hide-button'>
                {this.state.showPassword ? t("signup.hide") : t("signup.show")}
              </p>
            }
          />
          <Spacer height='16px' />
          <CustomButton
            onClick={() => this.handleClick()}
            disabled={
              !this.state.confirmPassword.length || !this.state.password.length
            }
            className={
              this.state.btnLoading
                ? "loading disabled"
                : !this.state.confirmPassword.length ||
                  !this.state.password.length
                  ? "disabled"
                  : ""
            }
          >
            {t("common:action:confirm")}
          </CustomButton>
        </PageWrapper>
      </div>
    );
  }
}

export default NewPasswordPage;

const PageWrapper = styled.div`
  background: #ecf0f5;
  height: 100vh;
  box-sizing: border-box;
  padding: 21px 30px;
  text-align: center;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.025em;
    color: #212121;
    margin: 0;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #212121;
    margin: 8px 0 24px;

    &.show-hide-button {
      font-family: Roboto !important;
      color: #212121;
      font-size: 12px;
      font-weight: 700;
      text-transform: capitalize;
      margin: 0 4px 0 0;
    }
  }
`;
