import { Component } from "react";
import { NoticeBar } from "antd-mobile";
import {
  CustomButton,
  Spacer,
} from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import disableScroll from "disable-scroll";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTagline, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import { createPlaylist } from "../../domains/playlist/endpoints/createPlaylist";
import { displayToast } from "../../components/app/AppToast";
import { connect } from "react-redux";
import { userApi } from "../../redux/user/user.service";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { t } from "i18next";
import styled from "styled-components";

// TODO: Rework entirely using toast notifications, etc.

class CreatePlaylist extends Component {
  state = {
    loading: false,
    isLogged: false,
    playlist_name: "",
    error_message: "",
  };

  componentDidUpdate() {
    const { userLoaded, articlesLoaded } = this.state;

    if (articlesLoaded && userLoaded) {
      disableScroll.off();
    }
  }

  async componentDidMount() {
    const { articlesLoaded } = this.state;
    window.scrollTo(0, 0);

    disableScroll.on();
    if (articlesLoaded && userLoaded) {
      disableScroll.off();
    }

    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.default"),
    });
    if (isRedirected) return;
  }

  async handleCreate() {
    const { playlist_name } = this.state;

    this.setState({
      loading: true,
    });

    if (playlist_name.length > 0) {
      const data = { title: playlist_name };

      const createReq = await createPlaylist(data);

      if (!!createReq.error) {
        this.setState({
          loading: false,
        });
        return displayToast(t("This playlist title already exists"), "error");
      }

      await this.props.getPlaylists();
      displayToast(t("toast.success.playlist_create"), "success");
      this.props.getUser();
      this.props.history.goBack();
    } else {
      this.setState({
        error_message: t("toast.fail.playlist_name"),
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { playlist_name, error_message } = this.state;

    return (
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>
            <HeaderTagline>{t("playlist.create.title")}</HeaderTagline>
            {playlist_name.length > 0
              ? playlist_name
              : t("playlist.create.placeholder")}
          </HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <Container>
          {/* Error Message on Empty submit */}
          {error_message.length > 0 && (
            <>
              <NoticeBar
                style={{ background: "#CE0868", color: "white" }}
                mode='closable'
                icon={null}
                onClick={() => this.setState({ error_message: "" })}
              >
                {error_message}
              </NoticeBar>
            </>
          )}

          {/* Form area */}
          {/* <h3>{t("playlist.create.name")}</h3> */}
          <Spacer />
          <CustomInput
            placeholder={t("playlist.create.title")}
            value={playlist_name}
            onInput={(e) => this.setState({ playlist_name: e.target.value })}
          />
          <hr style={{ margin: "30px 0" }} />
          <CustomButton
            className={this.state.loading ? "loading disabled" : ""}
            onClick={() => this.handleCreate()}
          >
            {t("common:action.create")}
          </CustomButton>
        </Container>
      </Wrapper>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}
const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(CreatePlaylist);

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #ECF0F5;
`;

const Container = styled.div`
  padding: 30px;
`;
