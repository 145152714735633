import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import styled from "styled-components";

export default function GPTChatSuggestions({ onSuggestionClick }: {
	onSuggestionClick: Function;
}) {
	const { appLang, t } = useAppLang();

	function handleClick(index: number) {
		const question = t(`juisciGpt:suggestions.q${index}.title`, { lng: appLang })
			+ " "
			+ t(`juisciGpt:suggestions.q${index}.subtitle`, { lng: appLang });

		onSuggestionClick(question);
	}

	return (
		<Container>
			<div className="heading">
				{t("juisciGpt:suggestions.title", { lng: appLang })}
			</div>
			<CardList>
				{[...Array(4)].map((_, index: number) => {
					var idx = index + 1;
					return (
						<SuggestionCard key={idx} onClick={() => handleClick(idx)}>
							<span className="title">
								{t(`juisciGpt:suggestions.q${idx}.title`, { lng: appLang })}
							</span>
							<span className="subtitle">
								{t(`juisciGpt:suggestions.q${idx}.subtitle`, { lng: appLang })}
							</span>
						</SuggestionCard>
					);
				})}
			</CardList>
		</Container>
	);
}

const Container = styled.div`
	padding: 0 22px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.heading {
		color: var(--BRAND-CORE-Juisci-Black, #212121);
		font-family: Roboto;
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 15px; /* 125% */
	}
`;

const CardList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const SuggestionCard = styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 2px;
	border-radius: 12px;

	&:nth-of-type(1) {
		background-color: hsla(63, 94%, 55%, 1);
	}
	&:nth-of-type(2) {
		background-color: hsla(63, 94%, 55%, 0.7);
	}
	&:nth-of-type(3) {
		background-color: hsla(63, 94%, 55%, 0.5);
	}
	&:nth-of-type(4) {
		background-color: hsla(63, 94%, 55%, 0.3);
	}
	
	.title {
		color: var(--BRAND-CORE-Juisci-Black, #212121);
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%; /* 18.2px */
		letter-spacing: 0.26px;
	}

	.subtitle {
		color: var(--BRAND-CORE-Juisci-Black, #212121);
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 110%; /* 14.3px */
		letter-spacing: 0.26px;
	}
`;
