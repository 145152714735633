import { useEffect, useRef, useState } from "react";
import { store, useAppDispatch, useAppSelector } from "../../../redux";
import { useAppLang } from "../../app/hooks/useAppLang";
import { useLazyGetVideoStoriesListQuery } from "../../video/endpoints/getVideoStoriesList";
import { useLazySearchVideosQuery } from "../endpoints/searchVideos";
import { memorizeSearchVideos, updateFacets } from "../search.reducer";
import { getDateParamsFromSearchFilters } from "../utils/getDateParamsFromSearchFilters";
import { displayToast } from "../../../components/app/AppToast";

export function useSearchVideos(searchValue: string) {
  // Store
  const dispatch = useAppDispatch();
  const searchFilters = useAppSelector((state) => state.search.searchFilters);
  const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
  const { appLang, t } = useAppLang();
  // State
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoadingResults, setLoadingResults] = useState(false);
  const [fallback, setFallback] = useState([]);
  // Query
  const [getVideoStoriesList, getVideoStoriesListResult] = useLazyGetVideoStoriesListQuery();
  const [searchVideos] = useLazySearchVideosQuery();
  // Custom
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [!!mountRef.current, () => mountRef.current = true];

  useEffect(() => {
    // NOTE: Usual selector not reliable enough, using store state instead.
    const cache = store.getState().search.cache;
    if (!isMounted) {
      if (cache.videos.results.length) {
        if (searchValue.length) {
          setResults(cache.videos.results);
          setTotal(cache.videos.total);
        } else {
          setFallback(cache.videos.results);
        }
      } else if (searchValue.length) {
        searchForResults(true);
      } else {
        loadFallback();
        searchForFacets();
      }
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length || filtersApplied) {
        searchForResults(true);
      } else {
        setResults([]);
        setTotal(0);
        if (!fallback.length) loadFallback();
      }
    }
  }, [searchValue, searchFilters]);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length) {
        searchForResults(true)
      } else {
        setFallback([]);
        setTimeout(() => {
          loadFallback();
        }, 0);
      }
    }
  }, [appLang]);

  function getParamsWithFilters(params: any) {
    params.scoring = searchFilters.scoring;

    const { afterDate, beforeDate } = getDateParamsFromSearchFilters(searchFilters);
    if (afterDate) params.afterDate = afterDate;
    if (beforeDate) params.beforeDate = beforeDate;

    return params;
  }

  function loadFallback() {
    getVideoStoriesList({
      limit: 20,
      offset: 0,
      language: appLang,
    })
      .unwrap()
      .then((data) => {
        const results = data.docs as [];
        setFallback(results);
        dispatch(memorizeSearchVideos({ results, total: 20 }));
      })
      .catch((error) => {
        console.error("Couldn't load fallback videos.", error);
        displayToast(t("error:default"));
        setLoadingResults(false);
      });
  }

  function searchForResults(resetResults: boolean) {
    if (resetResults) setLoadingResults(true);

    const params: any = getParamsWithFilters({
      search: searchValue,
      limit: 20,
      offset: resetResults ? 0 : results.length,
      language: appLang,
    });

    searchVideos(params)
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        const newResults = resetResults ? docs : [...results, ...docs];
        setResults(newResults);
        setTotal(total);
        setLoadingResults(false);
        dispatch(memorizeSearchVideos({ results: newResults, total }));
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't load video results.", error);
        displayToast(t("error:default"));
        setLoadingResults(false);
      });
  }

  function searchForFacets() {
    searchVideos(getParamsWithFilters({
      search: "",
      limit: 0,
      offset: 0,
      language: appLang,
    }))
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        setTotal(total);
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't search videos to update facets.", error);
      });
  }

  function searchMore() {
    searchForResults(false);
  }

  return {
    results,
    fallback,
    total,
    isLoadingResults,
    isLoadingFallback: getVideoStoriesListResult.isFetching,
    searchMore,
  };
}