import ReactGA from "react-ga";
import i18n from "../config/i18n";
import { OrganisationUid } from "../interfaces";
import { getFirebaseToken } from "../services/firebase";
import { getCurrentUser } from "../domains/user/endpoints/getCurrentUser";
import { getItemTranslation } from "../domains/app/utils/getItemTranslation";

export const gtmPageView = async () => {
  const firebaseId = await getFirebaseToken();
  let user = null;

  if (firebaseId) user = await getCurrentUser();

  window.dataLayer.push({
    event: "page_view",
    environment: {
      name: window.location.hostname.includes("app.juisci") ? "prod" : "dev",
      entity: "web app",
      partner: OrganisationUid.JUISCI,
    },
    page: {
      title: window.location.pathname.replace("/", ""),
      language: i18n.resolvedLanguage || "en",
    },
    user: {
      account_id: user?.uid || null,
      profile: user?.profession?.uid || null,
      specialty: user?.main_specialty?.uid || null,
      interests:
        user?.medical_specialties
          ?.map((specialty) => specialty.uid)
          ?.toString() || null,
      login_status: !!firebaseId ? "logged in" : "logged out",
    },
  });
};

export const gtmItemData = ({
  event,
  item_name,
  item_list_name,
  item_id,
  item_brand,
  item_category,
  item_category2,
  item_variant,
}) => {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event,
    ecommerce: {
      items: [
        {
          item_name,
          item_list_name,
          item_id,
          item_brand,
          item_category,
          item_category2,
          item_variant,
          quantity: 1,
        },
      ],
    },
  });
};

export const gtmItemsData = (event, items) => {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event,
    ecommerce: {
      items,
    },
  });
};

export const gtmFilter = (filter) => {
  dataLayer.push({
    event: "filter",
    filter,
  });
};

export const gtmAudioProgress = (
  event,
  percentages,
  article_title,
  page,
  article_id,
  article_journal,
  article_specialty
) => {
  const trigger = JSON.parse(localStorage.getItem("gtm_audio_percent_targets"));
  if (trigger?.includes(percentages)) {
    trigger.splice(trigger.indexOf(percentages), 1);
    localStorage.setItem("gtm_audio_percent_targets", JSON.stringify(trigger));
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event,
      percentages,
      ecommerce: {
        items: [
          {
            item_name: article_title,
            item_list_name: page,
            item_id: article_id,
            item_brand: article_journal,
            item_category: article_specialty,
            item_category2: "standard",
            item_variant: "article",
            quantity: 1,
          },
        ],
      },
    });
  }
};

export const gtmBasicEvent = (event) => {
  !window.location.hostname.includes("app.juisci") &&
    console.log("basic_event:", event);

  window.dataLayer.push({
    event,
  });
};

export const gtmAuthEvent = (event, userId) => {
  window.dataLayer.push({
    event,
    user: {
      account_id: userId,
    },
  });
};

export const gtmAdvancedEvent = (event, data) => {
  window.dataLayer.push({
    event,
    ...data,
  });
};

export const gtmLeadProfile = (event, lead_profile) => {
  // console.log("basic_event:", event);
  window.dataLayer.push({
    event,
    lead_profile,
  });
};

export const gtmSearchArticle = (term, res) => {
  dataLayer.push({ ecommerce: null });

  let items = [];
  res?.forEach((article) => {
    items.push({
      item_name: article?.title,
      item_list_name: window.location.pathname,
      item_id: article?._id,
      item_brand: article?.journal?.name,
      item_category: getItemTranslation(article?.medical_specialties[0]) ?? null,
      quantity: 1,
    });
  });

  dataLayer.push({
    event: "search",
    search: {
      term,
      results: {
        count: res?.length,
      },
    },
    ecommerce: {
      items,
    },
  });
};

export const gtmSearchView = ({ term, results_count, type }) => {
  window.dataLayer.push({
    event: "search",
    environment: {
      name: !window.location.hostname.includes("app.juisci") ? "dev" : "prod",
      entity: "web app",
    },
    search: {
      term,
      results_count,
      type,
    },
  });
};

export const convertContentToItemData = (
  items,
  listName,
  variant,
  isSponsored = false
) =>
  items.map(
    ({
      title,
      name,
      _id,
      journal,
      medical_specialties,
      organisations,
      company,
    }) => ({
      item_name: title ?? name,
      item_list_name: listName,
      item_id: _id,
      item_brand:
        journal?.name ?? organisations?.[0]?.name ?? company?.name ?? name,
      item_category: medical_specialties?.[0]?.uid || null,
      item_category2: isSponsored ? "sponsored" : "standard",
      item_variant: variant,
      quantity: 1,
    })
  );

// 'view_sign_up' -> When a user load the sign-up form page
// 'start_sign_up' -> When a user start interacting with the sign-up form (email field or tap on social logins apple or google)
// 'sign_up' -> When a user successfully submit the sign-up form
