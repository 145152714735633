import { useEffect } from "react";
import { Link } from "react-router-dom";
import { SupportedLanguagesEnum } from "../../interfaces";
import LogoHeader from "../../components/app/headers/LogoHeader";
import BottomNavbar from "../../components/app/BottomNavbar";
import { Row } from "react-grid-system";
import disableScroll from "disable-scroll";
import styled from "styled-components";
import { t } from "i18next";
import i18n from "../../config/i18n";
import Illustration from "../../assets/images/illustrations/ghost-hugs-brain.svg";

export default function ConfirmResetPage() {
  useEffect(() => {
    disableScroll.on();
  }, []);

  return (
    <Wrapper>
      <LogoHeader />

      <Container>
        <LoginImage alt="" src={Illustration} />
        <Row justify="center" align="center">
          <LoginTitle>{t("login:confirmResetPassword.title")}</LoginTitle>
          {i18n.resolvedLanguage === SupportedLanguagesEnum.EN ? (
            <LoginSubtitle>
              Check your mailbox now, you are going to receive a confirmation
              email to reset your password through a link.
              <br />
              <br />
              Please, follow the indicated instructions and then{" "}
              <Link
                style={{ color: "#FFAA00", textDecoration: "underline" }}
                to="/login"
              >
                login
              </Link>{" "}
              with your new password.
            </LoginSubtitle>
          ) : (
            <LoginSubtitle>
              Vérifiez votre boîte courriel, vous allez recevoir un email de
              confirmation pour réinitialiser votre mot de passe via un lien.
              <br />
              <br />
              Suivez les instructions indiquées, puis{" "}
              <Link
                style={{ color: "#FFAA00", textDecoration: "underline" }}
                to="/login"
              >
                connectez-vous
              </Link>{" "}
              avec votre nouveau mot de passe.
            </LoginSubtitle>
          )}
        </Row>
        <BubbleText>
          <p>
            <b>{t("reminder")}</b>
            <br />
            {i18n.resolvedLanguage !== SupportedLanguagesEnum.FR ? (
              <span>
                By the way, we share insightful scientific research about
                memory, feel free to <u>discover those publications</u>.
              </span>
            ) : (
              <span>
                D'ailleurs, nous partageons des recherches scientifiques
                approfondies sur la mémoire, n'hésitez pas à{" "}
                <u>découvrir ces publications</u>.
              </span>
            )}
            <br />
            😉
          </p>
          <BubbleImage className="illustration" />
        </BubbleText>
      </Container>

      <BottomNavbar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  flex: 1;
  padding: 30px;
`;

const LoginTitle = styled.div`
  color: #212121;
  font-family: "Inter";
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 10px;
`;

const LoginSubtitle = styled.div`
  font-family: "Roboto";
  color: #212121;
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
  width: 90%;
`;

const LoginImage = styled.img`
  display: block;
  margin: 20px auto 0;
`;

const BubbleText = styled.div`
  position: relative;
  width: 206px;
  height: 185px;
  float: right;
  margin-top: 30px;

  svg {
    transform: scale(1.2) translateX(25px);
  }

  p {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: 118%;
    text-align: center;
    color: #fff;

    position: absolute;
    top: 20px;
    left: 20px;
    right: 0px;
    z-index: 90;

    b {
      text-transform: uppercase;
      font-weight: 800;
    }

    u {
      color: #fff;
    }
  }
`;

const BubbleImage = () => (
  <svg
    width="171"
    height="154"
    viewBox="0 0 171 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5153_33045)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M147.913 122.729C159.836 109.761 167 93.132 167 75C167 33.5786 129.616 0 83.5 0C37.3842 0 0 33.5786 0 75C0 116.421 37.3842 150 83.5 150C104.246 150 123.225 143.204 137.828 131.956L156.799 143.102L147.913 122.729Z"
        fill="#2AA85E"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5153_33045"
        x="0"
        y="0"
        width="171"
        height="154"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5153_33045"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5153_33045"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
