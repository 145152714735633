import { auth } from "../../../services/firebase";
import { getApiRequestService } from "../../../services/index.services";

// TODO: move and rename
export async function getCurrentUser() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/me`);
    return res.data;
  } catch (error: any) {
    auth.signOut();
    throw error;
  }
}
