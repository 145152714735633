import { SupportedLanguagesEnum } from "../../../interfaces";
import { NectarModel } from "../nectar.types";
import { nectarApi } from "../nectar.api";

interface QueryParams {
  language: SupportedLanguagesEnum;
}

const defaultQueryParams: QueryParams = {
  language: SupportedLanguagesEnum.EN,
};

const api = nectarApi.injectEndpoints({
  endpoints: (build) => ({
    getNectarHighlights: build.query<NectarModel[], QueryParams>({
      query: (params = defaultQueryParams) => {
        return {
          url: "/nectar/highlight",
          params,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNectarHighlightsQuery,
  useLazyGetNectarHighlightsQuery,
} = api;
