import { t } from "i18next";
import { Component } from "react";
import { isAndroid } from "react-device-detect";
import { displayToast } from "../../components/app/AppToast";
import Loader from "../../components/Loader";
import { VideoFormat } from "../../domains/video/video.types";
import { getNotification } from "../../domains/notification/endpoints/getNotification";
import { androidOpen } from "../../tools/android";
import { iosOpenNewTab, isNativeIOS } from "../../tools/ios";

class NotificationRouter extends Component {
  componentDidMount() {
    this.handleReadNotification();
  }

  async handleReadNotification() {
    const notification_id = this.props.match.params.id;

    getNotification(notification_id)
      .then((notification) => {
        this.handleNotifications(notification);
      })
      .catch(({ response }) => {
        console.log("error", response);
        switch (response.status) {
          case 404:
            displayToast(t("notificationRouter.error.notFound"));
            this.props.history.replace("/discovery");
            break;

          case 401:
            localStorage.setItem("_cache_notification", notification_id);
            displayToast(t("notification.link.noauth"));
            this.props.history.replace("/login");
            break;

          default:
            console.log("not 401 or 403", error.status);
            this.props.history.replace("/profile/notifications");
            break;
        }
      });
  }

  handleNotifications(notification) {
    const notif_type = notification?.action?.notificationType;
    const emitter = notification.action.emitters[0] ?? "";
    const conversation = notification?.action?.conversation;

    switch (notif_type) {
      case "FollowRequestReceived":
        this.props.history.replace(`/profile/user/${emitter?.uid}`);
        break;

      case "FollowRequestSent":
        this.props.history.replace(`/profile/user/${emitter?.uid}`);
        break;

      case "FollowRequestAccepted":
        this.props.history.replace(`/profile/user/${emitter?.uid}`);
        break;

      case "FollowUnfollow":
        this.props.history.replace(`/profile/user/${emitter?.uid}`);
        break;

      case "ExternalLink":
        this.props.history.replace("/profile/notifications");

        if (
          notification?.action?.url.includes("app.juisci.com") ||
          notification?.action?.url.includes("dev.juisci.com") ||
          notification?.action?.url.includes("pro.juisci.com") ||
          notification?.action?.url.includes("pro-dev.juisci.com")
        ) {
          this.props.history.push(notification?.action?.url.split(".com")[1]);
        } else if (isNativeIOS) iosOpenNewTab(notification?.action?.url);
        else if (isAndroid && window.services)
          androidOpen(notification?.action?.url);
        else window.open(notification?.action?.url, "_blank");
        break;

      case "FollowActivity":
        this.props.history.replace("/discovery");
        break;

      case "DirectMessage":
        this.props.history.replace(
          `/profile/messages/conversation/${conversation?._id}`
        );
        break;

      case "ContentNew":
        this.props.history.replace("/discovery");
        break;

      case "ContentRecommended":
        this.props.history.replace("/discovery");
        break;

      case "ContentShare":
        this.props.history.replace("/discovery");
        break;

      case "ArticlePublished":
        this.props.history.replace(
          `/post/${notification.action.article.slug}?notificationCenter=true`
        );
        break;

      case "VideoPublished":
        const content = notification?.action?.content;

        this.props.history.replace(
          !content
            ? "/discovery/videos"
            : content.videoFormat === VideoFormat.STORY
            ? `/video/story/${content.slug}`
            : `/video/${content.slug}`
        );

        break;

      case "LemonCertifiedAnswered":
        this.props.history.replace("/settings");
        break;

      default:
        this.props.history.replace("/profile/notifications");
        break;
    }
  }

  render() {
    return (
      <>
        <Loader />
      </>
    );
  }
}

export default NotificationRouter;
