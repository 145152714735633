import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { ArticleModel } from "../../../domains/article/article.types";
import { preflightUser } from "../../../domains/user/utils/preflightUser";
import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import { useRoomPreflightTemp } from "../../../domains/room/hooks/useRoomPreflightTemp";
import { formatTime, renderPublicationDate } from "../../../tools/utils";
import disableScroll from "disable-scroll";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  NavBack,
} from "../../../components/app/headers/layout";
import { HapticEffect } from "../../../interfaces";
import { iosGenerateHaptic, isNativeIOS } from "../../../tools/ios";
import { RoomPageWrapper } from "../../../components/room/layout";
import { ReactComponent as InformationIcon } from "../../../assets/icons/information-icon.svg";
import { ReactComponent as SkipBackIcon } from "../../../assets/icons/skip-backward.svg";
import { ReactComponent as SkipForwardIcon } from "../../../assets/icons/skip-forward.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/pause_audio.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play_audio.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { ReactComponent as LearnMoreIcon } from "../../../assets/icons/audioroom-learn-more.svg";
import { synthesizeArticleSpeech } from "../../../domains/article/endpoints/synthesizeArticleSpeech";
import { getArticleBySlug } from "../../../domains/article/endpoints/getArticleBySlug";
import { Skeleton, Slider } from "@mui/material";
import { getPodcastBackground } from "../../../domains/room/utils/getPodcastBackground";
import { t } from "i18next";
import {
  iosAudioGetCurrentTime,
  iosAudioGetDuration,
  iosAudioPause,
  iosAudioPlayFromUrl,
  iosAudioPlayResume,
  iosAudioSeek,
  iosAudioSetPlaybackRate,
  iosAudioStop,
} from "../../../tools/ios/audio";
// NOTE: Temporary until translation is added to locale files
import i18n from "../../../config/i18n";
import { SupportedLanguagesEnum } from "../../../interfaces";

export default function AudioRoomPlayer() {
  const history = useHistory();
  const location = useLocation<{ podcast: ArticleModel }>();
  const playerRef = useRef<HTMLAudioElement | null>(null);

  const params = useParams<{
    roomId: string;
    slug: string;
    time?: string;
  }>();
  const { appLang } = useAppLang();
  const { roomData } = useRoomPreflightTemp(params.roomId);
  const [isLoading, setLoading] = useState(true);
  const [podcast, setPodcast] = useState<ArticleModel | null>(null);
  const [podcastCover, setpodcastCover] = useState<string | null>(null);
  const [player, setPlayer] = useState<HTMLAudioElement | null>(null);
  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const [hasPlayedOnce, setPlayedOnce] = useState(false); // NOTE: Used for native devices.
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [isInfoPanelVisible, setInfoPanelVisible] = useState(false);
  const [spinningButton, setSpinningButton] = useState<
    "backward" | "forward" | null
  >(null);
  const timePlayed = formatTime(currentTime);
  const timeRemaining = formatTime(totalDuration - currentTime);

  useEffect(() => {
    const loadPage = async () => {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();
        setLoading(true);
        let loadedPodcast =
          location.state?.podcast ?? (await getArticleBySlug(params.slug));

        if (loadedPodcast && !loadedPodcast.speech) {
          const { speech } = await synthesizeArticleSpeech(
            loadedPodcast.slug,
            appLang
          );
          loadedPodcast = { ...loadedPodcast, speech };
        }

        setPodcast(loadedPodcast as ArticleModel);
        if (loadedPodcast && loadedPodcast._id) {
          setpodcastCover(getPodcastBackground(loadedPodcast._id));
        }

        if (isNativeIOS) {
          setPlayer(null);
          const success = await iosAudioPlayFromUrl(
            loadedPodcast?.speech?.url as string
          );
          await iosAudioPause();
          setAudioPlaying(success);
          setPlayedOnce(success);
        } else {
          const audio = new Audio(loadedPodcast?.speech?.url as string);
          audio.addEventListener("loadedmetadata", () => {
            setTotalDuration(audio.duration);
            setLoading(false);
          });
          audio.load();
          playerRef.current = audio;
          setPlayer(audio);
        }
      } catch (error) {
        console.error("Couldn't fetch article.", error);
      }
    };

    const stopAudio = async () => {
      if (isNativeIOS) await iosAudioStop();
      else if (playerRef.current) playerRef.current.pause();
    };

    loadPage();

    return () => {
      stopAudio();
    };
  }, [params.slug]);

  // Load previously listened track time
  useEffect(() => {
    const getTotalDuration = async (): Promise<any> => {
      if (!podcast) return;

      if (isNativeIOS) {
        const duration = await iosAudioGetDuration();
        if (duration > 0) {
          setTotalDuration(duration);
          await iosAudioPlayResume();
          setAudioPlaying(true);
        } else return setTimeout(getTotalDuration, 1000);
      } else if (player && !isNaN(player.duration)) {
        setTotalDuration(player.duration);
      } else {
        return setTimeout(getTotalDuration, 1000);
      }

      setLoading(false);
    };

    if (podcast && player && params.time) {
      const seconds = parseFloat(params.time);
      if (!isNaN(seconds)) {
        seek(seconds);
      }
    }
    getTotalDuration();
  }, [podcast, player, hasPlayedOnce]);

  useEffect(() => {
    // Update audio current time while playing every 500ms
    const interval = setInterval(async () => {
      if (isNativeIOS) {
        const time = await iosAudioGetCurrentTime();
        setCurrentTime(time);
      } else if (player) {
        setCurrentTime(player?.currentTime);
      }

      // Save last played time every 500ms
      saveLastPlayed();
    }, 500);

    return () => clearInterval(interval);
  }, [currentTime, player]);

  useEffect(() => {
    if (isNativeIOS) iosAudioSetPlaybackRate(playbackSpeed);
    else if (player) player.playbackRate = playbackSpeed;
  }, [playbackSpeed]);

  function saveLastPlayed() {
    if (podcast) {
      localStorage.setItem(
        "lastPodcast",
        JSON.stringify({
          slug: podcast.slug,
          time: currentTime,
          id: podcast._id,
          title: podcast.title,
        })
      );
    }
  }

  function handlePlayPause() {
    if (player) {
      if (!isAudioPlaying) {
        player.play().catch(console.error);
        setAudioPlaying(true);
      } else {
        player.pause();
        setAudioPlaying(false);
      }
    } else {
      console.error("Couldn't play/pause audio. No player found.");
    }
  }

  async function handlePlayPauseNativeIos() {
    if (!isAudioPlaying) {
      if (!hasPlayedOnce) {
        const success = await iosAudioPlayFromUrl(
          podcast?.speech?.url as string
        );
        setPlayedOnce(success);
      } else {
        await iosAudioPlayResume();
      }
      setAudioPlaying(true);
    } else {
      const success = await iosAudioPause();
      setAudioPlaying(!success);
    }
    iosGenerateHaptic(HapticEffect.SELECTION);
  }

  function handleSkipBackward() {
    seek(Math.max(currentTime - 10, 0));
    setSpinningButton("backward");
    setTimeout(() => setSpinningButton(null), 300);
  }

  function handleSkipForward() {
    seek(Math.min(currentTime + 10, totalDuration));
    setSpinningButton("forward");
    setTimeout(() => setSpinningButton(null), 300);
  }

  function seek(time: number) {
    setCurrentTime(time);
    if (isNativeIOS) iosAudioSeek(time);
    else if (player) player.currentTime = time;
  }

  return (
    <RoomPageWrapper
      style={{ backgroundColor: roomData?.primaryColor ?? "#042B0B" }}
    >
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <InformationIcon onClick={() => setInfoPanelVisible(true)} />
      </HeaderBoxAutoSafe>

      <ContentWrapper>
        <CoverImageWrapper>
          {isLoading ? (
            <Skeleton
              variant='rounded'
              width={"100%"}
              height={"100%"}
              sx={{ backgroundColor: "#0b3813" }}
            />
          ) : (
            podcastCover && <img src={podcastCover} alt='Podcast cover' />
          )}
        </CoverImageWrapper>
        <InfoWrapper>
          {isLoading ? (
            <>
              <Skeleton
                variant='rounded'
                width={"100px"}
                height={14}
                sx={{ backgroundColor: "#81516E" }}
              />
              <Skeleton
                variant='rounded'
                width={"100%"}
                height={22}
                sx={{ backgroundColor: "#B4B4B4" }}
              />
              <Skeleton
                variant='rounded'
                width={"80%"}
                height={22}
                sx={{ backgroundColor: "#B4B4B4" }}
              />
            </>
          ) : (
            <>
              <span>{podcast?.article_type}</span>
              <h1>{podcast?.title}</h1>
            </>
          )}
        </InfoWrapper>

        {isLoading ? (
          <Skeleton
            variant='rounded'
            width={"100%"}
            height={5}
            sx={{ backgroundColor: "#B4B4B4", marginBottom: 2 }}
          />
        ) : (
          <SectionWrapper>
            <Slider
              min={0}
              value={(currentTime / totalDuration) * 100}
              onChange={(_, value) => seek(value as number)}
              sx={{
                color: "#FF78D2",
                borderRadius: 5,
                margin: "auto",
                display: "block",
              }}
            />
            <TimeDisplay>
              <span>{timePlayed}</span>
              <span>-{timeRemaining}</span>
            </TimeDisplay>
          </SectionWrapper>
        )}

        {!isLoading ? (
          <SectionWrapper>
            <ControlsWrapper>
              <SkipButtonWrapper
                onClick={handleSkipBackward}
                className={spinningButton === "backward" ? "spin-backward" : ""}
              >
                <span>10</span>
                <SkipBackIcon />
              </SkipButtonWrapper>
              {isAudioPlaying ? (
                <PauseIcon
                  onClick={
                    isNativeIOS ? handlePlayPauseNativeIos : handlePlayPause
                  }
                  style={{ height: "58px", width: "58px" }}
                />
              ) : (
                <PlayIcon
                  onClick={
                    isNativeIOS ? handlePlayPauseNativeIos : handlePlayPause
                  }
                  style={{ height: "58px", width: "58px" }}
                />
              )}
              <SkipButtonWrapper
                onClick={handleSkipForward}
                className={spinningButton === "forward" ? "spin-forward" : ""}
              >
                <span>10</span>
                <SkipForwardIcon />
              </SkipButtonWrapper>
            </ControlsWrapper>

            <ControlsWrapper>
              {[0.9, 1, 1.2].map((speed, index) => {
                return (
                  <SpeedControlButton
                    key={index}
                    isSelected={playbackSpeed === speed}
                    onClick={() => setPlaybackSpeed(speed)}
                  >
                    <span>x{speed}</span>
                  </SpeedControlButton>
                );
              })}
            </ControlsWrapper>
          </SectionWrapper>
        ) : (
          <CustomFlex>
            {[0, 1, 2].map((index) => (
              <Skeleton
                key={index}
                variant='circular'
                width={64}
                height={64}
                sx={{ backgroundColor: "#B4B4B4" }}
              />
            ))}
          </CustomFlex>
        )}
      </ContentWrapper>

      {isInfoPanelVisible && (
        <MoreInformationScreen
          onClose={() => setInfoPanelVisible(false)}
          title={podcast?.title}
          publicationDate={podcast?.publication_date as unknown as string}
          journalName={podcast?.journal?.name}
          primaryAthor={podcast?.primary_author}
          otherAuthors={podcast?.authors}
          doi={podcast?.doi}
        />
      )}
    </RoomPageWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px 20px calc(20px + var(--safe-area-bottom));

  .skeleton {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
`;

const CustomFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CoverImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  flex: 1;

  span {
    color: #ff78d2;
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  h1 {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.2px;
    margin: 0;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiSlider-rail {
    background: #ffffff;
  }

  .MuiSlider-track {
    background: #859486;
    border: none;
  }

  .MuiSlider-thumb {
    background: #ff78d2;
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 12px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;

const SkipButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  span {
    position: absolute;
    left: 0;
    right: 0;
    margin: 2px auto 0;
    color: white;
    text-align: center;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
  }

  svg {
    transition: transform 0.2s ease;
  }

  &.spin-backward svg {
    animation: spinBackward 0.2s ease-out;
  }

  &.spin-forward svg {
    animation: spinForward 0.2s ease-out;
  }

  @keyframes spinBackward {
    from {
      transform: rotate(0deg) scale(1);
    }
    to {
      transform: rotate(-360deg) scale(0.92);
    }
  }

  @keyframes spinForward {
    from {
      transform: rotate(0deg) scale(1);
    }
    to {
      transform: rotate(360deg) scale(0.92);
    }
  }
`;

const SpeedControlButton = styled.div<{ isSelected: boolean }>`
  background: ${(props) => (props.isSelected ? "#FF78D2" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;

  span {
    color: #042b0b;
    text-align: center;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
  }
`;

interface MoreInformationScreenProps {
  onClose: () => void;
  title?: string;
  publicationDate?: string;
  journalName?: string;
  primaryAthor?: string;
  otherAuthors?: string[];
  doi?: string;
}

function MoreInformationScreen({
  onClose,
  title,
  publicationDate,
  journalName,
  primaryAthor,
  otherAuthors,
  doi,
}: MoreInformationScreenProps) {
  useEffect(() => {
    disableScroll.off();
  }, []);

  const dateObject =
    typeof publicationDate === "string" ? new Date(publicationDate) : null;

  return (
    <MoreInformationScreenWrapper>
      <div className='content-wrapper'>
        <h2>
          {t("room:audio.infoPanel.title")}
          <CrossIcon onClick={onClose} />
        </h2>
        {title && (
          <>
            <h4>{t("common:content.title")}</h4>
            <p>{title}</p>
          </>
        )}
        {publicationDate && (
          <>
            <h4>{t("common:content.publicationDate")}</h4>
            <p>{renderPublicationDate(dateObject)}</p>
          </>
        )}
        {journalName && (
          <>
            <h4>{t("common:content.journal")}</h4>
            <p>{journalName}</p>
          </>
        )}
        {primaryAthor && (
          <>
            <h4>{t("common:content.primaryAuthor")}</h4>
            <p>{primaryAthor}</p>
          </>
        )}
        {otherAuthors && otherAuthors.length > 0 && (
          <>
            <h4>{t("common:content.otherAuthors")}</h4>
            <ul>
              {otherAuthors.map((author, index) => (
                <li key={index}>{author}</li>
              ))}
            </ul>
          </>
        )}
        {/* TODO: Hardcoded for now - remove in the future */}
        <h4>Promomats code</h4>
        <p>MAT-MX-2401677</p>
        {doi && (
          <>
            <h4>DOI</h4>
            <a href={`${doi}`}>{doi}</a>
          </>
        )}
        {/* NOTE: Enable when fixed */}
        {/* <LearnMoreContainer
          href="https://docsend.com/view/u6uerv9zibqhhcgw"
          target="_blank"
        >
          <div> */}
        {/* NOTE: Temporary until translation is added to locale files */}
        {/* {i18n.resolvedLanguage === SupportedLanguagesEnum.EN ? (
              <>
                <h3>Learn more about this publication!</h3>
                <span>Take me to the full original publication.</span>
              </>
            ) : (
              <>
                <h3>Más información sobre esta publicación</h3>
                <span>Llévame a la publicación original completa.</span>
              </>
            )}
          </div>
          <div>
            <LearnMoreIcon />
          </div>
        </LearnMoreContainer> */}
      </div>
    </MoreInformationScreenWrapper>
  );
}

const MoreInformationScreenWrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7efe6;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    padding: calc(25px + var(--safe-area-top)) 20px
      calc(25px + var(--safe-area-bottom));
    overflow-y: auto;
    height: 100%;
  }

  h2,
  h4,
  p,
  li,
  a {
    font-family: "Inter";
    color: #141414;
    margin: 0;
  }

  h2 {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 700;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  p,
  li,
  a {
    font-size: 16px;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0 0 8px 0;
  }

  li {
    margin-bottom: 4px;
  }

  a {
    text-decoration: underline;
    margin-bottom: 10px;
  }
`;

const LearnMoreContainer = styled.a`
  margin-top: 24px;
  background: #fff;
  display: flex;
  padding: 32px;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  text-decoration: none !important;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h3 {
    color: #042b0b;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 800;
    margin: 0;
  }

  span {
    color: #042b0b;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
  }
`;
