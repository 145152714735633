import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { loginEmailPassword } from "../../services/firebase";
import { changeUserPassword } from "../../domains/auth/endpoints/changePassword";
import axios from "axios";
import disableScroll from "disable-scroll";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import SaveHeader from "../../components/app/headers/SaveHeader";
import Loader from "../../components/Loader";
import { CustomInput } from "../../components/CustomInput";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import { ReactComponent as EyeOpen } from "../../assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "../../assets/icons/eye-closed.svg";

export default function SettingsEditSecurityPage() {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [isLoading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isConfirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(false);

  useEffect(() => {
    (async function () {
      disableScroll.on();

      const isAuthentified = await isUserAuthentified();
      if (!isAuthentified) return history.replace("/discovery");

      if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");
      setLoading(false);
    })();
  }, []);

  async function handleSubmit() {
    try {
      if (
        [password, newPassword, confirmNewPassword]
          .some((value: string) => value.length === 0)
      ) {
        return;
      }
      if (newPassword.length < 6) {
        displayToast(t("common:form.error.passwordLength"));
        return;
      }
      if (newPassword !== confirmNewPassword) {
        displayToast(t("common:form.error.passwords"));
        return;
      }

      await changeUserPassword({
        email: currentUser.email,
        password,
        newPassword,
      });
      displayToast(t("Thanks Doc. Your settings have been updated!"), "success");
      await loginEmailPassword(currentUser.email, newPassword);
    } catch (error) {
      console.error("Couldn't edit settings:", error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        displayToast(t("The password is invalid."));
      } else {
        displayToast(t("error:default"));
      }
    }
  }

  return (
    <>
      <SaveHeader
        title={t("settings.edit_settings")}
        saveReady={[password, newPassword, confirmNewPassword].every(value => value.length > 0)}
        onSaveClick={handleSubmit}
      />

      {isLoading
        ? <Loader />
        : (
          <Container>
            <Label>{t("common:form.field.email")}</Label>
            <Text>{currentUser.email}</Text>

            <Label>
              {t("common:form.field.password")}
              <CustomInput
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                autocomplete="current-password"
                placeholder={t("common:form.field.password")}
                onInput={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                suffix={!isPasswordVisible ? <EyeOpen /> : <EyeClosed />}
                suffixClick={() => setPasswordVisible(!isPasswordVisible)}
                required
              />
            </Label>

            <Label>
              {t("common:form.field.newPassword")}
              <CustomInput
                type={isNewPasswordVisible ? "text" : "password"}
                value={newPassword}
                autocomplete="new-password"
                placeholder={t("common:form.field.newPassword")}
                onInput={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                suffix={!isNewPasswordVisible ? <EyeOpen /> : <EyeClosed />}
                suffixClick={() => setNewPasswordVisible(!isNewPasswordVisible)}
                required
              />
            </Label>

            <Label>
              {t("common:form.field.confirmNewPassword")}
              <CustomInput
                type={isConfirmNewPasswordVisible ? "text" : "password"}
                value={confirmNewPassword}
                placeholder={t("common:form.field.confirmNewPassword")}
                onInput={(e: ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value)}
                suffix={!isConfirmNewPasswordVisible ? <EyeOpen /> : <EyeClosed />}
                suffixClick={() => setConfirmNewPasswordVisible(!isConfirmNewPasswordVisible)}
                required
              />
            </Label>
          </Container>
        )}
    </>
  );
}

const Container = styled.form`
  padding: 30px;
`;

const Label = styled.label`
  display: block;
  font-family: "Inter";
  line-height: 30px;
  font-size: 12px;
  font-weight: 400;
  color: #212121;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const Text = styled.span`
  font-family: "Inter";
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #212121;
`;