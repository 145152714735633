import { Component } from "react";
import styled from "styled-components";
import { CustomInput } from "../../components/CustomInput";
import { displayToast } from "../../components/app/AppToast";
import { CustomButton, Spacer } from "../../components/global";
import PatternBackground from "../../assets/images/bg_pattern.png";
import JuisciLogo from "../../assets/core/pipe-icon.svg";

const _VALID_PASSWORDS = ["loreal2022", "juisci@2022"];

class PasswordPage extends Component {
  state = {
    password: undefined,
  };

  componentDidMount() {
    // localStorage.clear();
    localStorage.removeItem("_accessAuthorized");
  }

  handleGetAuthorized() {
    if (_VALID_PASSWORDS.includes(this.state.password)) {
      localStorage.setItem("_accessAuthorized", "1");
      window.location.reload();
    } else displayToast("Oops! It seems to be the wrong password 🤭");
  }

  render() {
    return (
      <PageContainer>
        <div className='form'>
          <img src={JuisciLogo} alt='' />
          <h4>Welcome to Juisci</h4>
          <CustomInput
            placeholder='Password'
            type='password'
            onInput={(e) => this.setState({ password: e.target.value })}
            background='#F5F5F5'
          />
          <Spacer />
          <CustomButton onClick={this.handleGetAuthorized.bind(this)}>
            Access the app
          </CustomButton>
        </div>
      </PageContainer>
    );
  }
}

export default PasswordPage;

const PageContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: ${window.innerHeight}px;
  background: linear-gradient(rgba(255, 196, 8, 0.75), rgba(255, 196, 8, 0.75)),
    url(${PatternBackground});

  .form {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 45%;
    transform: translateY(-45%);
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;

    img {
      margin: auto;
      display: block;
      height: 70px;
      width: 70px;
    }

    h4 {
      font-family: "Inter";
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      margin-top: 20px;
    }
  }
`;
