import { useEffect } from "react";
import { useAppSelector } from "../../redux";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { useSearchNectars } from "../../domains/search/hooks/useSearchNectars";
import { ItemDataEventListName, ItemDataVariant, ItemsDataEvent } from "../../interfaces";
import { convertContentToItemData, gtmItemsData } from "../../tools/reactgaEvents";
import Loader from "../Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import PreviewCard from "../profile/PreviewCard";
import { NoResultText, SearchResultsListWrapper } from "./layout";
import { t } from "i18next";

export default function SearchResultsNectars() {
	const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
	const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
	const searcher = useSearchNectars(searchValueParam);

	useEffect(() => {
		for (const list of [searcher.fallback, searcher.results]) {
			if (list.length) {
				gtmItemsData(
					ItemsDataEvent.VIEW_ITEM_LIST,
					convertContentToItemData(
						list,
						ItemDataEventListName.SEARCH,
						ItemDataVariant.NECTAR,
					)
				);
			}
		}
	}, [searcher.fallback, searcher.results]);

	if (!searchValueParam && !filtersApplied) {
		if (searcher.isLoadingFallback) {
			return <Loader loaderOnly />;
		}

		return (
			<FadeIn key="fallback">
				<SearchResultsListWrapper>
					{searcher.fallback.map((nectar: NectarModel) => {
						return (
							<PreviewCard
								key={nectar._id}
								content={nectar}
								gtmItemListName={ItemDataEventListName.SEARCH}
							/>
						);
					})}
				</SearchResultsListWrapper>
			</FadeIn>
		);
	}

	if (searcher.isLoadingResults) {
		return <Loader loaderOnly />;
	}

	if (!searcher.results.length) {
		return <NoResultText>{t("common:noSearchResults")}</NoResultText >;
	}

	return (
		// NOTE: specific key to force fade when switching results
		<FadeIn key="results">
			<InfiniteScroll
				dataLength={searcher.results.length}
				next={() => searcher.searchMore()}
				hasMore={searcher.results.length < searcher.total}
				pullDownToRefresh={false}
				scrollableTarget="content-ref"
				loader={null}
			>
				<SearchResultsListWrapper>
					{searcher.results.map((nectar: NectarModel) => {
						return (
							<PreviewCard
								key={nectar._id}
								content={nectar}
								gtmItemListName={ItemDataEventListName.SEARCH}
							/>
						);
					})}
					{searcher.isLoadingResults && <Loader loaderOnly />}
				</SearchResultsListWrapper>
			</InfiniteScroll>
		</FadeIn>
	);
}