import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { iosGenerateHaptic, isNativeIOS } from "../../tools/ios";
import CustomIcon from "../CustomIcon";
import { ArticleModel } from "../../domains/article/article.types";
import { RoundIcon } from "../global";
import Lottie from "lottie-react";
import Spinner from "../../assets/animations/spinner.json";
import {
  convertContentToItemData,
  gtmAudioProgress,
  gtmItemsData,
} from "../../tools/reactgaEvents";
import {
  HapticEffect,
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
} from "../../interfaces";
import { Slider } from "@mui/material";
import {
  iosAudioGetCurrentTime,
  iosAudioGetDuration,
  iosAudioPause,
  iosAudioPlayFromUrl,
  iosAudioPlayResume,
  iosAudioSeek,
  iosAudioSetPlaybackRate,
  iosAudioStop,
} from "../../tools/ios/audio";
import { formatTime } from "../../tools/utils";

export default function AudioPlayer({
  article,
  trackIndex,
  trackNumber,
  onPrevious,
  onNext,
}: {
  article: ArticleModel | null;
  trackNumber?: number;
  trackIndex?: number;
  onPrevious?: Function;
  onNext?: Function;
}) {
  const playerRef = useRef<HTMLAudioElement | null>(null);
  const [player, setPlayer] = useState<HTMLAudioElement | null>(null);
  const [totalDuration, setTotalDuration] = useState(-1);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const loadPlayer = async (): Promise<any> => {
      if (!article) return;

      if (isNativeIOS) {
        let success = false;
        let duration = 0;

        while (!success)
          success = await iosAudioPlayFromUrl(article?.speech?.url as string);

        while (duration <= 0) duration = await iosAudioGetDuration();

        setTotalDuration(duration);

        if (!isPlaying) await iosAudioPause();
      } else {
        const audio = new Audio(article?.speech?.url);

        audio.addEventListener("loadedmetadata", () => {
          setTotalDuration(audio.duration);
          // setLoading(false);
        });

        audio.addEventListener("ended", () => {
          if (onEnded) onEnded();
        });

        audio.addEventListener("timeupdate", () => {
          setCurrentTime(audio.currentTime);
          if (onProgress) {
            const progressRate = Math.ceil(
              (audio.currentTime / audio.duration) * 100
            );
            onProgress(progressRate);
          }
        });

        audio.load();
        playerRef.current = audio;
        setPlayer(audio);

        if (isPlaying) playerRef.current.play();
      }
    };

    loadPlayer();

    return () => {
      stopAudio();
    };
  }, [article]);

  useEffect(() => {
    return () => {
      stopAudio();
    };
  }, []);

  useEffect(() => {
    if (isPlaying) isNativeIOS ? iosAudioPlayResume() : player?.play();
    else isNativeIOS ? iosAudioPause() : player?.pause();
  }, [isPlaying]);

  useEffect(() => {
    async function onAudioListen() {
      if (!isNativeIOS) return;

      const time = await iosAudioGetCurrentTime();
      setCurrentTime(time);

      if (Math.floor(currentTime) === Math.floor(totalDuration)) onEnded();

      if (onProgress) {
        const progressRate = Math.ceil((currentTime / totalDuration) * 100);
        onProgress(progressRate);
      }
    }

    const interval = setInterval(() => {
      onAudioListen();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [currentTime]);

  useEffect(() => {
    if (isNativeIOS) iosAudioSetPlaybackRate(playbackRate);
    else if (player) player.playbackRate = playbackRate;
  }, [playbackRate]);

  async function stopAudio() {
    if (isNativeIOS) await iosAudioStop();
    else if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.currentTime = 0;
      playerRef.current.removeEventListener("loadedmetadata", () => {});
      playerRef.current.removeEventListener("ended", () => {});
      playerRef.current.removeEventListener("timeupdate", () => {});
      playerRef.current = null;
    }

    setTotalDuration(-1);
  }

  function handlePlayPause() {
    setIsPlaying(!isPlaying);

    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
  }

  function canGoPreviousTrack() {
    if (trackNumber === undefined || trackIndex === undefined) {
      return false;
    }
    return trackNumber > 0 && trackIndex > 0;
  }

  function canGoNextTrack() {
    if (trackNumber === undefined || trackIndex === undefined) {
      return false;
    }
    return trackIndex > -1 && trackIndex < trackNumber - 1;
  }

  function onEnded() {
    gtmItemsData(
      ItemsDataEvent.AUDIO_COMPLETE,
      convertContentToItemData(
        [article],
        ItemDataEventListName.ARTICLE_PAGE,
        ItemDataVariant.ARTICLE
      )
    );
  }

  function onProgress(progressRate: number) {
    gtmAudioProgress(
      ItemsDataEvent.AUDIO_PROGRESS,
      progressRate,
      article?.title,
      ItemDataEventListName.ARTICLE_PAGE,
      article?._id,
      article?.journal?.name,
      article?.medical_specialties?.[0]?.uid
    );
  }

  function onSeek(value: number) {
    setCurrentTime(value);

    if (player) player.currentTime = value;
    else if (isNativeIOS) {
      iosAudioSeek(value);
      iosGenerateHaptic(HapticEffect.SELECTION);
    }
  }

  function handleBackAudio() {
    const newTime = currentTime - 10 < 0 ? 0 : currentTime - 10;

    if (player) player.currentTime = newTime;
    else if (isNativeIOS) {
      iosGenerateHaptic(HapticEffect.SELECTION);
      iosAudioSeek(newTime);
    }

    setCurrentTime(newTime);
  }

  function handleAudioPlaybackRate() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);

    switch (playbackRate) {
      case 1:
        setPlaybackRate(1.5);
        break;
      case 1.5:
        setPlaybackRate(2);
        break;
      default:
        setPlaybackRate(1);
    }
  }

  function handleGoPrevTrack() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    if (canGoPreviousTrack() && onPrevious) onPrevious();
  }

  function handleGoNextTrack() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    if (canGoNextTrack() && onNext) onNext();
  }

  return (
    <>
      <PlayIcon
        onClick={handlePlayPause}
        isPlaying={isPlaying}
        isLoading={!article}
      />

      <ControllerWrapper
        className={isPlaying ? "" : "hide"}
        isNative={isNativeIOS}
      >
        <Controls>
          {onPrevious && (
            <CustomIcon
              iconName='prev_audio'
              className={!canGoPreviousTrack() ? "disabled" : ""}
              onClick={handleGoPrevTrack}
            />
          )}

          <CustomIcon iconName='back_audio' onClick={handleBackAudio} />

          <CustomIcon
            iconName={isPlaying ? "pause_audio" : "play_audio"}
            onClick={handlePlayPause}
          />

          <SpeedButton onClick={handleAudioPlaybackRate}>
            x{playbackRate ?? 1}
          </SpeedButton>

          {onNext && (
            <CustomIcon
              className={!canGoNextTrack() ? "disabled" : ""}
              iconName='next_audio'
              onClick={handleGoNextTrack}
            />
          )}
        </Controls>

        <SliderControls>
          <div style={{ width: 50 }}>{formatTime(currentTime)}</div>
          <Slider
            min={0}
            max={totalDuration}
            value={currentTime}
            onChange={(_, value) => onSeek(value as number)}
            sx={{
              "& .MuiSlider-track": {
                backgroundColor: "white",
                height: 5,
                borderRadius: 30,
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#212121",
                height: 5,
                borderRadius: 30,
              },
              "& .MuiSlider-thumb": {
                height: 15,
                width: 15,
                backgroundColor: "white",
                "&:focus, &:hover, &.Mui-active": {
                  boxShadow: "none",
                },
              },
            }}
          />
          <div style={{ width: 50 }}>
            {totalDuration > 0 ? formatTime(totalDuration) : "..."}
          </div>
        </SliderControls>
      </ControllerWrapper>
    </>
  );
}

function PlayIcon({
  isPlaying,
  isLoading,
  onClick,
}: {
  isPlaying: boolean;
  isLoading: boolean;
  onClick: Function;
}) {
  return (
    <RoundIcon>
      {isLoading ? (
        <div
          style={{
            width: 60,
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(180deg, #F42CAC 0%, #F1353B 100%)",
          }}
        >
          <Lottie
            animationData={Spinner}
            style={{ height: 30 }}
            loop={true}
            autoplay={true}
          />
        </div>
      ) : (
        <CustomIcon
          iconName={!isPlaying ? "play_audio" : "pause_audio"}
          className={`play-icon ${!isPlaying ? "playing" : ""}`}
          style={{
            background: "linear-gradient(180deg, #F42CAC 0%, #F1353B 100%)",
          }}
          onClick={onClick}
        />
      )}
    </RoundIcon>
  );
}

const ControllerWrapper = styled.div<{ isNative: boolean }>`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  bottom: calc(50px + var(--safe-area-bottom));
  z-index: 100;
  margin: 20px;
  padding: 30px 20px;
  border-radius: 8px;
  background: linear-gradient(180deg, #f42cab 0%, #f1353a 100%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: slideIn 0.2s ease-in;

  &.hide {
    opacity: 0;
    display: none;
    animation: slideOut 0.2s ease-out;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      bottom: -10px;
    }
    100% {
      opacity: 1;
      bottom: ${(props) => (props.isNative ? "70px" : "15px")};
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      bottom: ${(props) => (props.isNative ? "70px" : "15px")};
    }
    100% {
      opacity: 0;
      bottom: -10px;
    }
  }

  color: white;
  font-weight: 800;
`;

const Controls = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;

  svg {
    height: 27px;
  }

  .--custom-icon.disabled {
    opacity: 0.5;
  }
`;

const SliderControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;

  .--custom-icon.disabled {
    opacity: 0.5;
  }
`;

const SpeedButton = styled.button`
  outline: none;
  border: none;
  background: white;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 50%;
  font-size: 10px;
  color: #f42cab;
  font-weight: 500;
  font-family: "Inter";
  padding: 0;
`;
