import { InfographicModel } from "../../interfaces/infographic.types";
import styled from "styled-components";
import {
  CardContainer,
  CardActions,
  CardDate,
  CardTags,
  CardType,
  CardTitle,
  CondensedCardFooter,
} from "./layout";
import { gtmItemData } from "../../tools/reactgaEvents";
import {
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
} from "../../interfaces";
import { useHistory } from "react-router-dom";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { t } from "i18next";
import { ReactComponent as InfographicIcon } from "../../assets/icons/image-icon.svg";

export default function InfographicCard({
  infographic,
  className,
  gtmItemListName,
}: {
  infographic: any; // TODO: Fix with InfographicModel
  className?: string;
  gtmItemListName?: ItemDataEventListName;
}) {
  const history = useHistory();
  const thumbnailUrl = infographic.thumbnail?.url ?? "";

  function handleNavigate() {
    // TODO: GTM.
    // if (gtmItemListName) {
    // gtmItemData({
    //   event: ItemsDataEvent.SELECT_ITEM,
    //   item_name: video.title,
    //   item_list_name: gtmItemListName,
    //   item_id: video._id,
    //   item_brand: video.company?.name ?? null,
    //   item_category: video.medical_specialties, //[0]?.uid, // TODO: Fix this
    //   item_category2: "standard",
    //   item_variant: video.videoFormat === VideoFormat.STORY
    //     ? ItemDataVariant.VIDEO_STORY
    //     : ItemDataVariant.VIDEO_ARTICLE,
    // });
    // TODO: Infographic page.
    history.push({
      pathname: `/infographic/${infographic.slug}`,
      state: { infographic },
    });
  }

  return (
    <CardContainer
      variant="dark"
      backgroundUrl={thumbnailUrl}
      onClick={handleNavigate}
    >
      <CardHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: "8px",
          }}
        >
          <CardDate date={infographic.publication_date} />
          <CardTags contentLabel="infographic" tags={infographic.medical_specialties} />
        </div>
        {/* <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }}
          /> */}
      </CardHeader>
      <CardBody>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <CardType>{t("contentType.infographic")}</CardType>
          <CardTitle lines={4}>{infographic.title}</CardTitle>
        </div>
        <div>
          <InfographicIcon />
        </div>
      </CardBody>
      <CondensedCardFooter
        className="dark"
        companyName={infographic.company?.name ?? infographic.congress?.name}
        companyLogo={
          infographic.company?.images
            ? infographic.company?.images[0]?.url
            : infographic.congress?.image.url
        }
      />
    </CardContainer>
  );
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
