import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { t } from "i18next";
import { isNativeIOS } from "../../tools/ios";
import { isNativeAndroid } from "../../tools/android";
import { ItemDataEventListName, ItemDataVariant, ItemsDataEvent } from "../../interfaces";
import { gtmItemsData } from "../../tools/reactgaEvents";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetForYouContentQuery } from "../../domains/forYou/endpoints/getForYouContent";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { displayToast } from "../../components/app/AppToast";
import BottomNavbar from "../../components/app/BottomNavbar";
import styled from "styled-components";
import { clearRecommendedContent, memorizeRecommendedContent } from "../../domains/forYou/forYou.reducer";
import DownloadAppModal from "../../components/home/modals/DownloadAppModal";
import ForYouMainSlider from "../../components/forYou/ForYouMainSlider";

export default function ForYouPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const recommendedContent = useAppSelector((state) => state.forYou.cache.recommendedContent);
  const { appLang } = useAppLang();
  const { currentUser } = useCurrentUser();
  const [isLoadingContent, setLoadingContent] = useState(!recommendedContent.length);
  const [isNativeModalVisible, setNativeModalVisible] = useState(false);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getForYouContent, {
    isFetching: isFetchingContent,
  }] = useLazyGetForYouContentQuery();

  useEffect(() => {
    (async function () {
      try {
        // NOTE: Landing page, split auth & onboarding checks
        const { isAuthentified } = await preflightUser({ history, authOnly: true });
        if (!isAuthentified) return;

        if (!currentUser) await getCurrentUser();

        scrollTo(0, 0);

        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get("onboarding") === "complete") {
          if (!isNativeIOS && !isNativeAndroid) setNativeModalVisible(true);
        }

        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.foryou"),
        });
        if (isRedirected) return;

        if (!recommendedContent.length) {
          loadRecommendedContent(true);
        }
      } catch (error) {
        console.error("Couldn't mount For You page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();

    return () => {
      // NOTE: Clear cache to force refetch when back from other sections than For You
      if (!history.location.pathname.startsWith("/foryou")) {
        dispatch(clearRecommendedContent());
        localStorage.removeItem("foryou_carousel_index");
      }
    }
  }, []);

  function loadRecommendedContent(resetList = false) {
    try {
      getForYouContent({
        limit: 10,
        offset: resetList ? 0 : recommendedContent.length,
        language: appLang,
      })
        .unwrap()
        .then((docs) => {
          const newList = resetList ? docs : [...recommendedContent, ...docs];
          dispatch(memorizeRecommendedContent(newList));
          handleGTM(docs);
          setLoadingContent(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load recommended content.");
      displayToast(t("error:default"));
    }
  }

  function handleGTM(docs: any[]) {
    const items = docs.map((content) => {
      const item: any = {
        item_list_name: ItemDataEventListName.FORYOU,
        item_id: content._id,
        item_category: content.medical_specialties?.[0]?.uid,
        item_category2: "standard",
        quantity: 1,
      };

      if ("apiVideo" in content) {
        // Video
        item.name = content.title;
        item.item_brand = content.company?.name;
        item.item_variant = ItemDataVariant.VIDEO_STORY;
      } else if ("answer" in content) {
        // Nectar
        item.name = content.question;
        item.item_brand = content.organisations?.[0]?.name;
        item.item_variant = ItemDataVariant.NECTAR;
      } else {
        // Article
        item.name = content.title;
        item.item_brand = content.journal?.name;
        item.item_variant = ItemDataVariant.ARTICLE;
      }
      return item;
    });

    gtmItemsData(ItemsDataEvent.VIEW_ITEM_LIST, items);
  }

  function handleSlideChange(newIndex: number) {
    if (newIndex >= recommendedContent.length - 3) {
      if (recommendedContent.length < 50 && !isFetchingContent) {
        loadRecommendedContent();
      }
    }
  }

  return (
    <>
      <Wrapper>
        <ForYouMainSlider
          contentList={recommendedContent}
          isLoading={isLoadingContent}
          // TODO: improve this part
          isLocked={isNativeModalVisible || isLoadingContent}
          onSlideChange={handleSlideChange}
        />

        <BottomNavbar />
      </Wrapper>

      <DownloadAppModal
        isVisible={isNativeModalVisible}
        onClose={() => {
          setNativeModalVisible(false);
          // NOTE: To hide the modal?
          history.replace("/foryou");
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background: linear-gradient(180deg, #FFC408 0%, rgba(255, 255, 255, 0.00) 100%), #F7F6F2;
`;
