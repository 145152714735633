import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { LemonCertifiedRequestStatus } from "../../domains/user/user.types";
import { getUserCertificationStatus } from "../../domains/user/endpoints/getUserCertificationStatus";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import disableScroll from "disable-scroll";
import { logoutUserFromApp } from "../../tools/utils";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import ProfileHeader from "../../components/app/headers/ProfileHeader";
import Loader from "../../components/Loader";
import ProfileInfo from "../../components/settings/ProfileInfo";
import SettingButton from "../../components/settings/SettingButton";
import SettingButtonLink from "../../components/settings/SettingButtonLink";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as CertificationStatusPendingIcon } from "../../assets/icons/certification-status-pending.svg";

export default function SettingsPage() {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [certifiedStatus, setCertifiedStatus] = useState<LemonCertifiedRequestStatus | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [isLoggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      disableScroll.off();

      if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

      const { isRedirected } = await preflightUser({ history, authOnly: true });
      if (isRedirected) return;

      if (currentUser.lemonCertified) {
        setCertifiedStatus(LemonCertifiedRequestStatus.Approved);
      } else {
        const { status } = await getUserCertificationStatus();
        setCertifiedStatus(status ?? null);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <ProfileHeader user={currentUser} title={t("settings.settings")} />
      {isLoading
        ? <Loader />
        : (
          <Container>
            <HeadingContainer>
              <h2>{t("Profile")}</h2>
              <Link to="/settings/edit/profile">
                <ProfileInfo />
              </Link>
            </HeadingContainer>

            <LinksContainer>
              {certifiedStatus === null &&
                <SettingButtonLink
                  iconName="lemon-certified-settings"
                  to="/settings/request/certification"
                >{t("settings.certification_options.default")}</SettingButtonLink>
              }

              {certifiedStatus === LemonCertifiedRequestStatus.Approved &&
                <SettingButton
                  className="certified-approved"
                  iconName="lemon-certified-settings"
                >{t("settings.certification_options.approved")}</SettingButton>
              }

              {certifiedStatus === LemonCertifiedRequestStatus.Waiting &&
                <SettingButton
                  className="certified-pending"
                  icon={<CertificationStatusPendingIcon />}
                >
                  {t("settings.certification_options.waiting")}
                </SettingButton>
              }

              {certifiedStatus === LemonCertifiedRequestStatus.Rejected &&
                <SettingButton
                  className="certified-rejected"
                  icon={<CertificationStatusPendingIcon />}
                >
                  {t("settings.certification_options.rejected")}
                </SettingButton>
              }

              <SettingButtonLink iconName='settings_edit' to='/settings/edit/profile'>
                {t("settings.edit_profile")}
              </SettingButtonLink>

              <SettingButtonLink
                iconName='settings_preferred'
                to='/settings/edit/profile/preferredContent'
              >
                {t("settings.preferred_content")}
              </SettingButtonLink>

              <HeadingContainer>
                <h2>{t("Settings")}</h2>
              </HeadingContainer>

              <SettingButtonLink iconName='settings_security' to='/settings/edit/security'>
                {t("Password Security")}
              </SettingButtonLink>

              <SettingButtonLink iconName='settings_lang' to='/settings/edit/language'>
                {t("Interface Language")}
              </SettingButtonLink>

              <SettingButtonLink
                iconName='settings_terms'
                to={{ pathname: `/terms`, state: { isPush: true } }}
              >
                {t("settings.terms")}
              </SettingButtonLink>

              <SettingButtonLink
                iconName='settings_policy'
                to={{ pathname: `/policy`, state: { isPush: true } }}
              >
                {t("settings.policies")}
              </SettingButtonLink>

              <SettingButtonLink iconName='settings_account' iconScale={1.5} to='/settings/account'>
                {t("settings.account")}
              </SettingButtonLink>

              <LogoutLink
                className={isLoggingOut ? "logging-out" : ""}
                onClick={() => {
                  setLoggingOut(true);
                  logoutUserFromApp();
                }}
              >
                {t("settings.logout")}
              </LogoutLink>
            </LinksContainer>
          </Container>
        )}
    </>
  );
}

const Container = styled.div`
  box-sizing: border-box;

  h2 {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.025em;
  }
`;

const HeadingContainer = styled.div`
  padding: 30px 30px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LogoutLink = styled.span`
  margin: 36px 0px;
  color: #FF4300;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;

  &.logging-out {
    color: #81939c;
  }
`;
