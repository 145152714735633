import { useHistory } from "react-router-dom";
import { NectarModel } from "../../nectar/nectar.types";
import { useCurrentUser } from "./useCurrentUser";
import { shareContent } from "../endpoints/shareContent";
import { preflightUser } from "../utils/preflightUser";
import { convertContentToItemData, gtmItemsData } from "../../../tools/reactgaEvents";
import { ItemDataVariant, ItemsDataEvent, SupportedLanguagesEnum } from "../../../interfaces";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { isAndroid } from "react-device-detect";
import { androidShare } from "../../../tools/android";
import i18n from "../../../config/i18n";

export function useShareNectar(nectar: NectarModel | null) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  return async function () {
    try {
      if (!nectar) throw new Error("Invalid nectar.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.shareContent"),
      });
      if (isRedirected) return;

      const url = `
        ${window.location.origin}/nectar/${nectar.slug}?shared=${currentUser?.uid ?? null}
      `;

      // TODO: localize
      const text = i18n.resolvedLanguage === SupportedLanguagesEnum.FR
        ? `Je pense que ce nectar pourrait t'intéresser: ${nectar.title}.`
        : `I think you might be interested in this nectar: ${nectar.title}.\n`;

      if (isAndroid) {
        return androidShare(url, text);
      }

      if (!navigator.share) {
        displayToast(t("toast.fail.browser"));
      }

      await navigator.share({
        title: document.title,
        text,
        url,
      });

      await shareContent(nectar.slug);

      gtmItemsData(
        ItemsDataEvent.NECTAR_SHARE,
        convertContentToItemData(
          [nectar],
          undefined,
          ItemDataVariant.NECTAR,
        )
      );

      displayToast(t("you have shared a nectar"), "success");
    } catch (error) {
      console.error("Couldn't share nectar.", error);
      displayToast(t("error:default"));
    }
  };
}