import { MouseEvent, SyntheticEvent, useState } from "react";
import styled from "styled-components";
import { ArticleModel } from "../../domains/article/article.types";
import {
  CardContainer,
  CardActions,
  CardDate,
  CardTags,
  CardType,
  CardTitle,
  CardJournal,
  CardAuthor,
} from "./layout";
import { gtmItemData } from "../../tools/reactgaEvents";
import {
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
} from "../../interfaces";
import { useHistory } from "react-router-dom";
import { useLikeArticle } from "../../domains/user/hooks/useLikeArticle";
import { useSaveArticle } from "../../domains/user/hooks/useSaveArticle";
import { useShareArticle } from "../../domains/user/hooks/useShareArticle";
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { t } from "i18next";

export default function ArticleCard({
  article,
  gtmItemListName,
  variant = "white",
  onClick,
}: {
  article: ArticleModel;
  gtmItemListName?: ItemDataEventListName;
  variant?: "white" | "yellow";
  onClick?: Function;
}) {
  const history = useHistory();
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const { isLiked, toggleLike } = useLikeArticle(article, gtmItemListName);
  const { isSaved, toggleSaveToPlaylists } = useSaveArticle(article);
  const shareArticle = useShareArticle(article);

  function handleNavigate() {
    if (gtmItemListName) {
      gtmItemData({
        event: ItemsDataEvent.SELECT_ITEM,
        item_name: article.title,
        item_list_name: gtmItemListName,
        item_id: article._id,
        item_brand: article.journal?.name ?? null,
        item_category: article.medical_specialties[0]?.uid,
        item_category2: "standard",
        item_variant: ItemDataVariant.ARTICLE,
      });
    }
    history.push(`/post/${article.slug}`);
  }

  function handleNavigateJournal(e: SyntheticEvent) {
    e.stopPropagation();
    console.log("DDD", article.journal);
    history.push(`/journal/${article.journal?.uid}`);
  }

  return (
    <>
      <CardContainer
        variant={variant}
        onClick={() => {
          onClick && onClick();
          handleNavigate();
        }}
      >
        <CardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "8px",
            }}
          >
            <CardDate date={article.publication_date} />
            <CardTags
              contentLabel="article"
              tags={article.medical_specialties}
              variant={variant}
            />
          </div>
          <CardActions
            isLiked={isLiked}
            onLike={(e: MouseEvent) => {
              e.stopPropagation();
              toggleLike();
            }}
            onOptions={(e: MouseEvent) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }}
          />
        </CardHeader>

        <CardBody>
          <CardType>{t(article.article_type ?? "Clinical Study")}</CardType>
          <CardTitle lines={4}>{article.title}</CardTitle>
        </CardBody>

        <CardFooter>
          <CardJournal
            journalName={article.journal?.name}
            journalLogo={article.journal?.image?.url}
            onClick={handleNavigateJournal}
          />
          <CardAuthor
            primaryAuthor={article.primary_author}
            authors={article.authors}
          />
        </CardFooter>
      </CardContainer>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onDismiss={() => {
          setOptionsPanelVisible(false);
        }}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareArticle}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={article}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({
          add,
          remove,
        }: {
          add: string[];
          remove: string[];
        }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;
