import React, { Component } from "react";
import styled from "styled-components";
import { Drawer } from "@mui/material";
import FiltersPage from "./FiltersPage";
import { iosDismissKeyboard } from "../../tools/ios";
import { androidDismissKeyboard } from "../../tools/android";
import { gtmItemsData } from "../../tools/reactgaEvents";
import { ItemsDataEvent } from "../../interfaces";

class FiltersDrawer extends Component {
  inputDrawerRef = React.createRef();

  handleDismissKeyboard = () => {
    this.inputDrawerRef.current.blur();
    iosDismissKeyboard();
    androidDismissKeyboard();
  };

  handleSelectFilters = ({ medical_specialties, tags }) => {
    let itemsData;

    if (typeof medical_specialties === "string")
      itemsData = [
        {
          item_id: tags?.[0]?.parent?._id, // Id de la spécialité
          item_name: tags?.[0]?.parent?.uid, // nom de la spécialilité
          item_category: tags
            .map((tag) => tag.uid)
            .toString()
            .replace(" ", ""),
          item_list_id: "filters",
          item_list_name: "filters",
          quantity: 1,
        },
      ];
    else
      itemsData = medical_specialties.map((specialty) => ({
        item_id: specialty._id, // Id de la spécialité
        item_name: specialty.uid, // nom de la spécialilité
        item_category: !!tags.some((tag) => tag.parent.uid === specialty.uid)
          ? tags
              .filter((tag) => tag.parent.uid === specialty.uid)
              .map((tag) => tag.uid)
              .toString()
              .replace(" ", "")
          : specialty.uid, // sous précialités (2 ou plus)
        item_list_id: "filters",
        item_list_name: "filters",
        quantity: 1,
      }));

    gtmItemsData(ItemsDataEvent.SET_FILTER, itemsData);
  };

  render() {
    return (
      <StyledDrawer
        anchor='left'
        open={this.props.showDrawer}
        disableScrollLock={false}
        onClose={this.props.onClose}
        onScroll={this.handleDismissKeyboard.bind(this)}
      >
        <div style={{ width: window.innerWidth }}>
          <FiltersPage
            onSubmit={(filters) => {
              this.props.handleFilters(filters);
              this.handleSelectFilters(filters);
            }}
            inputDrawerRef={this.inputDrawerRef}
            {...this.props}
          />
        </div>
      </StyledDrawer>
    );
  }
}

export default FiltersDrawer;

const StyledDrawer = styled(Drawer)`
  width: 100%;
  box-sizing: border-box;

  .MuiDrawer-paper {
    box-sizing: border-box;
    overflow: scroll;
  }
`;