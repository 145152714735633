import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { NotificationModel } from "../../domains/app/app.types";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { getNotifications } from "../../domains/notification/endpoints/getNotifications";
import { readAllNotifications } from "../../domains/notification/endpoints/readAllNotifications";
import { readNotification } from "../../domains/notification/endpoints/readNotification";
import { deleteNotification } from "../../domains/notification/endpoints/deleteNotification";
import Loader from "../../components/Loader";
import ProfileHeader from "../../components/app/headers/ProfileHeader";
import NotificationItem from "../../components/NotificationItem";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";

export default function NotificationCenter() {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [isLoading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteTimeoutId, setDeleteTimeoutId] = useState<TimeoutId>();
  const [deleteCallback, setDeleteCallback] = useState<Function | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const isAuthentified = await isUserAuthentified();
        if (!isAuthentified) return history.replace("/discovery");

        loadNotifications();
      } catch (error) {
        console.error("Couldn't mount notification center.", error);
        displayToast(t("error:default"));
      }
    })();
  }, []);

  useEffect(() => {
    if (isDeleteModalVisible) {
      const timeoutId = setTimeout(() => {
        setDeleteModalVisible(false);
      }, 3000);
      setDeleteTimeoutId(timeoutId);
    } else {
      if (deleteId) {
        confirmPendingDeletion();
      }
    }
  }, [isDeleteModalVisible]);

  async function loadNotifications() {
    try {
      const res = await getNotifications();
      const notifications = res.filter((n: { deleted: boolean }) => !n.deleted);
      await readAllNotifications();
      setNotifications(notifications);
      setLoading(false);
    } catch (error) {
      console.error("Couldn't load notifications.", error);
      displayToast(t("error:default"));
    }
  }

  async function confirmPendingDeletion() {
    try {
      await deleteNotification(deleteId);
  
      if (deleteCallback) {
        deleteCallback();
        setDeleteCallback(null);
        clearTimeout(deleteTimeoutId);
      }
      setNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification._id !== deleteId)
      );
      setDeleteId("");
    } catch (error) {
      console.error("Couldn't delete notification.", error);
      displayToast(t("error:default"));
    }
  }

  async function handleClickNotification(notification: NotificationModel) {
    try {
      await readNotification(notification._id);
      loadNotifications();
    } catch (error) {
      console.error("Couldn't mark notification as read.", error);
      displayToast(t("error:default"));
    }
  }

  async function handleSoftDeleteNotification(notificationId: string, cb: Function | void) {
    if (deleteId) {
      await confirmPendingDeletion();
    }
    setDeleteModalVisible(true);
    setDeleteId(notificationId);
    // NOTE: We need to set the callback in a callback to not have it trigger immediately.
    if (cb) setDeleteCallback(() => () => cb());
  }

  function handleCancelDeletion() {
    setDeleteModalVisible(false);
    setDeleteId("");
    setDeleteCallback(null);
    clearTimeout(deleteTimeoutId);
  }

  return (
    <Wrapper>
      <ProfileHeader user={currentUser} />
      {isLoading ? <Loader /> : (
        <Content>
          <h1>{t("Notification Center")}</h1>
          <hr />
          {!notifications.length && (
            <div className="empty">{t("notifications.empty")}</div>
          )}
          <NotificationsList>
            {notifications.map((notification, index) => {
              return notification._id === deleteId ? null : (
                <NotificationItem
                  key={index}
                  notification={notification}
                  onClick={() => handleClickNotification(notification)}
                  onDelete={(cb: Function | void) => handleSoftDeleteNotification(notification._id, cb)}
                />
              );
            })}
          </NotificationsList>
        </Content>
      )}
      {isDeleteModalVisible && (
        <DeleteModal>
          <span>{t("Notification deleted")}</span>
          <span className="cancel" onClick={handleCancelDeletion}>{t("common:action.cancel")}</span>
        </DeleteModal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: var(--SECONDARY-GREY-SHADES-Bluegrey-2, #ECF0F5);
`;

const Content = styled.div`
  h1 {
    margin: 16px 22px;
    color: var(--JUISCI-Black-Matters, #212121);
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%; /* 25.2px */
  }

  hr {
    border-color: var(--LIGHT-GREY, #DFE6EC);
  }

  .empty {
    padding: 16px 22px;
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4C5861);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 18.2px */
  }
`;

const NotificationsList = styled.div`
  padding: 0 22px;
`;

const DeleteModal = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 64px;
  left: 8px;
  right: 8px;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: var(--BRAND-CORE-Juisci-Black, #212121);
  box-shadow: 4px 4px 10px 0px rgba(33, 33, 33, 0.15);

  span {
    color: var(--BRAND-CORE-White, #FFF);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%; /* 15.6px */

    &.cancel {
      color: var(--secondarytangy-orange-2, #F80);
      font-family: Roboto;
      font-size: 13px;
      font-weight: 400;
      line-height: 12px; /* 92.308% */
    }
  }
`;
