import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Link, withRouter } from "react-router-dom";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import styled from "styled-components";
import Loader from "../../components/Loader";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavBack } from "../../components/app/headers/layout";
import { SpecialtyTag } from "../../components/global";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import {
  maxVideoViewed,
  renderPublicationDate,
} from "../../tools/utils";
import disableScroll from "disable-scroll";
import CustomIcon from "../../components/CustomIcon";
import { t } from "i18next";
import ArticleCard from "../../components/cards/ArticleCard";
import EditorialIcon from "../../assets/icons/card_editorial.svg";
import { getContentRelatedArticles } from "../../domains/content/endpoints/getContentRelatedArticles";
import { displayToast } from "../../components/app/AppToast";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import { store } from "../../redux";
import { setPlaylists, setUser } from "../../redux/user/user.reducer";
import { androidShare } from "../../tools/android";
import { isAndroid } from "react-device-detect";
import i18n from "../../config/i18n";
import { getContentFromSlug } from "../../domains/content/endpoints/getContentFromSlug";
import PlaylistSheet from "../../components/playlists/PlaylistSheet";
import { addContentToPlaylist } from "../../domains/playlist/endpoints/addContentToPlaylist";
import ShareSheet from "../../components/ShareSheet";
import { iosPlaySound } from "../../tools/ios";
import {
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
  SoundEffect,
  SupportedLanguagesEnum,
} from "../../interfaces";
import { saveContentToPlaylists } from "../../services/contentApi";
import {
  convertContentToItemData,
  gtmItemsData,
} from "../../tools/reactgaEvents";
import { incrementUserMetric } from "../../domains/user/endpoints/incrementUserMetric";
import ProfilePicture from "../../components/ProfilePicture";
import { getVideoArticlesList } from "../../domains/video/endpoints/getVideoArticlesList";
import SpecialtyBadges from "../../components/app/SpecialtyBadges";

const YOUTUBE_CONFIG = {
  playerVars: {
    showinfo: 0,
    modestbranding: 1,
    iv_load_policy: 3,
    rel: 0,
    wmode: "transparent",
    playsinline: 1,
  },
};

class VideoPage extends Component {
  navbarWrapperRef = React.createRef();
  videoPlayer = React.createRef();

  state = {
    video: undefined,
    showSave: false,
    relatedArticles: undefined,
    playing: false,
    isMuted: true,
    isLiked: false,
    isSaved: false,
    userSender: null,
  };

  async componentDidMount() {
    const isAuthentified = await isUserAuthentified();
    if (!isAuthentified && maxVideoViewed("article")) {
      localStorage.setItem("authRedirectPath", this.props.location.pathname);
      displayToast(t("error:notLoggedIn"));
      return this.props.history.replace("/login");
    }

    disableScroll.off();
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.listenScroll);

    if (!this.props.user.user) await this.props.getUser();

    if (!this.props.user.user.playlists || !this.props.user.user.saves) {
      await this.props.getPlaylists();
    } else {
      this.props.getPlaylists();
    }

    await getContentFromSlug(this.props.match.params.slug).then((video) => {
      this.setState(
        {
          video,
          isSaved:
            !!this.props.user.saves &&
            !!this.props.user.saves.find((videoId) => videoId === video._id),
          isLiked:
            !!this.props.user.user.likedContent &&
            !!this.props.user.user.likedContent.find(
              (el) => el._id === video._id
            ),
        },
        () => {
          setTimeout(() => {
            this.setState({ playing: !this.state.playing });
          }, 1000);
        }
      );

      // TODO: Missing catch.
      incrementUserMetric("contentViews").then(({ metrics }) => {
        store.dispatch(setUser({ ...this.props.user.user, metrics }));
      });

      gtmItemsData(
        ItemsDataEvent.VIDEO_PLAYED,
        convertContentToItemData(
          [video],
          ItemDataEventListName.VIDEO_ARTICLE_PAGE,
          ItemDataVariant.VIDEO_ARTICLE
        )
      );

      this.fetchRelatedArticles(video);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScroll);
  }

  listenScroll = () => {
    if (window.scrollY > 60) this.setState({ scrolled: true });
    else this.setState({ scrolled: false });
  };

  fetchRelatedArticles = async (video) => {
    const relatedArticles = await getContentRelatedArticles(
      video._id
    );
    this.setState({ relatedArticles });

    gtmItemsData(
      ItemsDataEvent.VIEW_ITEM_LIST,
      convertContentToItemData(
        relatedArticles,
        ItemDataEventListName.ARTICLE_PAGE,
        ItemDataVariant.ARTICLE
      )
    );
  };

  handleLike = async () => {
    const { isLiked, video } = this.state;

    gtmItemsData(
      ItemsDataEvent.VIDEO_LIKE,
      convertContentToItemData(
        [video],
        ItemDataEventListName.VIDEO_ARTICLE_PAGE,
        ItemDataVariant.VIDEO_ARTICLE
      )
    );

    const body = {
      slug: video.slug,
      action: isLiked ? "unlike" : "like",
    };

    this.setState({
      isLiked: !isLiked,
      video: {
        ...this.state.video,
        metrics: {
          ...this.state.video.metrics,
          likes: this.state.video.metrics.likes + (isLiked ? -1 : 1),
        },
      },
    });

    iosPlaySound(SoundEffect.LIKE);
    displayToast(
      isLiked ? t("you have unliked a video") : t("you have liked a video")
    );

    const { data } = await this.props.patchUserContent(body);
    store.dispatch(setUser(data[0]));

    await this.props.getVideoArticlesList({
      offset: 0,
      limit: 50,
      language: i18n.resolvedLanguage,
    })
      .unwrap()
      .catch((error) => {
        console.error("Couldn't update video articles.", error);
      });
  };

  handleShare = async () => {
    const { slug, title, journal, company, congress, publisher } =
      this.state.video;
    const url =
      window.location.origin +
      `/video/${slug}?shared=${this.props.user?.user?.uid}`;

    const publisherName =
      publisher === "journal"
        ? journal.name
        : publisher === "congress"
          ? congress.name
          : company.name;

    const shareText =
      i18n.resolvedLanguage === SupportedLanguagesEnum.FR
        ? `Je viens de regarder cette vidéo qui pourrait t'intéresser : ${title} par ${publisherName}`
        : `I’ve just watched this video you may be interested in: ${title} by ${publisherName}`;

    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.shareContent"),
    });
    if (isRedirected) return;

    if (isAndroid) {
      await this.props.patchUserContent({ slug, action: "share" });
      androidShare(url, shareText);
      gtmItemsData(
        ItemsDataEvent.VIDEO_SHARE,
        convertContentToItemData(
          [this.state.video],
          ItemDataEventListName.VIDEO_ARTICLE_PAGE,
          ItemDataVariant.VIDEO_ARTICLE
        )
      );
    } else if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: shareText,
          url,
        })
        .then(async () => {
          await this.props.patchUserContent({ slug, action: "share" });
          iosPlaySound(SoundEffect.SHARE);
          displayToast(t("you have shared a video"), "success");

          gtmItemsData(
            ItemsDataEvent.VIDEO_LIKE,
            convertContentToItemData(
              [this.state.video],
              ItemDataEventListName.VIDEO_ARTICLE_PAGE,
              ItemDataVariant.VIDEO_ARTICLE
            )
          );
        });
    } else {
      displayToast(t("toast.fail.browser"));
    }
  };

  addPlaylistSuccess = ({
    contentIsInPlaylist,
    playlistsToAdd,
    playlistsToRemove,
  }) => {
    const video = {
      ...this.state.video,
      metrics: {
        ...this.state.video.metrics,
        saveds:
          this.state.video.metrics.saveds +
          playlistsToAdd.length -
          playlistsToRemove.length,
      },
    };

    this.setState({ showSave: false, video, isSaved: contentIsInPlaylist });
  };

  confirmAddPlaylist = async (checkedPlaylists) => {
    const { contentIsInPlaylist, playlistsToAdd, playlistsToRemove } =
      saveContentToPlaylists(
        this.state.video,
        checkedPlaylists,
        ItemDataEventListName.VIDEO_ARTICLE_PAGE
      );

    this.addPlaylistSuccess({
      contentIsInPlaylist,
      playlistsToAdd,
      playlistsToRemove,
    });

    this.props.patchUserContent({
      slug: this.state.video.slug,
      action: playlistsToAdd.length > 0 ? "save" : "unsave",
    });
  };

  onConfirmCreatePlaylist = (newPlaylist) => {
    const playlist = {
      ...newPlaylist,
      playlistContent: [this.state.video._id],
    };

    store.dispatch(setPlaylists([playlist, ...this.props.user.playlists]));
    store.dispatch(
      setUser({
        ...this.props.user.user,
        savedContent: [
          ...this.props.user.user.savedContent,
          this.state.video._id,
        ],
      })
    );
    addContentToPlaylist(playlist._id, this.state.video._id);

    this.setState({
      isSaved: true,
      video: {
        ...this.state.video,
        metrics: {
          ...this.state.video.metrics,
          saveds: this.state.video.metrics.saveds + 1,
        },
      },
    });

    displayToast(t("create video playlist success"));
  };

  getVideoPublisherAvatar = () => {
    const { video } = this.state;

    switch (video.publisher) {
      case "journal":
        return {
          image: video.journal.image.url,
          path: `/journal/${video.journal.uid}`,
        };

      case "user":
        return {
          path: `/profile/user/${video.user?.uid}`,
          image: null,
        };

      case "congress":
        return {
          image: video.congress.image.url,
          path: null,
        };

      default:
        return {
          image: video.company.images[0].url,
          path: `/company/${video.company._id}`,
        };
    }
  };

  render() {
    const { video } = this.state;

    if (!video) return <Loader />;

    const videoPublisher = this.getVideoPublisherAvatar();

    return (
      <StyledContainer>
        {this.state.showSave && (
          <PlaylistSheet
            type='video'
            playlists={this.props.user?.playlists}
            showSheet={this.state.showSave}
            onClose={() => this.setState({ showSave: false })}
            article={video}
            gtmItemListName={ItemDataEventListName.VIDEO_ARTICLE_PAGE}
            getPlaylists={() => this.props.getPlaylists()}
            onConfirm={this.confirmAddPlaylist.bind(this)}
            onConfirmCreatePlaylist={this.onConfirmCreatePlaylist.bind(this)}
          />
        )}
        <ShareSheet />
        <div ref={this.navbarWrapperRef}>
          <HeaderBoxAutoSafe>
            <HeaderLinks><NavBack onClick={() => {
              !!this.props.location.key
                ? this.props.history.goBack()
                : this.props.history.replace("/discovery/videos?wasShared=true");
            }} /></HeaderLinks>
            <HeaderLogo />
            <HeaderLinks />
          </HeaderBoxAutoSafe>
        </div>

        <div
          className={`video-player ${this.state.scrolled ? "fixed" : ""} `}
          style={{
            top: this.state.scrolled
              ? this.navbarWrapperRef.current?.clientHeight
              : "initial",
          }}
        >
          <ReactPlayer
            width='100%'
            height='210px'
            url={video.sourceURL}
            config={{
              youtube: { ...YOUTUBE_CONFIG },
            }}
            playing={this.state.playing}
            onReady={(ready) => this.setState({ ready })}
            onStart={() => this.setState({ started: true })}
            onPlay={() => this.setState({ playing: true })}
            onPause={() => this.setState({ playing: false })}
            stopOnUnmount={true}
            playsinline={true}
          />
        </div>
        <div className='video-infos'>
          <div className='row-flex'>
            <div>
              <div className='publication-date'>
                {renderPublicationDate(video.publication_date)}
              </div>
              <SpecialtyBadges specialties={video.medical_specialties.slice(0, 2)} />
            </div>
            {
              <div
                className={video.publisher === "user" ? "publisher-avatar" : ""}
                onClick={() =>
                  videoPublisher.path &&
                  this.props.history.push(videoPublisher.path)
                }
              >
                {video.publisher === "user" ? (
                  <ProfilePicture height={56} user={video?.user} />
                ) : (
                  <img src={videoPublisher.image} alt='' />
                )}
              </div>
            }
          </div>

          <h3>{video.title}</h3>

          <div className='content-controllers'>
            <div
              className='controller'
              onClick={() => this.setState({ showSave: true })}
            >
              <IconWrapper
                style={{
                  paddingTop: "9px",
                  background: this.state.isSaved
                    ? "linear-gradient(180deg, #FFD000 0%, #FFA100 100%)"
                    : "#90A4AE",
                  transform: "scale(0.8)",
                  transition: "all ease-in-out 0.3s",
                }}
              >
                <CustomIcon iconName='marker' color='#fff' />
              </IconWrapper>
              <p>{video.metrics.saveds}</p>
            </div>

            <div className='controller' onClick={() => this.handleLike()}>
              <IconWrapper
                style={{
                  paddingTop: "9px",
                  background: this.state.isLiked ? "#ff8800" : "#90A4AE",
                  transform: "scale(0.8)",
                  transition: "all ease-in-out 0.3s",
                }}
              >
                <CustomIcon
                  iconName='heart'
                  scale={1.2}
                  color='#fff'
                  style={{ marginBottom: -5 }}
                />
              </IconWrapper>
              <p>{video.metrics.likes}</p>
            </div>

            <div className='controller' onClick={() => this.handleShare()}>
              <IconWrapper
                style={{
                  marginRight: "5px",
                  paddingTop: "9px",
                  background: "#90A4AE",
                  transform: "scale(0.8)",
                  transition: "all ease-in-out 0.3s",
                }}
              >
                {this.state.iconSize > 0 ? (
                  <CustomIcon
                    iconName='twitter'
                    scale={this.state.iconSize}
                    color='#fff'
                    style={{
                      marginBottom: -5,
                      transition: "all ease-in-out 0.3s",
                    }}
                  />
                ) : (
                  <CustomIcon scale={1.2} iconName='share_plane' color='#fff' />
                )}
              </IconWrapper>
              <p>{video.metrics.shares}</p>
            </div>
          </div>
        </div>
        {!!video.associatedArticles[0] && (
          <div className='associated-content'>
            <Link to={"/post/" + video.associatedArticles[0].slug}>
              <div className='edito-card'>
                <img src={EditorialIcon} className='editorial-icon' alt='' />

                <div className='card-content'>
                  <div>
                    <h5>{t("Associated article")}</h5>
                    <p>
                      {t("Discover the digested article linked to this video")}
                    </p>
                  </div>
                  <CustomIcon iconName='back-arrow' color2='#CE0868' />
                </div>
              </div>
            </Link>
          </div>
        )}

        <div className='related-content'>
          <div className='section-title'>
            <CustomIcon iconName='paperscroll' />
            <h3>{t("Recommended publications")}</h3>
          </div>
          <CardList>
            {!!this.state.relatedArticles ? (
              this.state.relatedArticles.map((article) => (
                <ArticleCard
                  key={article._id + "--relatedArticles"}
                  article={article}
                  gtmItemListName={ItemDataEventListName.ARTICLE_PAGE}
                />
              ))
            ) : (
              <Loader loaderOnly />
            )}
          </CardList>
        </div>
      </StyledContainer>
    );
  }
}

function mapState(state) {
  const user = state.user;
  const videos = state.videos;
  return {
    user,
    videos,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
  patchUserContent: userApi.endpoints.patchUserContent.initiate,
  getVideoArticlesList: getVideoArticlesList.initiate,
};
const connector = connect(mapState, mapDispatch);

export default connector(withRouter(VideoPage));

const StyledContainer = styled.div`
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #212121;
  }

  .video-player {
    z-index: 9 !important;
    position: relative;

    &.fixed {
      box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.2);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    img.thumbnail {
      width: 100%;
    }
  }

  .video-infos {
    background: #ecf0f5;
    padding: 32px 22px;

    .row-flex {
      display: flex;
      justify-content: space-between;
      align-items: start;

      .publisher-avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      img {
        max-width: 80px;
        max-height: 40px;
        object-fit: contain;
      }

      .publication-date {
        margin-bottom: 5px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.04em;
        color: #212121;
      }
    }
  }

  .content-controllers {
    display: flex;
    align-items: center;
    gap: 16px;

    .controller {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 115%;
        color: #90a4ae;
        margin: 0;
      }
    }
  }

  .related-content {
    padding: 16px 0px 32px;

    min-height: 100vh;
    background: linear-gradient(
      180deg,
      #ff8a00 0%,
      #fdb955 47.92%,
      #ffc408 100%
    );

    .section-title {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 32px 22px 16px;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: -0.00017em;
        color: #212121;
        margin: 0;
      }

      .--custom-icon {
        transform: scale(1.3) translateY(2px) !important;
      }
    }
  }

  .associated-content {
    background: #fff;
    padding: 32px 22px;

    .edito-card {
      position: relative;
      background: #fff3ce;
      border-radius: 8px;
      padding: 30px 24px;

      img.editorial-icon {
        position: absolute;
        top: -16px;
        left: 12px;
        height: 32px;
      }

      h5,
      p {
        font-family: "Inter";
        font-style: normal;
        color: #313b42;
        margin: 0;
      }

      h5 {
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 4px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
      }

      .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 64px;

        .--custom-icon {
          transform: rotate(180deg) scale(1.5) !important;
          width: 32px;
          height: 32px;
          margin-right: 6px;
        }
      }
    }
  }
`;

const IconWrapper = styled.div`
  padding: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardList = styled.div`
	padding: 0 8px calc(16px + var(--safe-area-bottom));
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
