import { SpecialtyModel } from "../../domains/app/app.types";
import styled from "styled-components";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";

export default function SpecialtyBadges({
  specialties,
  variant = "white",
}: {
  specialties: SpecialtyModel[] | [];
  variant?: "white" | "yellow";
}) {
  return (
    <>
      {specialties?.map((specialty: SpecialtyModel) => {
        return (
          <Badge variant={variant} key={specialty.uid}>
            {getItemTranslation(specialty)}
          </Badge>
        );
      })}
    </>
  );
}

const Badge = styled.div<{ variant?: "white" | "yellow" }>`
  width: max-content;
  border-radius: 10px;
  background: ${(props) => (props.variant === "white" ? "#FFF3CE" : "#FFFFFF")};
  display: flex;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #ff8800;
  font-family: Inter;
  font-size: 11px;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.001px;
`;
