import { userApi } from "../../../redux/user/user.service";
import { UserRoomModel } from "../user.types";

const api = userApi.injectEndpoints({
  endpoints: (build) => ({
    getUserRooms: build.query<UserRoomModel[], void>({
      query: () => {
        return "/user/rooms";
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getUserRooms,
} = api.endpoints;

export const {
  useLazyGetUserRoomsQuery,
} = api;
