import { ReactChildren, ReactNode, createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Socket, io } from "socket.io-client";
import { getFirebaseToken } from "../services/firebase";
import { SOCKET_URL } from "../config";

// NOTE: For documentation, see https://socket.io/fr/docs/v4/client-api.

interface ContextProps {
  socket: Socket | null;
}

const defaultValue: ContextProps = {
  socket: null,
};

export const SocketContext = createContext<ContextProps>(defaultValue);

export function SocketProvider({ children }: {
  children: ReactNode | ReactChildren;
}) {
  const location = useLocation();
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!socket) setupSocket();
    return () => {
      socket?.disconnect();
    }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/profile")) {
      socket?.connect();
    } else {
      socket?.disconnect();
    }
  }, [socket, location.pathname]);

  async function setupSocket() {
    try {
      const socket = io(
        SOCKET_URL as string,
        {
          auth: { token: await getFirebaseToken() },
          transports: ["websocket", "polling"],
          autoConnect: false,
        }
      );

      socket.on("connect", () => console.log("Socket connected."));
      socket.on("disconnect", (reason) => console.log("Socket Disconnected.", reason));
      socket.io.on("error", (error) => console.error("Socket error.", error));
      socket.onAny((eventName: string, ...args: any[]) => console.log("Socket onAny", eventName, args));

      socket.on("conversation/new", (payload) => console.log("conversation/new", payload));

      setSocket(socket);
    } catch (error) {
      console.error("Couldn't setup socket.", error);
    }
  }

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
}
