import { ReactNode } from "react";
import CustomIcon from "../CustomIcon";
import styled from "styled-components";

export default function SettingButton({
  children,
  className,
  iconName,
  iconScale,
  icon,
  onClick,
  inputChild,
}: {
  children?: ReactNode;
  className?: string;
  iconName?: string;
  iconScale?: number;
  icon?: ReactNode;
  onClick?: Function;
  inputChild?: ReactNode;
}) {
  return (
    <Container className={className} onClick={() => onClick && onClick()}>
      <div className='left-part'>
        {iconName ? (
          <CustomIcon
            color='#000000'
            iconName={iconName}
            scale={iconScale ?? 1}
          />
        ) : icon
          ? icon
          : null
        }
        <span>{children}</span>
      </div>
      {inputChild}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 12px 32px;
  box-sizing: border-box;
  width: 100%;

  .left-part {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  svg, svg path, svg circle {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #212121;
  }

  &.certified-approved {
    background-color: #FFF3CE;
    .left-part {
      svg path:nth-child(1) {
        fill: #FFC408;
      }
      svg path:nth-child(2) {
        fill: #212121;
        stroke: unset;
      }
    }
    svg path {
      stroke: #FFAA00;
    }
  }

  &.certified-pending, &.certified-rejected {
    background-color: #D2DCE2;

    .left-part svg path {
      fill: grey;
    }
  }

  &.certified-rejected {
    span {
      line-height: 22px;
    }
  }
`;