import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SpecialtyModel, TagModel } from "../../domains/app/app.types";
import { specialtiesApi } from "./specialties.service";

export interface SpecialtiesState {
  medical_specialties: SpecialtyModel[];
  tags: TagModel[];
  isUninitialized: boolean;
}

const initialState: SpecialtiesState = {
  medical_specialties: [],
  tags: [],
  isUninitialized: true,
};

export const slice = createSlice({
  name: "specialties",
  initialState,
  reducers: {
    setSpecialties: (state, action: PayloadAction<SpecialtiesState>) => {
      const { medical_specialties, tags } = action.payload;
      if (medical_specialties) state.medical_specialties = medical_specialties;
      if (tags) state.tags = tags;
      state.isUninitialized = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      specialtiesApi.endpoints.getMedicalSpecialties.matchFulfilled,
      (state, { payload }) => {
        state.medical_specialties = payload;
        state.isUninitialized = false;
      }
    );
    builder.addMatcher(
      specialtiesApi.endpoints.getTags.matchFulfilled,
      (state, { payload }) => {
        state.tags = payload;
        state.isUninitialized = false;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { setSpecialties } = slice.actions;

export default slice.reducer;
