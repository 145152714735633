import { store } from "../../../redux";
import { SearchContextEnum } from "../search.types";

// TODO: Redo this part (uses deprecated values)
export function gtmGetSearchResultsCount() {
  return 0;
  // const { selectedContextTab, searchResults } = store.getState().search;
  // if (selectedContextTab !== SearchContextEnum.ALL) {
  //   return searchResults[selectedContextTab]?.list?.length;
  // } else {
  //   return Object.keys(searchResults).reduce(
  //     (total, context) => total + searchResults[context]?.list?.length,
  //     0,
  //   );
  // }
}