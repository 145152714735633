import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../redux";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { DEFAULT_LANGUAGE, SELECTABLE_PUBLIC_LANGUAGES } from "../../../config/i18n";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../user/hooks/useCurrentUser";

// NOTE: Used outside of hook.
export function getAppLang() {
  return localStorage.getItem("appLang") as SupportedLanguagesEnum ?? DEFAULT_LANGUAGE;
}

// NOTE: Used outside of hook.
export function restoreAppLang() {
  const storedLang = localStorage.getItem("appLang");
  if (storedLang) {
    console.log("Restore language to:", storedLang);
    i18next.changeLanguage(storedLang as SupportedLanguagesEnum ?? DEFAULT_LANGUAGE);
  }
}

export function useAppLang() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const roomOrganisationUid = useAppSelector((state) => state.user.cache.currentRoom.data?.organisation?.uid ?? null);
  const appLang = i18n.resolvedLanguage as SupportedLanguagesEnum;
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const isInRoom = !!roomOrganisationUid || location.pathname.includes("/room");

    if (!!currentUser && !isInRoom) {
      const storedLang = localStorage.getItem("appLang") as SupportedLanguagesEnum;
      if (!!storedLang) {
        if (i18n.resolvedLanguage !== storedLang) {
          console.log("Load i18n language from store:", storedLang);
          setAppLangTemp(storedLang);
        }
      } else {
        // Initialize app lang if doesn't exist
        setAppLang(appLang);
      }
    }
  }, [i18n.resolvedLanguage]);

  function setAppLang(lang: SupportedLanguagesEnum) {
    if (!SELECTABLE_PUBLIC_LANGUAGES.includes(lang)) {
      lang = DEFAULT_LANGUAGE;
    }
    i18next.changeLanguage(lang);
    storeAppLang(lang);
  }

  // NOTE: Used in rooms.
  function setAppLangTemp(lang: SupportedLanguagesEnum) {
    i18next.changeLanguage(lang);
  }

  function storeAppLang(lang: SupportedLanguagesEnum) {
    localStorage.setItem("appLang", lang);
  }

  function t(key: string, options: any = {}) {
    // Use browser/device language when signing in/signing up
    if (["/login", "/signup"].some((pathRoot: string) => location.pathname.startsWith(pathRoot))) {
      return i18n.t(key, { lng: navigator.language }) as string;
    }

    // NOTE: This hardcoded implementation is temporary.
    const isUsingFormalFrench = roomOrganisationUid?.includes("takeda");
    if (isUsingFormalFrench && appLang === SupportedLanguagesEnum.FR) {
      return i18n.t(key, { lng: "yo" }) as string;
    }

    return i18n.t(key, options) as string;
  }

  return {
    appLang,
    setAppLang,
    setAppLangTemp,
    storeAppLang,
    restoreAppLang,
    t,
  };
}
