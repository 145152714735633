import { SupportedLanguagesEnum } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguagesEnum;
  medical_specialties?: string;
  congress?: string;
}

export async function getContentCongress(params: QueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/content/congress", { params });
    const { docs, meta } = res.data;
    return { docs, total: meta.total, meta };
  } catch (error) {
    throw error;
  }
}
