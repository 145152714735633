import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useLazyGetNectarsListQuery } from "../../domains/nectar/endpoints/getNectarsList";
import { getNectarBySlug } from "../../domains/nectar/endpoints/getNectarBySlug";
import { clearSwipeIndex, memorizeNectarsList, setSwipeIndex } from "../../domains/nectar/nectar.reducer";
import { useLikeNectar } from "../../domains/user/hooks/useLikeNectar";
import { useSaveNectar } from "../../domains/user/hooks/useSaveNectar";
import { useShareNectar } from "../../domains/user/hooks/useShareNectar";
import disableScroll from "disable-scroll";
import { HeaderBoxSimple, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import NectarHero from "../../components/nectar/NectarHero";
import Loader from "../../components/Loader";
import ArticleCard from "../../components/cards/ArticleCard";
import { displayToast } from "../../components/app/AppToast";
import styled from "styled-components";
import ContentMarkdown from "../../components/ContentMarkdown";
import SwipeDetector from "../../components/SwipeDetector";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import OptionsPanel from "../../components/app/OptionsPanel";
import PlaylistPanel from "../../components/app/PlaylistPanel";
import { ReactComponent as NectarDrop } from "../../assets/icons/nectar/nectar-drop.svg";
import { ReactComponent as OptionsIcon } from "../../assets/icons/share-btn.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as ArrowNavigation } from "../../assets/icons/nectar/arrow-navigation.svg";
import { ReactComponent as AnswerIcon } from "../../assets/icons/nectar/heading-answer.svg";
import { ReactComponent as SourcesIcon } from "../../assets/icons/nectar/heading-sources.svg";
import { ReactComponent as YellowBall } from "../../assets/images/nectar/yellow-ball.svg";
import { ReactComponent as GreenSpring } from "../../assets/images/nectar/green-spring.svg";

export default function NectarPage() {
  // Router
  const history = useHistory();
  const params = useParams<{ slug: string }>();
  // Store
  const dispatch = useAppDispatch();
  const cache = useAppSelector((state) => state.nectars.cache);
  const currentNectarIndex = useAppSelector((state) => state.nectars.currentNectarIndex);
  // State
  const [isLoading, setLoading] = useState(true);
  const [nectar, setNectar] = useState<NectarModel | null>(null);
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [isFlippingLeft, setFlippingLeft] = useState(false);
  const [isFlippingRight, setFlippingRight] = useState(false);
  // Query
  const [getNectarsList] = useLazyGetNectarsListQuery();
  // Custom
  const { appLang, t } = useAppLang();
  const { isLiked, toggleLike } = useLikeNectar(nectar);
  const { isSaved, toggleSaveToPlaylists } = useSaveNectar(nectar);
  const shareNectar = useShareNectar(nectar);
  // Refs
  const answerTitleRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off(); // Enable Scroll
        scrollTo(0, 0);

        const { isRedirected } = await preflightUser({ history, authOnly: true });
        if (isRedirected) return;

        let cachedNectar: NectarModel | null = null;
        if (currentNectarIndex !== -1 && cache.list[currentNectarIndex]) {
          cachedNectar = cache.list[currentNectarIndex];
          if (cachedNectar) {
            setNectar(cachedNectar);
            setTimeout(() => {
              setLoading(false);
            }, 100); // NOTE: Let animation play when flipping pages.
          }
        }

        if (!cachedNectar || !cachedNectar?.associatedArticles || cachedNectar?.associatedArticles.some((item: any) => {
          return typeof item == "string";
        })) {
          const fetchedNectar = await getNectarBySlug(params.slug);
          setNectar(fetchedNectar);
          setLoading(false);
        }
      } catch (error) {
        console.error("Couldn't mount.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();
  }, [params.slug]);

  useEffect(() => {
    const flipTimeout = setTimeout(() => {
      setFlippingLeft(false);
      setFlippingRight(false);
    }, 500);
    return () => clearTimeout(flipTimeout);
  }, [isFlippingLeft, isFlippingRight]);

  function handleNavigatePrevious() {
    if (!isOptionsPanelVisible && currentNectarIndex !== -1 && currentNectarIndex > 0 && !isFlippingRight) {
      const prevNectar = cache.list[currentNectarIndex - 1];
      history.replace(`/nectar/${prevNectar.slug}`);
      dispatch(setSwipeIndex(currentNectarIndex - 1));
      setFlippingRight(true);
    }
  }

  function handleNavigateNext() {
    if (!isOptionsPanelVisible && currentNectarIndex !== -1 && currentNectarIndex < cache.list.length - 1 && !isFlippingLeft) {
      const nextNectar = cache.list[currentNectarIndex + 1];
      history.replace(`/nectar/${nextNectar.slug}`);
      dispatch(setSwipeIndex(currentNectarIndex + 1));
      setFlippingLeft(true);

      // Fetch remaining nectars when navigating
      if (cache.list.length < cache.total) {
        getNectarsList({
          limit: 50,
          offset: cache.list.length,
          language: appLang,
        })
          .unwrap()
          .then(({ docs, total }) => {
            const newList = [...cache.list, ...docs];
            dispatch(memorizeNectarsList({ list: newList, total }));
          })
          .catch((error) => {
            console.error("Couldn't get nectars.", error);
          });
      }
    }
  }

  if (isLoading) return <Loader />;
  if (!nectar) return null;

  return (
    <>
      <FlipCard style={{
        display: (isFlippingLeft || isFlippingRight) ? "flex" : "none",
        animation: isFlippingLeft
          ? "flipCardLeft 0.5s ease-in forwards"
          : isFlippingRight
            ? "flipCardRight 0.5s ease-in forwards"
            : "unset",
      }} />

      <SwipeDetector
        onSwipeLeft={handleNavigateNext}
        onSwipeRight={handleNavigatePrevious}
      >
        <PageContainer>
          <div className="header-wrapper">
            <SafeAreaTopWrapper>
              <HeaderBoxSimple>
                <HeaderLinks>
                  <NavBack onClick={() => {
                    history.goBack();
                    dispatch(clearSwipeIndex());
                  }} />
                </HeaderLinks>
                <HeaderTitle>
                  <span className="page-title">
                    <NectarDrop />{t("nectars.title")}
                  </span>
                </HeaderTitle>
                <HeaderLinks>
                  {/* TODO: replace with nav option button */}
                  <OptionsIcon
                    onClick={() => setOptionsPanelVisible(true)}
                  />
                </HeaderLinks>
              </HeaderBoxSimple>

              {currentNectarIndex !== -1 && (
                <div className="nav-links">
                  {currentNectarIndex > 0 && (
                    <span className="link previous" onClick={handleNavigatePrevious}>
                      <Arrow />
                      {t("nectars.readPrevious")}
                    </span>
                  )}
                  {currentNectarIndex < cache.list.length - 1 && (
                    <span className="link next" onClick={handleNavigateNext}>
                      {t("nectars.readNext")}
                      <Arrow />
                    </span>
                  )}
                </div>
              )}
            </SafeAreaTopWrapper>
          </div>

          <div className="page-hero">
            <NectarHero
              nectar={nectar}
              styles={{
                container: {
                  background: "none",
                  boxShadow: "none",
                  textAlign: "center",
                  padding: 16,
                  gap: 8,
                },
                title: {
                  fontSize: 24,
                  lineHeight: "24px",
                },
              }}
            />

            <ArrowNavigation
              onClick={() => {
                if (answerTitleRef.current) {
                  window.scrollTo({
                    top: answerTitleRef.current?.offsetTop - 100,
                    behavior: "smooth",
                  });
                }
              }}
              style={{
                position: "absolute",
                bottom: "-16px",
                alignSelf: "center",
              }}
            />
          </div>

          <div className="page-content">
            <h2 ref={answerTitleRef}>
              <AnswerIcon />
              {t("nectars.answer")}
              <YellowBall className="answer-bg-pic" />
            </h2>

            <ContentMarkdown className="answer" children={nectar?.answer} />

            {nectar.associatedArticles?.length ? (
              <>
                <h2>
                  <SourcesIcon />
                  {t("nectars.sources")} ({nectar.associatedArticles.length})
                  <GreenSpring className="sources-bg-pic" />
                </h2>

                <CardList>
                  {nectar.associatedArticles.map((article, index) => {
                    // NOTE: Prevent crash with corrupted data. To be removed.
                    if (typeof article === "string") return;
                    return (
                      <ArticleCard
                        key={index}
                        article={article}
                      />
                    );
                  })}
                </CardList>
              </>
            ) : <Loader loaderOnly />}
          </div>

          <OptionsPanel
            isOpen={isOptionsPanelVisible}
            onDismiss={() => setOptionsPanelVisible(false)}
            isContentSaved={isSaved}
            isContentLiked={isLiked}
            onSaveContent={() => setPlaylistsPanelVisible(true)}
            onLikeContent={toggleLike}
            onShareContent={shareNectar}
          />

          <PlaylistPanel
            isVisible={isPlaylistsPanelVisible}
            contentToAdd={nectar}
            onClose={() => setPlaylistsPanelVisible(false)}
            onSave={async ({ add, remove }: { add: string[]; remove: string[] }) => {
              await toggleSaveToPlaylists({ add, remove });
            }}
          />
        </PageContainer>
      </SwipeDetector>
    </>
  );
}

const PageContainer = styled.div`
  min-height: 100dvh;

  .header-wrapper {
    background-color: white;
  }

  .page-title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 17.6px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .nav-links {
    position: relative;
    z-index: 1;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eef822;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px;

    .link {
      display: flex;
      align-items: center;
      gap: 6px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;

      svg {
        width: 16px;
        height: 16px; 
 
        path {
          fill: #212121;
        }
      }

      &.previous {
        margin-right: auto;
      }
  
      &.next {
        margin-left: auto;
        svg { transform: scaleX(-1); }
      }
    }
  }

  .page-hero {
    position: relative;
    padding: 22px 0 22px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(
      180deg,
      #ffffff 33.85%,
      rgba(254, 235, 169, 0.73) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  }

  .page-content {
    overflow: hidden;
    padding: 0 12px;

    h2 {
      position: relative;
      margin: 60px 0 22px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: Inter;
      font-size: 30px;
      font-weight: 900;
      line-height: 30px;
      color: #212121;

      .answer-bg-pic {
        position: absolute;
        top: -18px;
        right: -42px;
        transform: rotate(6deg);
      }

      .sources-bg-pic {
        position: absolute;
        top: -48px;
        right: -42px;
      }
    }

    .answer {
      position: relative;
      padding: 30px 20px;
      border-radius: 16px;
      gap: 10px;
      background-color: white;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      z-index: 1;

      *:first-child {
        margin-top: 0;
      }
      *:last-child {
        margin-bottom: 0;
      }

      p::before {
        content: "•";
        color: #f42cab;
        margin-right: 6px;
        font-size: 24px;
        vertical-align: top;
      }
      
      a {
        color: #CE0868;
      }
    }
  }
`;

const CardList = styled.div`
  padding: 0 8px calc(16px + var(--safe-area-bottom));
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FlipCard = styled.div`
  position: absolute;
  top: 15dvh;
  left: 5dvw;
  z-index: 10;
  width: 90dvw;
  height: 80dvh;
  border-radius: 22px;
  background-image: linear-gradient(
    180deg,
    #ffffff 33.85%,
    rgba(254, 235, 169, 0.73) 100%
  ),
  linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px;
  transform-origin: 50% 80%;
  opacity: 0.5;

  @keyframes flipCardLeft {
    0% {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: translate(-100%, 20px) rotate(-35deg);
      opacity: 0;
    }
  }

  @keyframes flipCardRight {
    0% {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: translate(100%, 20px) rotate(35deg);
      opacity: 0;
    }
  }
`;