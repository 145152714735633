import { getApiRequestService } from "../../../services/index.services";

export async function synthesizeArticleSpeech(slug: string, language: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(
      `/speech/synthesize?language=${language}`,
      { slug }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}