import {
  CSSProperties,
  MouseEvent,
  ReactChildren,
  ReactNode,
  SyntheticEvent,
} from "react";
import { SpecialtyModel } from "../../domains/app/app.types";
import { renderPublicationDate } from "../../tools/utils";
import ClampLines from "../app/ClampLines";
import SpecialtyBadges from "../app/SpecialtyBadges";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as HeartBlackIcon } from "../../assets/icons/heart-circle-black.svg";
import { ReactComponent as HeartRedIcon } from "../../assets/icons/heart-circle-red.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/dots-circle-black.svg";
import { ReactComponent as JournalIcon } from "../../assets/icons/journal.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil.svg";
import { ReactComponent as NectarLabelIcon } from "../../assets/icons/nectar-label.svg";
import { ReactComponent as ArticleLabelIcon } from "../../assets/icons/article-label.svg";
import { ReactComponent as PlaylistLabelIcon } from "../../assets/icons/playlist-label.svg";
import { ReactComponent as VideoLabelIcon } from "../../assets/icons/video-label.svg";
import { ReactComponent as PodcastLabelIcon } from "../../assets/icons/podcast-label.svg";
import { ReactComponent as InfographicLabelIcon } from "../../assets/icons/infographic-label.svg";
import { ReactComponent as JuisciGPTLabelIcon } from "../../assets/icons/juisciGPT-label.svg";
import { ReactComponent as JuisciMainAvatar } from "../../assets/icons/juisci-main-avatar.svg";

export function CardContainer({
  children,
  variant,
  style,
  backgroundUrl,
  onClick,
}: {
  children: ReactNode | ReactChildren;
  variant?: "white" | "yellow" | "dark";
  style?: CSSProperties;
  backgroundUrl?: string;
  onClick?: Function;
}) {
  return (
    <OuterContainer
      style={style}
      onClick={(e: SyntheticEvent) => onClick && onClick(e)}
    >
      {backgroundUrl && <BlurredThumbnail src={backgroundUrl} alt="" />}
      <ColorLayer className={variant} />
      <CardContentContainer className={variant}>
        {children}
      </CardContentContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  flex: auto;
  min-height: 280px; // CHECK: test height or change to max-height
  border-radius: 15px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  display: flex;
  overflow: hidden;
`;

const ColorLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  background: #ffffff;

  &.yellow {
    background: #fff3ce;
  }

  &.dark {
    /* background: #36444b6c; */
    /* background: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985); */
    background-image: linear-gradient(to right, #36444b, #617985);
    mix-blend-mode: multiply;
  }
`;

const CardContentContainer = styled.div`
  --card-primary-color: #212121;
  --card-secondary-color: #ce0868;
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  color: var(--card-primary-color);

  &.yellow {
    .specialty-badge {
      background: var(--BRAND-CORE-White, #fff);
      color: var(--secondarytangy-orange-2, #f80);
    }
  }

  &.dark {
    color: #ffffff;
    --card-primary-color: #ffffff;
    --card-secondary-color: #fae6f0;
  }

  .card-date {
    text-align: left; // For carousel
    font-family: Inter;
    font-size: 11px;
  }
`;

const BlurredThumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  object-fit: cover;
`;

export function CardDate({ date }: { date: Date | string | undefined }) {
  const dateObject = typeof date === "string" ? new Date(date) : date;
  return <span className="card-date">{renderPublicationDate(dateObject)}</span>;
}

// TODO: Move icon + text to component
const labelConfig = {
  article: { Icon: ArticleLabelIcon, text: "Article" },
  nectar: { Icon: NectarLabelIcon, text: "Nectar" },
  playlist: { Icon: PlaylistLabelIcon, text: "Playlist" },
  video: { Icon: VideoLabelIcon, text: "Video" },
  podcast: { Icon: PodcastLabelIcon, text: "Podcast" },
  infographic: { Icon: InfographicLabelIcon, text: "Infographic" },
} as const;

type LabelType = keyof typeof labelConfig;

export function ContentLabel({ type }: { type: LabelType }) {
  const { Icon, text } = labelConfig[type];
  return (
    <ContentBadge>
      <Icon />
      {text}
    </ContentBadge>
  );
}

const ContentBadge = styled.div`
  border-radius: 10px;
  background: var(--SECONDARY-SOFT-Soft-Raspberry-2, #fae6f0);
  display: flex;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--secondarytangy-raspberry-1, #ce0868);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.001px;
`;

export function JuisciGPTLabel() {
  return (
    <div
      style={{
        height: "18px",
        width: "max-content",
        display: "flex",
        padding: "1px 8px",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        borderRadius: "10px",
        backgroundColor: "#EEF822",
        fontFamily: "Inter",
        fontSize: "11px",
        fontWeight: "400",
        color: "#212121",
      }}
    >
      {t("juisciGpt:poweredBy")}
      <JuisciGPTLabelIcon />
    </div>
  );
}

// TODO: undefined is present due to videos not having the same SpecialtyModel
export function CardTags({
  tags,
  contentLabel,
  variant,
}: {
  tags: SpecialtyModel[] | undefined;
  contentLabel?: LabelType;
  variant?: "white" | "yellow";
}) {
  if (!tags || tags.length === 0) return null;

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {contentLabel && <ContentLabel type={contentLabel} />}
      <SpecialtyBadges variant={variant} specialties={tags?.slice(0, 3)} />
    </div>
  );
}

// TODO: separate each buttons into individual action components
export function CardActions({
  isLiked,
  onLike,
  onOptions,
}: {
  isLiked: boolean;
  onLike: (e: MouseEvent) => void;
  onOptions: (e: MouseEvent) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
      }}
    >
      <IconContainer onClick={onLike}>
        {isLiked ? <HeartRedIcon /> : <HeartBlackIcon />}
      </IconContainer>
      <IconContainer onClick={onOptions}>
        <DotsIcon />
      </IconContainer>
    </div>
  );
}

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid #d2dce2;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardType = styled.div`
  text-align: left; // For carousel
  color: var(--card-secondary-color);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const CardTitle = styled(ClampLines)`
  text-align: left; // For carousel
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.32px;
  margin: 0;
`;

export function CardJournal({
  journalLogo,
  journalName,
  onClick,
}: {
  journalLogo: string;
  journalName: string;
  onClick?: Function;
}) {
  return (
    <FooterItemContainer onClick={e => onClick && onClick(e)}>
      <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
        <JournalIcon />
        <FooterItemTitle>{t("Journal")}</FooterItemTitle>
      </div>
      {journalLogo ? (
        <div
          style={{
            display: "inline-block",
            maxWidth: "120px",
          }}
        >
          <img
            style={{
              display: "block",
              objectFit: "contain",
              width: "auto",
              height: "40px",
              maxWidth: "100%",
            }}
            src={journalLogo}
            alt={journalName}
          />
        </div>
      ) : (
        <span>{journalName}</span>
      )}
    </FooterItemContainer>
  );
}

export function CardAuthor({
  primaryAuthor,
  authors,
}: {
  primaryAuthor?: string;
  authors: string[];
}) {
  return (
    <FooterItemContainer>
      <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
        <PencilIcon />
        <FooterItemTitle>{t("Authors")}</FooterItemTitle>
      </div>
      <span
        style={{
          color: "#212121",
          fontFamily: "Inter",
          fontSize: "11px",
          fontWeight: "500",
          lineHeight: "120%",
          textAlign: "left", // For carousel
        }}
      >
        {primaryAuthor} <br />
        {!!authors && !!authors.length
          ? t("varOtherAuthors", { authorsLength: authors?.length ?? 0 })
          : ""}
      </span>
    </FooterItemContainer>
  );
}

const FooterItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FooterItemTitle = styled.span`
  color: #212121;
  font-family: "Inter";
  font-size: 12px;
`;

// CHECK: acceptable name?
export function CondensedCardFooter({
  companyLogo,
  companyName,
  companyMetrics,
  className,
}: {
  companyLogo: string | undefined;
  companyName?: string | undefined; // TODO: type this properly
  companyMetrics?: string | React.ReactNode;
  className?: string;
}) {
  return (
    <div style={{ display: "flex", gap: "6px" }}>
      {companyLogo ? (
        <CompanyLogo>
          <img src={companyLogo} />
        </CompanyLogo>
      ) : (
        <JuisciMainAvatar />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            color: className === "dark" ? "#FFFFFF" : "#212121",
            fontSize: "11px",
            fontWeight: "500",
            textAlign: "left", // For carousel
          }}
        >
          {companyName}
        </span>
        <span
          style={{
            color: className === "dark" ? "#FFFFFF" : "#617985",
            fontSize: "10px",
            fontWeight: "500",
            textAlign: "left", // For carousel
          }}
        >
          {companyMetrics}
        </span>
      </div>
    </div>
  );
}

const CompanyLogo = styled.div`
  height: 30px;
  width: 30px !important; // For carousel
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  background: #fff;
  padding: 3px;
  border: 1px solid #d2dce2;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    background: transparent;
  }
`;
