import BG1 from "../../../assets/images/podcast-bg/podcast-bg-1.png";
import BG2 from "../../../assets/images/podcast-bg/podcast-bg-2.png";
import BG3 from "../../../assets/images/podcast-bg/podcast-bg-3.png";
import BG4 from "../../../assets/images/podcast-bg/podcast-bg-4.png";
import BG5 from "../../../assets/images/podcast-bg/podcast-bg-5.png";
import BG6 from "../../../assets/images/podcast-bg/podcast-bg-6.png";

const backgroundImages = [BG1, BG2, BG3, BG4, BG5, BG6];

export function getPodcastBackground(podcastId: string): string {
  const lastSixChars = podcastId.slice(-6);
  const index = parseInt(lastSixChars, 16) % backgroundImages.length;
  return backgroundImages[index];
}
