import { getApiRequestService } from "../../../services/index.services";
import { SupportedLanguagesEnum } from "../../../interfaces";

export async function getMagicLink({ email, language }: {
  email: string;
  language: SupportedLanguagesEnum;
}) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/auth/magic-link", {
      email,
      language,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
}
