import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import { SpecialtyModel } from "../../../domains/app/app.types";
import { PlaylistModel } from "../../../domains/playlist/playlist.types";
import { useLazyGetPublicPlaylistsQuery } from "../../../domains/playlist/endpoints/getPublicPlaylists";
import { useLazyGetKOLPlaylistsQuery } from "../../../domains/playlist/endpoints/getKOLPlaylists";
import styled from "styled-components";
import { displayToast } from "../../../components/app/AppToast";
import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import PlaylistCard from "../../../components/playlists/PlaylistCard";
import { convertContentToItemData, gtmItemsData } from "../../../tools/reactgaEvents";
import { ItemDataEventListName, ItemDataVariant, ItemsDataEvent, SupportedLanguagesEnum } from "../../../interfaces";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavLang } from "../../../components/app/headers/layout";
import BottomNavbar from "../../../components/app/BottomNavbar";
import HomeContentFilters from "../../../components/home/layout/HomeContentFilters";
import FiltersDrawer from "../../../components/filters/FiltersDrawer";
import disableScroll from "disable-scroll";
import ItemList from "../../../components/app/ItemList";

export default function HomeContentPlaylistsPage() {
  const location = useLocation();
  const { appLang, t } = useAppLang();
  const [isLoading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [totalPlaylists, setTotalPlaylists] = useState(0);
  const [specialtyFilters, setSpecialtyFilters] = useState<[] | null>(null);
  const [isFilterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [getPublicPlaylists] = useLazyGetPublicPlaylistsQuery();
  const [getKOLPlaylists] = useLazyGetKOLPlaylistsQuery();

  useEffect(() => {
    if (specialtyFilters !== null) {
      loadPlaylists(true);
    }
  }, [specialtyFilters]);

  useEffect(() => {
    scrollTo(0, 0);
    disableScroll.off();
    loadFilters();
    loadPlaylists(true);
  }, [appLang]);

  useEffect(() => {
    gtmItemsData(
      ItemsDataEvent.VIEW_ITEM_LIST,
      convertContentToItemData(
        playlists,
        ItemDataEventListName.HOME,
        ItemDataVariant.PLAYLIST,
        true
      )
    );
  }, [playlists]);

  async function loadFilters() {
    try {
      if (localStorage.getItem("homeListContentFilters")) {
        const { medical_specialties } = JSON.parse(
          localStorage.getItem("homeListContentFilters") as string
        );
        setSpecialtyFilters(medical_specialties);
      } else {
        setSpecialtyFilters([]);
      }
    } catch (error) {
      console.error("Couldn't load filters.", error);
    }
  }

  async function loadPlaylists(resetList = false) {
    try {
      const params: {
        limit: number;
        offset: number;
        language: SupportedLanguagesEnum;
        medicalSpecialties?: string;
      } = {
        limit: 10,
        offset: resetList ? 0 : playlists.length,
        language: appLang,
      };

      if (specialtyFilters?.length) {
        params.medicalSpecialties = specialtyFilters.map((spe: SpecialtyModel) => spe._id).join(",");
      }

      const searchParams = new URLSearchParams(location.search);

      if (searchParams.get("kol") === "true") {
        const { docs, meta } = await getKOLPlaylists(params).unwrap();
        const newList = resetList ? docs : [...playlists, ...docs];
        setPlaylists(newList);
        setTotalPlaylists(meta.total);
      } else {
        const { docs, meta } = await getPublicPlaylists(params).unwrap();
        const newList = resetList ? docs : [...playlists, ...docs];
        setPlaylists(newList);
        setTotalPlaylists(meta.total);
      }

      setLoading(false);
    } catch (error) {
      console.error("Couldn't fetch content.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters(filters: { medical_specialties: [] }) {
    localStorage.setItem("homeListContentFilters", JSON.stringify(filters));
    setSpecialtyFilters(filters.medical_specialties);
  }

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>{t("Playlists")}</HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <HomeContentFilters
          active={!!specialtyFilters?.length}
          onClick={() => setFilterDrawerVisible(true)}
        />

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <Loader loaderOnly />
          ) : !playlists.length ? (
            <NoResult>{t("No content")}</NoResult>
          ) : (
            <div className='page-content'>
              <InfiniteScroll
                dataLength={playlists.length}
                next={loadPlaylists}
                hasMore={playlists.length < totalPlaylists}
                loader={null}
              >
                <ItemList>
                  {playlists.map((playlist: PlaylistModel) => (
                    <PlaylistCard
                      key={playlist._id + "pagecontentkey"}
                      playlist={playlist}
                    />
                  ))}
                </ItemList>
              </InfiniteScroll>
            </div>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      <FiltersDrawer
        showDrawer={isFilterDrawerVisible}
        onClose={() => setFilterDrawerVisible(false)}
        medical_specialties={specialtyFilters}
        handleFilters={(filters: any) => {
          handleChangeFilters(filters);
          setFilterDrawerVisible(false);
        }}
        hideTags
      />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #fae6f0;
`;

const NoResult = styled.div`
  padding: 8px 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  color: #212121;
`;
