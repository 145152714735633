import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { ArticleModel } from "../../domains/article/article.types";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { ItemDataEventListName } from "../../interfaces";
import { useLazyGetArticlesListQuery } from "../../domains/article/endpoints/getArticlesList";
import { useRoomPreflightTemp } from "../../domains/room/hooks/useRoomPreflightTemp";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonContentCard from "../../components/profile/SkeletonContentCard";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import { HeaderBoxSimple, HeaderLinks, HeaderLogoTitleTagline, NavHome, NavLang, NavOptions } from "../../components/app/headers/layout";
import { RoomListHeading, RoomListWrapper, RoomPageTab, RoomPageWrapper, RoomTabSwitcher } from "../../components/room/layout";
import HomeNectarScroller from "../../components/home/home-blocks/HomeNectarScroller";
import ArticleCard from "../../components/cards/ArticleCard";
import { useLazyGetPublicPlaylistsQuery } from "../../domains/playlist/endpoints/getPublicPlaylists";
import MiniPlaylistScroller from "../../components/app/MiniPlaylistScroller";
import styled from "styled-components";
import { BottomSheet } from "react-spring-bottom-sheet";
import { SheetWrapper } from "../../components/CustomSheet";
import { PanelContainer, PanelRow } from "../../components/app/panels/layout";
import CustomIcon from "../../components/CustomIcon";
import { ReactComponent as FeedIcon } from "../../assets/icons/feed-white.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle-yellow.svg";

// TODO: Header: NavOptions: to do what?

export default function RoomDiscoveryPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { appLang, t } = useAppLang();
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const [isBottomPanelVisible, setBottomPanelVisible] = useState(false);
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  const [articles, setArticles] = useState<ArticleModel[]>([]);
  // NOTE: Using state here instead of RTK Query state to prevent glitch before first fetch.
  const [total, setTotal] = useState(0);
  const [getPublicPlaylists, {
    isLoading: isLoadingPlaylists,
  }] = useLazyGetPublicPlaylistsQuery();
  const [getArticlesList, {
    isUninitialized: isUninitializedArticles,
    isFetching: isFetchingArticles,
  }] = useLazyGetArticlesListQuery();
  const { isLoadingRoom, roomData, preflightUserRoom } = useRoomPreflightTemp(roomId);

  // TODO: cache articles

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentRoomId) {
      scrollTo(0, 0);
      loadPlaylists();
      loadArticles(true);
    }
  }, [currentRoomId, appLang]);

  async function loadPlaylists() {
    try {
      getPublicPlaylists({
        limit: 20,
        offset: 0,
        language: appLang,
      })
        .unwrap()
        .then(({ docs }) => {
          setPlaylists(docs);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load playlists.", error);
    }
  }

  async function loadArticles(resetList = false) {
    try {
      getArticlesList({
        limit: 20,
        offset: resetList ? 0 : articles.length,
        language: appLang,
      })
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...articles, ...docs];
          setArticles(newList);
          setTotal(total);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load articles.", error);
    }
  }

  if (isLoadingRoom) return <Loader />;

  return (
    <>
      <Wrapper style={{ backgroundColor: roomData?.primaryColor ?? "" }}>
        <SafeAreaTopWrapper>
          <HeaderBoxSimple>
            <HeaderLinks><NavHome /></HeaderLinks>
            <HeaderLogoTitleTagline
              logo={roomData?.organisation?.logo?.url || ''}
              title={roomData?.name || ''}
              tagline={roomData?.organisation.name || ''}
            />
            <HeaderLinks>
              <NavLang />
              <NavOptions onClick={() => setBottomPanelVisible(true)} />
            </HeaderLinks>
          </HeaderBoxSimple>

          <RoomTabSwitcher activeTab={RoomPageTab.DISCOVERY} />
        </SafeAreaTopWrapper>

        {/* TODO: Make new component for rooms. */}
        {/* <HomeNectarScroller /> */}

        {/* TODO: Localize + Link see all */}
        <MiniPlaylistScroller
          title={t("room:discovery.playlists")}
          linkTo="#"
          playlists={playlists}
          isLoading={isLoadingPlaylists}
        />

        <RoomListHeading><FeedIcon />{t("room:discovery.feed")}</RoomListHeading>

        {(isUninitializedArticles || isFetchingArticles) ? (
          <FadeIn key="loading">
            <RoomListWrapper>
              {[...Array(10)].map((_, index) => <SkeletonContentCard key={index} />)}
            </RoomListWrapper>
          </FadeIn>
        ) : (
          <FadeIn key="content">
            <InfiniteScroll
              dataLength={articles.length}
              next={() => loadArticles()}
              hasMore={articles.length < total}
              pullDownToRefresh={false}
              loader={null}
            >
              <RoomListWrapper>
                {!articles.length && (
                  <span className="no-results">{t("room:discovery.noResults")}</span>
                )}
                {articles.map((article) => {
                  return (
                    <ArticleCard
                      key={article._id}
                      article={article}
                      // TODO: Change.
                      gtmItemListName={ItemDataEventListName.DISCOVERY_MAIN_CAROUSEL}
                    />
                  );
                })}
              </RoomListWrapper>
            </InfiniteScroll>
          </FadeIn>
        )}
      </Wrapper>

      <BottomSheet
        expandOnContentDrag
        open={isBottomPanelVisible}
        onDismiss={() =>
          setTimeout(() => setBottomPanelVisible(false), 100)
        }
      >
        <SheetWrapper style={{ height: window.innerHeight * 0.3 }}>
          <PanelContainer>
            {/* TODO: QR Code page */}
            {/* <PanelRow onClick={() => null}>
              <CustomIcon iconName='share-alt' color='#212121' />
              <div className="text">
                <span className="label">{t("Share room")}</span>
                <span className="description">{t("Share this room to other people")}</span>
              </div>
            </PanelRow> */}
            <PanelRow onClick={() => history.push(`/room/terms-privacy/${roomId}`)}>
              <InfoIcon />
              <div className="text">
                <span className="label">{t("room:panel.information.label")}</span>
                <span className="description">{t("room:panel.information.description")}</span>
              </div>
            </PanelRow>
          </PanelContainer>
        </SheetWrapper>
      </BottomSheet>
    </>
  );
}

const Wrapper = styled(RoomPageWrapper)`
  .mini-playlist-scroller-container {
    background-color: white;
  }
`;
