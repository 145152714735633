import styled from "styled-components";
import { ReactComponent as SearchFilterIcon } from "../../assets/icons/filter-icon.svg";

export default function FilterIconButton({ active, label, onClick }: {
  active: boolean;
  label?: string;
  onClick?: Function;
}) {
  return (
    <StyledContainer>
      <StyledButton className={active ? "active" : ""} onClick={() => onClick && onClick()}>
        <SearchFilterIcon />
      </StyledButton>
      {label && <label>{label}</label>}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;
  }
`;

const StyledButton = styled.button`
  width: fit-content;
  padding: 3px 14px;
  border-radius: 20px;
  border: solid 2px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;

  &.active {
    background-color: #CE0868;
    border-color: white;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: white;
    }
  }
`;
