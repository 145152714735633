import { useEffect } from "react";
import { SupportedLanguagesEnum } from "./interfaces";
import { InlineWidget } from "react-calendly";
import disableScroll from "disable-scroll";
import styled from "styled-components";
import i18n from "./config/i18n";
import { t } from "i18next";
import BrowserBG from "./assets/images/desktop-page/juisci-pattern.svg";
import JuisciLogo from "./assets/core/logo-with-tagline.svg";
import MobileJuisciEN from "./assets/images/desktop-page/juisci-phone-eng.svg";
import MobileJuisciFR from "./assets/images/desktop-page/juisci-phone-fr.svg";
import DashboardJuisci from "./assets/images/browser-page-dashboard.png";

// NOTE: Translation files take time to load.
// These texts are hardcoded to be displayed instantly.
const LANG_TEXTS: { [key: string]: any } = {
  oopsMessage: {
    [SupportedLanguagesEnum.EN]: "😬 Oops! You are connected to the mobile app from a computer.",
    [SupportedLanguagesEnum.FR]: "😬 Oups ! Vous êtes connecté sur ordinateur à l’appli mobile.",
  },
  title: {
    [SupportedLanguagesEnum.EN]: <>🍋 The Free Juisci App is a mobile-<br />first experience to enjoy your <span>JUI</span>ce
      <br /> of <span>SCI</span>ence on-the-go.</>,
    [SupportedLanguagesEnum.FR]: `🍋 La version gratuite de Juisci est disponible uniquement sur mobile 
      pour savourer son Jus de Science en toute mobilité.`,
  },
  needBiggerScreen: {
    [SupportedLanguagesEnum.EN]: "💻 Need a bigger screen for a broader view?",
    [SupportedLanguagesEnum.FR]: "💻 Besoin d'un grand écran pour une vision élargie ?",
  },
  discoverCompanyVersion: {
    [SupportedLanguagesEnum.EN]: (
      <>
        Discover our company version:{" "}
        <a href="https://www.juisci.com/services">Juisci Workspace</a>.
      </>
    ),
    [SupportedLanguagesEnum.FR]: (
      <>
        Découvrez <a href="https://www.juisci.com/fr/services">Juisci Workspace</a>,
        notre version pour entreprises.
      </>
    ),
  },
  bookDemoNow: {
    [SupportedLanguagesEnum.EN]: "🌟 Book your demo now\nand enhance your team's scientific exploration",
    [SupportedLanguagesEnum.FR]: "🌟 Planifiez votre démo dès maintenant\net enrichissez l'aventure scientifique de votre équipe",
  },
};

export default function BrowserPage() {
  useEffect(() => {
    disableScroll.off();
  });

  return (
    <PageWrapper>
      <div className="background" />
      <InnerContent>
        <img className="logo-brand" src={JuisciLogo} />
        <div className="oopsy-message">
          {LANG_TEXTS.oopsMessage[i18n.resolvedLanguage ?? SupportedLanguagesEnum.EN]}
        </div>
        <h1>{LANG_TEXTS.title[i18n.resolvedLanguage ?? SupportedLanguagesEnum.EN]}</h1>
        <img
          className="mobile-screenshot"
          src={{
            [SupportedLanguagesEnum.EN]: MobileJuisciEN,
            [SupportedLanguagesEnum.FR]: MobileJuisciFR,
          }[i18n.resolvedLanguage] ?? MobileJuisciEN}
          alt=""
        />

        <span className="text">
          {LANG_TEXTS.needBiggerScreen[i18n.resolvedLanguage ?? SupportedLanguagesEnum.EN]}
          <br />
        </span>
        <span className="text">
          {LANG_TEXTS.discoverCompanyVersion[i18n.resolvedLanguage ?? SupportedLanguagesEnum.EN]}
        </span>
        <img className="dashboard-screenshot" src={DashboardJuisci} alt="Juisci Dashboard" />

        <span className="text">{LANG_TEXTS.bookDemoNow[i18n.resolvedLanguage ?? SupportedLanguagesEnum.EN]}</span>

        <InlineWidget
          styles={{
            height: "800px",
            overflow: "hidden",
          }}
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
          }}
          url='https://calendly.com/juisci-demo/juisci-demo'
        />

        <div className="footer">
          <div className="left">
            <a href="https://www.juisci.com/terms-of-use">
              {t("Terms and conditions")}
            </a>
            <a href="https://www.juisci.com/privacy-policy">
              {t("Privacy policies")}
            </a>
          </div>
          <div className="right">
            {`© 2024, Juisci SAS. ${t("All rights reserved")}.`}
          </div>
        </div>
      </InnerContent>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  position: relative;
  min-height: 100dvh;
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 200%;
    height: 200%;
    top: 0;
    left: -50%;
    z-index: -1;
    background: #f7f8fc;
    background-image: url(${BrowserBG});
    transform:
      rotate(-10deg)
      scale(
        ${window.innerWidth > 900 ? 1.32 : window.innerWidth > 700 ? 1.45 : 1.6}
      );
  }
`;

const InnerContent = styled.div`
  box-sizing: border-box;
  background: #fff;
  width: 60%;
  min-width: 414px;
  min-height: 100dvh;
  margin: 0 auto;
  padding: 21px 80px;
  text-align: center;
  color: #212121;

  img {
    display: block;
    margin: 0 auto;

    &.logo-brand {
      height: 88px;
      margin: 48px auto;
    }
  
    &.mobile-screenshot {
      width: 20%;
      margin: 48px auto;
    }

    &.dashboard-screenshot {
      width: 90%;
    }
  }

  .oopsy-message {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  h1 {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;

    span {
      color: #ff8800;
    }
  }

  .text {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    white-space: pre-line;
    
    a {
      color: #ff8800;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .footer {
    padding: 32px 0px;
    border-top: 0.5px solid #212121;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .right {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.04em;
      text-align: left;
      color: #212121;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 8px;

      a {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.04em;
        text-align: left;
        text-decoration: none;
        color: #212121;
      }
    }
  }
`;
