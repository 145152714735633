import { useHistory, useParams } from "react-router-dom";
import TabSwitcher from "../app/TabSwitcher";
import styled from "styled-components";
import { useAppLang } from "../../domains/app/hooks/useAppLang";

export enum RoomPageTab {
  FORYOU,
  DISCOVERY,
  HIGHLIGHTS,
}

export const RoomPageWrapper = styled.div`
  min-height: 100dvh;
  background-color: #b51e84;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */ // CHECK: Necessary here?

  .safe-area-top-wrapper {
    background-color: #fff;
  }

  .nectar-scroller-container {
    padding: 24px 0;
    background-color: inherit;

    .heading {
      color: #fff;
    }
  }
`;

export function RoomTabSwitcher({ activeTab }: { activeTab: number }) {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { t } = useAppLang();

  return (
    <RoomTabsContainer>
      <TabSwitcher
        tabs={[t("common:nav.forYou"), t("Discovery"), t("Highlights")]}
        activeTab={activeTab}
        onTabChange={(index: number) => {
          const pathname = [
            `/room/foryou/${roomId}`,
            `/room/discovery/${roomId}`,
            `/room/highlights/${roomId}`,
          ][index];
          history.replace(pathname);
        }}
        noSlider
      />
    </RoomTabsContainer>
  );
}

export const RoomTabsContainer = styled.div`
  padding: 16px 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .tab-switcher-container {
    border: none;
    border-radius: 20px;
    background: var(--SECONDARY-SOFT-Soft-Yellow-Grey, #f7f6f2);
  }

  .tab-switcher-button {
    border-radius: 20px;
    color: #212121;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &.active {
      background: var(--BRAND-CORE-Juisci-Yellow, #ffc408);
      box-shadow: 1px 1px 10px 0px rgba(33, 33, 33, 0.1);
    }
  }
`;

export const RoomListHeading = styled.div`
  margin: 16px 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--BRAND-CORE-White, #fff);
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 110%; /* 17.6px */

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const RoomListWrapper = styled.div`
  padding: 0 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .no-results {
    text-align: center;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
  }
`;
