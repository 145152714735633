import { t } from "i18next";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as JuisciIcon } from "../assets/core/pipe-icon.svg";
import EditoIcon from "../assets/images/edito-icon.png";
import { ReactComponent as RedArrowIcon } from "../assets/icons/next-color.svg";
import { ReactComponent as YellowArrowIcon } from "../assets/icons/next-color-yellow.svg";

export default function ArticleExternalLink({ href, style = {}, isEditorial = false }: {
  href: string;
  style?: {};
  isEditorial?: boolean;
}) {
  return (
    <a href={href} target='_blank' onClick={e => e.stopPropagation()}>
      <Container style={style}>
        {!isEditorial
          ? <JuisciIcon style={{ position: "absolute", top: -25, height: 45 }} />
          : <img src={EditoIcon} alt="Edito" style={{ position: "absolute", top: -25, left: 15, height: 45 }} />
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <Heading>
              {!isEditorial
                ? (
                  <Trans i18nKey="originalLinkButton">
                    Find out more about<br /> this article!
                  </Trans>
                )
                : t("To go further")
              }
            </Heading>
            <Text>
              {!isEditorial
                ? t("Take me to the full scientific publication")
                : t("Read the editorial")
              }
            </Text>
          </div>
          <div>
            {!isEditorial
              ? <RedArrowIcon style={{ height: 50 }} />
              : <YellowArrowIcon style={{ height: 50 }} />
            }
          </div>
        </div>
      </Container>
    </a>
  );
};

const Container = styled.div`
  position: relative;
  padding: 30px 24px;
  border-radius: 20px;
  background: #ECF0F5;
`;

const Heading = styled.div`
  color: #4C5861;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%; /* 16px */
`;

const Text = styled.div`
  margin-top: 8px;
  color: #4C5861;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
`;
