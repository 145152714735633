import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import styled from "styled-components";
import Loader from "../../components/Loader";
import i18n from "../../config/i18n";
import { t } from "i18next";
import { getCompany } from "../../domains/company/endpoints/getCompany";
import { shareCompany } from "../../domains/company/endpoints/shareCompany";
import { getCompanyPlaylists } from "../../domains/playlist/endpoints/getCompanyPlaylists";
import CustomIcon from "../../components/CustomIcon";
import { displayToast } from "../../components/app/AppToast";
import moment from "moment";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavShare } from "../../components/app/headers/layout";
import { iosPlaySound } from "../../tools/ios";
import {
  ItemDataEventListName,
  ItemDataVariant,
  ItemsDataEvent,
  SoundEffect,
  SupportedLanguagesEnum,
} from "../../interfaces";
import { gtmItemsData } from "../../tools/reactgaEvents";
import disableScroll from "disable-scroll";
import ExpandableText from "../../components/app/ExpandableText";
import PlaylistCarousel from "../../components/app/PlaylistCarousel";
import ClampLines from "../../components/app/ClampLines";
import { ReactComponent as WebsiteIcon } from "../../assets/icons/website.svg";
import { ReactComponent as ArrowCircleRightIcon } from "../../assets/icons/arrow-circle-right.svg";

// TODO: review
interface Info {
  name: string;
  _id: string;
  description: string;
  metrics: any;
}

export default function OrganizationPage() {
  const [company, setCompany] = useState<any>();
  const [playlists, setPlaylists] = useState<any | null>(null);
  const [isTextExpanded, setTextExpanded] = useState(false);

  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ company: any }>();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    disableScroll.off();
    window.scrollTo(0, 0);

    initialize();
  }, [id]);

  async function initialize() {
    try {
      // NOTE: Used when company is cached
      if (location.state && location.state.company) {
        setCompany(location.state.company);
      } else {
        setCompany(await getCompany(id));
      }
      const { docs } = await getCompanyPlaylists(id);
      setPlaylists(docs);
    } catch (error) {
      console.error("Couldn't get company data or playlists.", error);
    }
  }

  async function handleShare() {
    try {
      if (!company) return;

      const user: any = await getCurrentUser();

      const shareText =
        i18n.resolvedLanguage === SupportedLanguagesEnum.FR
          ? `Découvrez les dernières nouvelles de ${company.name} sur Juisci !`
          : `Discover the latest news of ${company.name} on Juisci now!`;

      await shareCompany(company._id);

      await navigator.share({
        title: document.title,
        text: shareText,
        url: `/company/${company._id}?shared=${user.uid ?? "null"}`,
      });

      gtmItemsData(ItemsDataEvent.ORGANISATION_SHARE, [
        {
          item_name: company.name,
          item_list_name: ItemDataEventListName.ORGANISATION_PAGE,
          item_id: company._id,
          item_brand: null,
          item_category: null,
          item_category2: "standard",
          item_variant: ItemDataVariant.ORGANISATION,
          quantity: 1,
        },
      ]);
      displayToast(t("company profile shared"), "success");
      iosPlaySound(SoundEffect.SHARE);
    } catch (error) {
      console.error("Couldn't share playlist.", error);
      displayToast(t("toast.fail.browser"));
    }
  }

  function handleClickWebsite() {
    if (!company) return;

    gtmItemsData(ItemsDataEvent.ORGANISATION_REDIRECT, [
      {
        item_name: company.name,
        item_list_name: ItemDataEventListName.ORGANISATION_PAGE,
        item_id: company._id,
        item_brand: company.name,
        item_category: "organisation",
        item_category2: "standard",
        item_variant: ItemDataVariant.ORGANISATION,
        quantity: 1,
      },
    ]);
  }

  return (
    <>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavBack /></HeaderLinks>
        <HeaderTitle>{company?.name ?? ""}</HeaderTitle>
        <HeaderLinks><NavShare onClick={handleShare} /></HeaderLinks>
      </HeaderBoxAutoSafe>

      {company ? (
        <>
          <HeaderWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <CompanyLogo
                src={company?.images[0]?.url}
                alt={company?.name + " logo"}
              />
              <div style={{ width: "100%" }}>
                <CompanyTitle>{company?.name}</CompanyTitle>
                <CompanySubtitle>{company?.type}</CompanySubtitle>
              </div>
              <a
                href={company?.website}
                target="_blank"
                onClick={handleClickWebsite}
              >
                <WebsiteIcon />
              </a>
            </div>
            <ExpandableText
              isExpanded={isTextExpanded}
              setExpand={setTextExpanded}
            >
              <CompanyDescription>{company?.description}</CompanyDescription>
            </ExpandableText>
            {company?.video_link && (
              <VideoCard
                link={company?.video_link}
                thumbnail={`https://i3.ytimg.com/vi/${company?.video_link?.split("v=")[1]
                  }/mqdefault.jpg`}
              />
            )}
          </HeaderWrapper>

          <PlaylistCarousel
            title={company?.name + " playlists"}
            linkTo={`/playlist/company/${company._id}`} // TODO: This doesn't work
            playlists={playlists}
            backgroundColor="#FFF3CE"
          />

          <ContentWrapper>
            {company?.infos?.length > 0 ? (
              company.infos.map((info: Info, index: number) => {
                return (
                  <EventItem
                    key={`${index}--event-item`}
                    info={info}
                    company={company}
                  />
                );
              })
            ) : (
              <p>{t("No events for the moment")}</p>
            )}
          </ContentWrapper>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

interface EventItemProps {
  info?: any;
  company?: any;
}

const EventItem: React.FC<EventItemProps> = ({ info, company }) => {
  if (!info) {
    return null;
  }

  return (
    <a href={info.link} target="blank">
      <StyledEventCard>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <img src={company?.images[0]?.url} alt={company?.name + " logo"} />
          <div style={{ width: "100%" }}>
            <h5>{company.name}</h5>
            <h6>{company.type}</h6>
          </div>
          {
            <div style={{ alignSelf: "flex-start" }}>
              <div className="publication_date">
                {moment(new Date(info.creation_date)).calendar()}
              </div>
            </div>
          }
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <div className="tag">{info?.type ?? "Évènement"}</div>
        </div>

        <ClampLines lines={8}>
          <p>{info.content}</p>
        </ClampLines>

        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <ArrowCircleRightIcon />
          <p className="link">{info.cta ?? "En savoir plus"}</p>
        </div>
      </StyledEventCard>
    </a>
  );
};

const StyledEventCard = styled.div`
  background: #fff;
  padding: 16px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  border-radius: 15px;
  color: #212121;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    color: #212121;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.16px;
    margin: 0;

    &.date {
      font-family: "Inter" !important;
      font-size: 11px;
      margin: 0;
    }

    &.link {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      font-family: "Inter" !important;
      margin: 0;
    }
  }

  div.tag {
    background: #fae6f0;
    color: #ce0868;
    border-radius: 10px;
    font-size: 11px;
    font-weight: 400;
    font-family: "Inter";
    display: table;
    padding: 2px 8px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &.date {
      background: #212121;
      color: #fff;
      font-weight: 500;
    }
  }

  div.publication_date {
    color: #212121;
    font-weight: 700;
    font-size: 10px;
    line-height: 120%;
    text-align: right;
    letter-spacing: 0.02em;
    font-family: "Roboto";
    white-space: nowrap;
  }

  img {
    width: 50px;
    height: 40px;
    object-fit: contain;
  }

  h5 {
    color: #212121;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }

  h6 {
    color: #212121;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 300;
    line-height: 140%;
    margin: 0;
  }
`;

function VideoCard({ link, thumbnail }: { link: string; thumbnail: string }) {
  return (
    <a href={link} target="blank">
      <StyledVideoCard thumbnail={thumbnail}>
        <div className="tag">{t("contentType.video")}</div>
        <div className="play-icon">
          <CustomIcon iconName="play_circle" />
        </div>
      </StyledVideoCard>
    </a>
  );
}

const StyledVideoCard = styled.div<{ thumbnail?: string }>`
  position: relative;
  background-image: ${(props) => `linear-gradient(
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(0, 0, 0, 0.4) 100%
  ), url(${props.thumbnail})`};
  background-size: cover;
  height: 200px;
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .tag {
    background: #212121;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    font-family: "Inter";
    display: table;
    padding: 5px 10px;
  }
`;

const HeaderWrapper = styled.div`
  background: #fff;
  padding: 24px 22px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CompanyLogo = styled.img`
  width: auto;
  height: 40px;
  object-fit: contain;
`;

const CompanyTitle = styled.h5`
  color: #212121;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
`;

const CompanySubtitle = styled.h6`
  color: #212121;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 140%;
  margin: 0;
`;

const CompanyDescription = styled.span`
  color: #212121;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  margin: 0;
`;

const ContentWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #ff699c 0%,
    #fe5763 25%,
    #ff8a00 50%,
    #fdb955 75%,
    #ffc408 100%
  );
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
