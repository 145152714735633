import { useEffect, useRef, useState } from "react";
import { store, useAppDispatch, useAppSelector } from "../../../redux";
import { useAppLang } from "../../app/hooks/useAppLang";
import { useLazyGetArticlesListQuery } from "../../article/endpoints/getArticlesList";
import { useLazySearchArticlesQuery } from "../endpoints/searchArticles";
import { memorizeSearchArticles, updateFacets } from "../search.reducer";
import { ArticleModel } from "../../article/article.types";
import { getDateParamsFromSearchFilters } from "../utils/getDateParamsFromSearchFilters";
import { SearchFilterPublicationTypeEnum } from "../search.types";
import { displayToast } from "../../../components/app/AppToast";

export function useSearchArticles(searchValue: string) {
  // Store
  const dispatch = useAppDispatch();
  const searchFilters = useAppSelector((state) => state.search.searchFilters);
  const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
  const { appLang, t } = useAppLang();
  // State
  const [results, setResults] = useState<ArticleModel[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoadingResults, setLoadingResults] = useState(false);
  const [fallback, setFallback] = useState<ArticleModel[]>([]);
  // Query
  const [getArticlesList, getArticlesListResult] = useLazyGetArticlesListQuery();
  const [searchArticles] = useLazySearchArticlesQuery();
  // Custom
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [!!mountRef.current, () => mountRef.current = true];

  useEffect(() => {
    // NOTE: Usual selector not reliable enough, using store state instead.
    const cache = store.getState().search.cache;
    if (!isMounted) {
      if (cache.articles.results.length) {
        if (searchValue.length) {
          setResults(cache.articles.results);
          setTotal(cache.articles.total);
        } else {
          setFallback(cache.articles.results);
        }
      } else if (searchValue.length) {
        searchForResults(true);
      } else {
        loadFallback();
        searchForFacets();
      }
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length || filtersApplied) {
        searchForResults(true);
      } else {
        setResults([]);
        setTotal(0);
        if (!fallback.length) loadFallback();
      }
    }
  }, [searchValue, searchFilters]);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length) {
        searchForResults(true)
      } else {
        setFallback([]);
        setTimeout(() => {
          loadFallback();
        }, 0);
      }
    }
  }, [appLang]);

  function getParamsWithFilters(params: any) {
    params.scoring = searchFilters.scoring;

    const { afterDate, beforeDate } = getDateParamsFromSearchFilters(searchFilters);
    if (afterDate) params.afterDate = afterDate;
    if (beforeDate) params.beforeDate = beforeDate;

    if (searchFilters.publicationType !== SearchFilterPublicationTypeEnum.NO_FILTER) {
      params.articleType = searchFilters.publicationType;
    }

    if (searchFilters.impactFactor !== 0) {
      params.impactFactorMoreThan = searchFilters.impactFactor;
    }

    return params;
  }

  function loadFallback() {
    getArticlesList({
      limit: 20,
      offset: 0,
      language: appLang,
    })
      .unwrap()
      .then((data) => {
        const results = data.docs as [];
        setFallback(results);
        dispatch(memorizeSearchArticles({ results, total: 20 }));
      })
      .catch((error) => {
        console.error("Couldn't load fallback articles.", error);
      });
  }

  function searchForResults(resetResults: boolean) {
    if (resetResults) setLoadingResults(true);

    const params: any = getParamsWithFilters({
      search: searchValue,
      limit: 20,
      offset: resetResults ? 0 : results.length,
      language: appLang,
    });

    searchArticles(params)
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        const newResults = resetResults ? docs : [...results, ...docs];
        setResults(newResults);
        setTotal(total);
        setLoadingResults(false);
        dispatch(memorizeSearchArticles({ results: newResults, total }));
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't load article results.", error);
        displayToast(t("error:default"));
        setLoadingResults(false);
      });
  }

  function searchForFacets() {
    searchArticles(getParamsWithFilters({
      search: "",
      limit: 0,
      offset: 0,
      language: appLang,
    }))
      .unwrap()
      .then(({ meta: { total, facets } }) => {
        setTotal(total);
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't search articles to update facets.", error);
      });
  }

  function searchMore() {
    searchForResults(false);
  }

  return {
    results,
    fallback,
    total,
    isLoadingResults,
    isLoadingFallback: getArticlesListResult.isFetching,
    searchMore,
  };
}