import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SupportedLanguagesEnum } from "../../interfaces";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import SaveHeader from "../../components/app/headers/SaveHeader";
import Loader from "../../components/Loader";
import CustomSelect from "../../components/customSelect";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";

export default function SettingsEditLanguagePage() {
  const history = useHistory();
  const { appLang, setAppLang, t } = useAppLang();
  const [isLoading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguagesEnum>(appLang);
  const langOptions: { [key in SupportedLanguagesEnum]?: unknown } = {
    [SupportedLanguagesEnum.EN]: t("common:lang:english"),
    [SupportedLanguagesEnum.FR]: t("common:lang:french"),
    // [SupportedLanguagesEnum.IT]: t("common:lang:italian"),
    // [SupportedLanguagesEnum.ES]: t("common:lang:spanish"),
  };

  useEffect(() => {
    (async function () {
      const isAuthentified = await isUserAuthentified();
      if (!isAuthentified) return history.replace("/discovery");

      if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");
      setLoading(false);
    })();
  }, []);

  async function handleChangeLang(e: ChangeEvent<HTMLSelectElement>) {
    const lang = Object.keys(langOptions)[
      Object.values(langOptions).findIndex(value => value === e.target.value)
    ];
    setSelectedLanguage(lang as SupportedLanguagesEnum);
  }

  async function handleSubmit() {
    try {
      history.goBack();
      setAppLang(selectedLanguage);
      displayToast(t("toast.edit_settings.success"));
    } catch (error) {
      console.error("Couldn't edit language.", error);
      displayToast(t("error:default"));
    }
  }

  return (
    <>
      <SaveHeader
        title={t("Interface Language")}
        saveReady={selectedLanguage !== appLang}
        onSaveClick={handleSubmit}
      />
      {isLoading
        ? <Loader />
        : (
          <Container>
            <CustomSelect
              noEmptyOption
              defaultValue={langOptions[appLang] ?? null}
              options={Object.values(langOptions)}
              label={t("edit_language.language")}
              onChange={handleChangeLang}
            />
          </Container>
        )
      }
    </>
  );
}

const Container = styled.div`
  padding: 30px;
`;
