import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import styled from "styled-components";
import { PlaylistModel } from "../../../domains/playlist/playlist.types";
import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import { preflightUser } from "../../../domains/user/utils/preflightUser";
import { getPlaylistById } from "../../../domains/playlist/endpoints/getPlaylistById";
import { useRoomPreflightTemp } from "../../../domains/room/hooks/useRoomPreflightTemp";
import { memorizeAudioRoomPlaylists } from "../../../redux/user/user.reducer";
import disableScroll from "disable-scroll";
import Loader from "../../../components/Loader";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
  NavLang,
} from "../../../components/app/headers/layout";
import { RoomPageWrapper } from "../../../components/room/layout";
import PodcastCard from "../../../components/cards/PodcastCard";
import { ReactComponent as AudioIcon } from "../../../assets/icons/audio-icon.svg";

const topicMap: { [key: string]: string } = {
  coughcold: "room:audio.sanofiOpella.topic.coughCold",
  allergy: "room:audio.sanofiOpella.topic.allergy",
};

export default function AudioRoomTopicPage() {
  const history = useHistory();
  const { roomId, topic } = useParams<{ roomId: string; topic: string }>();
  const dispatch = useAppDispatch();
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const roomPlaylists = useAppSelector(
    (state) => state.user.cache.audioRoom.playlists
  );
  const { appLang, t } = useAppLang();
  const { isLoadingRoom, roomData, preflightUserRoom } =
    useRoomPreflightTemp(roomId);

  // NOTE: Keep commented until backend is finished
  // const langPlaylists = roomPlaylists.filter((playlist: PlaylistModel) => {
  //   return playlist.language === appLang;
  // });

  // NOTE: Temporary until the backend is finished
  const coughColdPlaylistIds = [""];
  const allergyPlaylistIds = [
    // Staging playlists
    "669f83fb0f4f05c71c45eef2", // Cambio climático y su impacto en la rinitis alérgica
    "66a27b4e4be33add69d97223", // ️Climate Change and Impact on Allergic Rhinitis

    // Production playlists
    "66ad02dea54fc354a610f3b2", // 🌎🌡️Climate Change and Impact on Allergic Rhinitis
    "66b0ad0970dbbc5360770699", // 🌎🌡️ Cambio climático y su impacto en la rinitis alérgica
  ];

  const topicPlaylistIds =
    topic === "coughcold" ? coughColdPlaylistIds : allergyPlaylistIds;

  const langPlaylists = roomPlaylists.filter((playlist: PlaylistModel) => {
    return (
      topicPlaylistIds.includes(playlist._id) && playlist.language === appLang
    );
  });

  // console.log("roomPlaylists:", roomPlaylists);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (currentRoomId) {
          scrollTo(0, 0);
          if (roomData?.playlists && !roomPlaylists.length) {
            const list = await Promise.all(
              roomData.playlists.map(async (playlistId: string) => {
                return await getPlaylistById(playlistId);
              })
            );
            dispatch(memorizeAudioRoomPlaylists(list));
          }
        }
      } catch (error) {
        console.error("Couldn't load room playlists.", error);
        history.push("/discovery");
      }
    })();
  }, [currentRoomId, appLang]);

  useEffect(() => {
    console.log("roomData:", roomData);
  }, [roomData]);

  return (
    <RoomPageWrapper
      style={{ backgroundColor: roomData?.primaryColor ?? "#042B0B" }}
    >
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <HeaderTitle>{t(topicMap[topic])}</HeaderTitle>
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      {isLoadingRoom || !roomPlaylists.length ? (
        <Loader loaderOnly />
      ) : (
        <ContentWrapper>
          {langPlaylists.map((playlist: PlaylistModel) => (
            <SectionWrapper key={playlist._id}>
              <SectionHeader>
                <div>
                  <AudioIcon />
                </div>
                <h1>{playlist.title}</h1>
              </SectionHeader>
              {playlist.playlist.map((item) => (
                <PodcastCard key={item._id} podcast={item} />
              ))}
              <hr />
            </SectionWrapper>
          ))}
        </ContentWrapper>
      )}
    </RoomPageWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  padding-bottom: calc(16px + var(--safe-area-bottom));
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  hr {
    width: 100%;
    background: #6d7f6e;
  }

  &:last-child hr {
    display: none;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  h1 {
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
`;
