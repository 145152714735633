import "./ReactotronConfig";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
import App from "./App";
import "antd-mobile/dist/antd-mobile.css";
import { isAndroid, isMobile, isIOS } from "react-device-detect";
import BrowserPage from "./BrowserPage";
import "./config/i18n";
import { Provider } from "react-redux";
import { store } from "./redux";
import { isNativeIOS } from "./tools/ios";

Sentry.init({
  dsn: "https://7f7c25ceef3d4dc8bb5999cf4058d9dd@o1418672.ingest.sentry.io/6769043",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  beforeSend(event) {
    if (window.location.href.includes("/profile")) return event;
  },
});

const mobileView =
  isMobile || (!isMobile && (isAndroid || isNativeIOS || isIOS));

console.log(window.navigator.userAgent);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {mobileView
        ? <App />
        : <BrowserPage />
      }
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
