import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { OrganisationUid, SupportedLanguagesEnum } from "../../interfaces";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { loadMarkdown } from "../../domains/app/utils/loadMarkdown";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useRoomPreflightTemp } from "../../domains/room/hooks/useRoomPreflightTemp";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import { PolicyTextStyle } from "../../components/PolicyTextStyle";
import ContentMarkdown from "../../components/ContentMarkdown";
import styled from "styled-components";
import { RoomPageWrapper } from "../../components/room/layout";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderLogoTitleTagline,
  NavBack,
  NavLang,
} from "../../components/app/headers/layout";
import { displayToast } from "../../components/app/AppToast";
import privacyDefaultMd from "../../assets/markdowns/privacy.md";
import privacyBiocodexMd from "../../assets/markdowns/privacy-biocodex.md";
import privacyTakedaEnMd from "../../assets/markdowns/privacy-takeda-en.md";
import privacyTakedaFrMd from "../../assets/markdowns/privacy-takeda-fr.md";
import termsDefaultMd from "../../assets/markdowns/terms.md";
import termsBiocodexMd from "../../assets/markdowns/terms-biocodex.md";
import termsTakedaEnMd from "../../assets/markdowns/terms-takeda-en.md";
import termsTakedaFrMd from "../../assets/markdowns/terms-takeda-fr.md";

export default function RoomTermsPrivacyPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const { appLang, t } = useAppLang();
  const [termsContent, setTermsContent] = useState<string | null>(null);
  const [privacyContent, setPrivacyContent] = useState<string | null>(null);
  const { roomData, preflightUserRoom } = useRoomPreflightTemp(roomId);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentRoomId) {
      scrollTo(0, 0);
      loadTexts();
    }
  }, [currentRoomId, appLang]);

  async function loadTexts() {
    try {
      let terms;
      let privacy;

      switch (roomData?.organisation.uid) {
        case OrganisationUid.BIOCODEX:
          terms = await loadMarkdown(termsBiocodexMd);
          privacy = await loadMarkdown(privacyBiocodexMd);
          break;
        case OrganisationUid.TAKEDA:
          if (appLang === SupportedLanguagesEnum.FR) {
            terms = await loadMarkdown(termsTakedaFrMd);
            privacy = await loadMarkdown(privacyTakedaFrMd);
          } else {
            terms = await loadMarkdown(termsTakedaEnMd);
            privacy = await loadMarkdown(privacyTakedaEnMd);
          }
          break;
        default:
          terms = await loadMarkdown(termsDefaultMd);
          privacy = await loadMarkdown(privacyDefaultMd);
          break;
      }

      setTermsContent(terms as string);
      setPrivacyContent(privacy as string);
    } catch (error) {
      console.error("Couldn't load files.", error);
      displayToast(t("error:default"));
    }
  }

  return (
    <RoomPageWrapper style={{ backgroundColor: "white" }}>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavBack /></HeaderLinks>
        <HeaderLogoTitleTagline
          logo={roomData?.organisation?.logo?.url || ''}
          title={roomData?.name || ''}
          tagline={roomData?.organisation.name || ''}
        />
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      {(!termsContent || !privacyContent) ? (
        <Loader />
      ) : (
        <Container>
          <PolicyTextStyle>
            <ContentMarkdown>{termsContent}</ContentMarkdown>
          </PolicyTextStyle>

          <hr />

          <PolicyTextStyle>
            <ContentMarkdown>{privacyContent}</ContentMarkdown>
          </PolicyTextStyle>
        </Container>
      )}
    </RoomPageWrapper>
  );
}

const Container = styled.div`
  padding: 30px 30px calc(30px + var(--safe-area-bottom));
`;
