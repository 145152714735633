import axios from "axios";
import { API_URL } from "../config";
import { getFirebaseToken } from "./firebase";
import { store } from "../redux";

export async function getDefaultHttpHeaders() {
  const headers: any = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    "ngrok-skip-browser-warning": "any",
  };

  const accessToken = await getFirebaseToken();
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

  const currentRoomId = store.getState().user.currentRoomId;
  if (currentRoomId) headers["x-juisci-room"] = currentRoomId;

  return headers;
};

export async function getApiRequestService(
  params: any | undefined = undefined
) {
  const headers = {
    ...await getDefaultHttpHeaders(),
    ...params,
  };

  return axios.create({
    baseURL: `${API_URL}`,
    headers,
  });
};
