import { UserModel } from "../../domains/user/user.types";
import { useUserConnection } from "../../domains/user/hooks/useUserConnection";
import { MessageRow } from "./layout";
import ProfilePicture from "../ProfilePicture";
import { t } from "i18next";
import { ReactComponent as CloseButtonGrey } from "../../assets/icons/close-button-grey.svg";
import { ReactComponent as CheckButtonYellow } from "../../assets/icons/check-button-yellow.svg";

export default function ConnectionRequestRow({ user, onClick }: {
  user: UserModel;
  onClick?: Function;
  onAccept?: Function;
}) {
  const { acceptRequest, rejectRequest } = useUserConnection(user);

  return (
    <>
      <MessageRow>
        <ProfilePicture height={48} user={user} withBorder />
        <div className="details" onClick={() => onClick && onClick()}>
          <span className="username">{user.firstname} {user.lastname}</span>
          <span className="subline">{t("userConnect.requestMessage")}</span>
        </div>
        <div className="actions">
          <CloseButtonGrey onClick={() => {
            rejectRequest();
          }} />
          <CheckButtonYellow onClick={() => {
            acceptRequest();
            // TODO: clear follow notification
            // props.notifications.forEach((notification) => {
            //   if (notification?.action?.follow?._id === props.request_id)
            //     deleteNotification(notification?._id);
            // });
          }} />
        </div>
      </MessageRow>
      <hr />
    </>
  );
};