import dotenv from "dotenv";
import fb from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/analytics";
import { getCurrentUser } from "../domains/user/endpoints/getCurrentUser";
import { createUser } from "../domains/user/endpoints/createUser";
import { deleteCurrentUser } from "../domains/user/endpoints/deleteCurrentUser";
import { registerUserFirstLogin } from "../domains/user/endpoints/registerUserFirstLogin";
import { getUserStartingPage } from "../domains/user/utils/getUserStartingPage";
import { updateActiveSession } from "../domains/user/endpoints/updateActiveSession";
import { push, replace } from "react-router-redirect";
import {
  iosAppleSignIn,
  iosGetFirebaseToken,
  iosGoogleSignIn,
  isNativeIOS,
} from "../tools/ios";
import { registerFirebaseToken } from "../domains/notification/endpoints/registerFirebaseToken";
import { displayToast } from "../components/app/AppToast";
import { t } from "i18next";
import {
  setLanguage,
  getTranslation,
} from "../config/fb-errors-translator/config";
import { getCurrentOS } from "../tools/utils";
import {
  androidGetFirebaseToken,
  androidSignIn,
  isNativeAndroid,
} from "../tools/android";
import { gtmAuthEvent } from "../tools/reactgaEvents";
import i18n from "../config/i18n";
import { GTMBasicEvent } from "../interfaces";
import { FIREBASE_CONFIG } from "../config";

dotenv.config();

if (fb.apps.length === 0)
  fb.initializeApp({
    ...FIREBASE_CONFIG,
  });

export const analytics = fb.analytics();

/** Firebase Auth */
export const auth = fb.auth();
fb.auth().languageCode = i18n.resolvedLanguage ?? "en";
setLanguage(i18n.resolvedLanguage ?? "en"); // Language des erreurs
const googleProvider = new fb.auth.GoogleAuthProvider();
const appleProvider = new fb.auth.OAuthProvider("apple.com");

// Wait for token to refresh
export async function getFirebaseToken() {
  if (auth.currentUser) return await auth.currentUser.getIdToken();
  else
    return new Promise((resolve, _reject) => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          // console.log("Refresh Firebase token...");
          resolve(token);
        } else {
          resolve(null);
        }
      });
    });
}

/** Firebase Cloud Messaging (Push notification) */
export let messaging;
if (fb.messaging.isSupported()) messaging = fb.messaging();

export const getFcmToken = async () => {
  if (fb.messaging.isSupported()) {
    await messaging
      .getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      })
      .then(async (currentToken) => {
        if (currentToken) {
          localStorage.setItem("fb_token", currentToken);
          const accessToken = await getFirebaseToken();
          // TODO: missing catch.
          registerFirebaseToken(accessToken, currentToken);
        } else {
          // Show permission request UI
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export const onMessageListener = () => {
  if (fb.messaging.isSupported()) {
    messaging.onMessage((payload) => {
      var notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
      notification.onclick = function (event) {
        event.preventDefault(); // empêcher le navigateur de passer le focus sur l'onglet de la navigation
        window.location.pathname = payload.data.link;
      };
    });
  }
};

export const initFirebaseCloudMessaging = async () => {
  const accessToken = await getFirebaseToken();
  const app_os = getCurrentOS();

  if (isNativeIOS || window.services) {
    if (app_os === "ios") {
      setTimeout(() => {
        iosGetFirebaseToken();
        const ios_fb_token = localStorage.getItem("ios_fb_token");
        // TODO: missing catch.
        registerFirebaseToken(accessToken, ios_fb_token);
      }, 10000);
    }

    if (app_os === "android") {
      setTimeout(() => {
        const android_fb_token = androidGetFirebaseToken();
        console.log("android_fb_token", android_fb_token);
        // TODO: missing catch.
        registerFirebaseToken(accessToken, android_fb_token);
      }, 10000);
    }
  } else {
    ////// FCM NOTIFICATIONS WEB APP //////
    if ("Notification" in window) Notification.requestPermission();

    // Getting authorization token...
    getFcmToken();

    // Setting our listener when on window...
    onMessageListener();
  }
};

/** OAuth handler & catcher */
const authHandler = async (res) => {
  const { user, additionalUserInfo } = res;
  const { profile, providerId } = additionalUserInfo;

  /** Data format depends of provider */
  const data = {
    uid: user.uid,
    email: profile.email,
    firstname: providerId === "google.com" ? profile.given_name : "",
    lastname: providerId === "google.com" ? profile.family_name : "",
    device: getCurrentOS(),
  };

  if (additionalUserInfo.isNewUser) {
    const createUserRes = await createUser(data);
    if (!createUserRes) {
      console.error("[firebase.js] Error creating user");
      throw new Error("[firebase.js] Error creating user");
    }

    gtmAuthEvent(GTMBasicEvent.AUTH_SIGNUP, user.uid);
    push("/signup/onboarding");
  } else {
    const user = await getCurrentUser();
    gtmAuthEvent(GTMBasicEvent.AUTH_LOGIN, user.uid);
    updateActiveSession("true");

    if (localStorage.getItem("_cache_notification")) {
      replace(
        "/profile/notifications/" + localStorage.getItem("_cache_notification")
      );
    } else if (localStorage.getItem("authRedirectPath")) {
      replace(localStorage.getItem("authRedirectPath"));
      localStorage.removeItem("authRedirectPath");
    } else {
      if (!user.meta.firstLogin) {
        await registerUserFirstLogin();
        return replace("/login/video");
      }
      const path = await getUserStartingPage(user);
      replace(path ?? "/foryou");
    }
  }
};

const authCatcher = (error) => {
  console.log(error);
  if (error) {
    displayToast(getTranslation(error?.code));
  }
};

/** Google OAuth */
export const loginWithGoogle = () => {
  if (isNativeIOS) iosAuthSignIn("google");
  else if (isNativeAndroid) androidAuthSignIn();
  else popupAuthGoogle();
};

const androidAuthSignIn = async () => {
  try {
    await androidSignIn();
    const checkCallback = setInterval(() => {
      console.log(JSON.parse(localStorage.getItem("android_signin_callback")));
      if (!!localStorage.getItem("android_signin_callback")) {
        /** Clearing interval */
        clearInterval(checkCallback);

        /** Getting auth callback result */
        const callback = JSON.parse(
          localStorage.getItem("android_signin_callback")
        );
        console.log(callback);

        /** Native Android Auth */
        if (callback.success) {
          const { idToken, rawNonce } = callback;
          const credientials = googleProvider.credential({
            idToken,
            rawNonce,
          });

          auth
            .signInWithCredential(credientials)
            .then(authHandler)
            .catch(authCatcher);
        }
      } else {
        console.log("checking Android native auth callback...");
      }
    }, 1500);
  } catch (error) {
    console.error("Firebase: couldn't sign in (Android).", error);
    displayToast(error?.message);
  }
};

const popupAuthGoogle = async () => {
  auth.signInWithPopup(googleProvider).then(authHandler).catch(authCatcher);
};

/** Apple OAuth */

export const loginWithApple = async () => {
  if (isNativeIOS) iosAuthSignIn("apple");
  else popupAuthApple();
};

const iosAuthSignIn = async (provider) => {
  try {
    if (provider === "apple") await iosAppleSignIn();
    else await iosGoogleSignIn();

    const checkCallback = setInterval(() => {
      console.log(JSON.parse(localStorage.getItem("ios_signin_callback")));
      if (!!localStorage.getItem("ios_signin_callback")) {
        /** Clearing interval */
        clearInterval(checkCallback);

        /** Getting auth callback result */
        const callback = JSON.parse(
          localStorage.getItem("ios_signin_callback")
        );
        console.log(callback);

        /** Native Apple Auth */
        if (callback.success) {
          const { idToken, rawNonce } = callback;
          const credientials = (
            provider === "apple" ? appleProvider : googleProvider
          ).credential({
            idToken,
            rawNonce,
          });

          auth
            .signInWithCredential(credientials)
            .then(authHandler)
            .catch(authCatcher);
        }
      } else {
        console.log("checking iOS native auth callback...");
      }
    }, 1500);
  } catch (error) {
    console.error("Firebase: couldn't sign in (iOS).", error);
    displayToast(error?.message);
  }
};

const popupAuthApple = async () => {
  auth.signInWithPopup(appleProvider).then(authHandler).catch(authCatcher);
};

/** Basic Auth (Email/Password) */
export const loginEmailPassword = async (email, password) => {
  let isSuccess = false;
  let errorMessage = "";

  await auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(async () => {
      try {
        await auth.signInWithEmailAndPassword(email, password);
        isSuccess = true;
        initFirebaseCloudMessaging();
        updateActiveSession("true");

        const user = await getCurrentUser();

        gtmAuthEvent(GTMBasicEvent.AUTH_LOGIN, user.uid);

        if (localStorage.getItem("_cache_notification")) {
          replace(
            "/profile/notifications/" +
            localStorage.getItem("_cache_notification")
          );
        } else if (localStorage.getItem("authRedirectPath")) {
          replace(localStorage.getItem("authRedirectPath"));
          localStorage.removeItem("authRedirectPath");
        } else {
          if (!user.meta.firstLogin) {
            await registerUserFirstLogin();
            return replace("/login/video");
          }
          const path = await getUserStartingPage(user);
          replace(path ?? "/foryou");
        }
      } catch (error) {
        console.error("Couldn't submit login with email/password.", error);
        try {
          const errorPayload = JSON.parse(error.message) ?? null;
          errorMessage = errorPayload?.error?.message;
        } catch (_) {
          errorMessage = error.message;
        }
        isSuccess = false;
      }
    });
  return { isSuccess, errorMessage };
};

export const signupEmailPassword = async (email, password, data) => {
  try {
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const user = userCredential.user;
    data.uid = user?.uid;
    data.email = email;
    data.device = getCurrentOS();

    const createUserRes = await createUser(data);
    if (!createUserRes) {
      console.error("[firebase.js] Error creating user");
      throw new Error("[firebase.js] Error creating user");
    }

    gtmAuthEvent(GTMBasicEvent.AUTH_SIGNUP, user.uid);
    push("/signup/onboarding");

    return true;
  } catch (error) {
    // Deleting user if creation failed
    if (!error.code) {
      firebase.auth().currentUser.delete();
      deleteCurrentUser();
    }

    displayToast(t(error?.message));
    throw error;
  }
};

export const completeMagicLink = async (url) => {
  try {
    const email = window.localStorage.getItem("emailForSignIn");
    await auth.signInWithEmailLink(email, url);

    push("/signup/newpassword");
  } catch (error) {
    if (error) displayToast(t(error?.message));
    throw error;
  }
};

/** Firebase Auth Handler */

export const fbLogout = () => {
  auth.signOut();
};

export const fbRefresh = () => {
  auth.currentUser
    .getIdToken()
    .then(function (idToken) {
      console.log("fbRefresh", idToken);
    })
    .catch(function (error) {
      console.log("fbRefresh", error);
    });
};

/** Exported constants */

export const firebase = fb;
export default fb;
