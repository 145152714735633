import { useHistory } from "react-router-dom";
import { useCurrentUser } from "./useCurrentUser";
import { VideoFormat, VideoModel } from "../../video/video.types";
import { PlaylistModel } from "../../playlist/playlist.types";
import { useLazyGetPlaylistsQuery } from "../../../redux/user/user.service";
import { addContentToPlaylist } from "../../playlist/endpoints/addContentToPlaylist";
import { removeContentFromPlaylist } from "../../playlist/endpoints/removeContentFromPlaylist";
import { preflightUser } from "../utils/preflightUser";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { convertContentToItemData, gtmItemsData } from "../../../tools/reactgaEvents";
import { ItemDataVariant, ItemsDataEvent, SoundEffect } from "../../../interfaces";
import { iosPlaySound } from "../../../tools/ios";

export function useSaveVideo(video: VideoModel | null) {
  const history = useHistory();
  const { playlists } = useCurrentUser();
  const isSaved = playlists && playlists?.some((playlist: PlaylistModel) => {
    return playlist.playlistContent.some((content: any) => {
      return content._id === video?._id
    })
  });
  const [getPlaylists] = useLazyGetPlaylistsQuery();

  async function toggleSaveToPlaylists({ add, remove }: {
    add: string[];
    remove: string[];
  }) {
    try {
      if (!video) throw new Error("Invalid video.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.saveContent"),
      });
      if (isRedirected) return;

      await Promise.all(add.map(playlistId => addContentToPlaylist(playlistId, video._id)));
      await Promise.all(remove.map(playlistId => removeContentFromPlaylist(playlistId, video._id)));
      // Refresh user playlists
      await getPlaylists({})
        .unwrap()
        .catch((error) => {
          console.error("Couldn't get user playlists.", error);
        });

      if (add.length) {
        iosPlaySound(SoundEffect.SAVE);
        gtmItemsData(
          ItemsDataEvent.VIDEO_SAVE,
          convertContentToItemData(
            [video],
            undefined,
            video.videoFormat === VideoFormat.STORY
                ? ItemDataVariant.VIDEO_STORY
                : ItemDataVariant.VIDEO_ARTICLE,
          )
        );
      }

      displayToast(t(add.length > 0
        ? "Video saved to your playlist"
        : "this video has been removed from your playlist"
      ));
    } catch (error) {
      console.error("Couldn't save/unsave video.", error);
      displayToast(t("error:default"));
    }
  }

  return {
    isSaved,
    toggleSaveToPlaylists,
  };
}

