import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import theme from "./styles/theme";
import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";
import "./App.css";
import SplashPage from "./view/static/SplashPage";
import HomePage from "./view/home/HomePage";
import PostPage from "./view/post/PostPage";
import ErrorPage from "./view/static/ErrorPage";
import PreSignupPage from "./view/signup/PreSignupPage";
import SignupPage from "./view/signup/SignupPage";
import LoginPage from "./view/loginPage/LoginPage";
import SettingsPage from "./view/settings/SettingsPage";
import SettingsEditSecurityPage from "./view/settings/SettingsEditSecurityPage";
import PolicyPage from "./view/settings/PolicyPage";
import TermsPage from "./view/settings/TermsPage";
import RequestCertificationPage from "./view/settings/requestCertificationPage";
import GlobalSearchPage from "./view/search/GlobalSearchPage";
import CategorySearchPage from "./view/search/CategorySearchPage";
import { Redirector } from "react-router-redirect";
import ResetPage from "./view/loginPage/ResetPage";
import ConfirmResetPage from "./view/loginPage/confirmResetPage";
import TagManager from "react-gtm-module";
import PlaylistPage from "./view/profile/playlistPage";
import CreatePlaylist from "./view/profile/createPlaylist";
import ProfilePage from "./view/profile/ProfilePage";
import EditProfilePage from "./view/editProfilePage/EditProfilePage";
import EditAvatarPage from "./view/editProfilePage/editAvatarPage";
import NotificationCenter from "./view/notificationsPage/NotificationCenter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-spring-bottom-sheet/dist/style.css";
import { isUserAuthentified } from "./domains/user/utils/isUserAuthentified";
import { auth, initFirebaseCloudMessaging } from "./services/firebase";
import {
  iosGetSafeArea,
  iosRequestPushAuthorization,
  isNativeIOS,
} from "./tools/ios";
import NotificationRouter from "./view/notificationsPage/notificationRouter";
import { connect } from "react-redux";
import { articlesApi } from "./domains/article/article.api";
import PrevLoginPage from "./view/loginPage/PrevLoginPage";
import SettingsEditLanguagePage from "./view/settings/SettingsEditLanguagePage";
import JournalPage from "./view/journal/JournalPage";
import SettingsEditAccountPage from "./view/settings/SettingsEditAccountPage";
import PublicPlaylistPage from "./view/playlist/PublicPlaylistPage";
import OrganizationPage from "./view/organizationPage/OrganizationPage";
import ProfileGamification from "./view/profile/gamification/profileGamification";
import SignupOnboardingPage from "./view/signup/SignupOnboardingPage";
import PasswordPage from "./view/static/passwordPage";
import EditPreferredContentPage from "./view/editProfilePage/editPreferredContentPage";
import ProfileJournals from "./view/profile/profileJournals";
import HomeSpecialties from "./view/home/homeSpecialties";
import HomeContentPublicationsPage from "./view/home/content/HomeContentPublicationsPage";
import HomeContentPlaylistsPage from "./view/home/content/HomeContentPlaylistsPage";
import HomeContentNectarsPage from "./view/home/content/HomeContentNectarsPage";
import HomeVideos from "./view/home/homeVideos";
import VideoPage from "./view/videoPage/VideoArticlePage";
import VideoStoryFeed from "./view/videoPage/VideoStoryFeed";
import { ThemeProvider } from "@mui/material";
import { specialtiesApi } from "./redux/specialties/specialties.service";
import VideoStoryListPage from "./view/videoPage/VideoStoryListPage";
import MagicLinkPage from "./view/signupPage/magicLinkPage";
import NewPasswordPage from "./view/signupPage/newPassword";
import WelcomePage from "./view/welcome/welcomePage";
import { getCurrentUser } from "./domains/user/endpoints/getCurrentUser";
import { gtmPageView } from "./tools/reactgaEvents";
import { store } from "./redux";
import LoginVideoPage from "./view/loginPage/LoginVideoPage";
import NectarPage from "./view/nectar/NectarPage";
import GPTDiscoverPage from "./view/gpt/GPTDiscoverPage";
import GPTNectarPage from "./view/gpt/GPTNectarPage";
import GPTNewChatPage from "./view/gpt/GPTNewChatPage";
import GPTConversationPage from "./view/gpt/GPTConversationPage";
import GPTChatSourcesPage from "./view/gpt/GPTChatSourcesPage";
import GPTChatHistoryPage from "./view/gpt/GPTChatHistoryPage";
import ProfilePlaylists from "./view/profile/profilePlaylists";
import CustomRedirector from "./components/CustomRedirector";
import { SearchHistoryObserver } from "./domains/search/hooks/useSearchHistoryObserver";
import { AppToaster } from "./components/app/AppToast";
import MessageListPage from "./view/message/MessageListPage";
import NewMessagePage from "./view/message/NewMessagePage";
import DirectMessagePage from "./view/message/DirectMessagePage";
import { SocketProvider } from "./context/socket.context";
import ForYouArticleStoryPage from "./view/forYou/ForYouArticleStoryPage";
import ForYouNectarStoryPage from "./view/forYou/ForYouNectarStoryPage";
import RoomForYouPage from "./view/room/RoomForYouPage";
import RoomDiscoveryPage from "./view/room/RoomDiscoveryPage";
import RoomHighlightsPage from "./view/room/RoomHighlightsPage";
import RoomTermsPrivacyPage from "./view/room/RoomTermsPrivacyPage";
import AudioRoomHome from "./view/room/audio/AudioRoomHome";
import ForYouPage from "./view/forYou/ForYouPage";
import AudioRoomTopicPage from "./view/room/audio/AudioRoomTopicPage";
import AudioRoomPlayer from "./view/room/audio/AudioRoomPlayer";
import InfographicPage from "./view/infographicPage/InfographicPage";

class App extends React.Component {
  state = {
    appLoading: true,
    currentPath: window.location.pathname,
  };

  async componentDidMount() {
    const isAuthentified = await isUserAuthentified();
    if (isAuthentified) {
      try {
        await getCurrentUser();
      } catch (error) {
        auth.signOut();
      }

      initFirebaseCloudMessaging();
    }

    /** Fetch specialties if not in store */
    this.handleFetchSpecialties();

    // If new day, reset article views
    this.handleArticleViews();

    /** Getting iOS Device safeAreaTop */
    iosGetSafeArea();

    // request ios notifications
    if (isNativeIOS) {
      iosRequestPushAuthorization();
    }

    // GOOGLE ANALYTICS
    if (window.location.host !== "localhost:3000")
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
  }

  componentDidUpdate = () => {
    this.handlePageView();
  };

  handleFetchSpecialties = () => {
    if (this.props.specialties.isUninitialized) {
      this.props.getMedicalSpecialties();
      this.props.getTags();
    }
  };

  handleArticleViews() {
    const _nb_views_tm = new Date(
      Date.parse(localStorage.getItem("_nb_views_tm") ?? "")
    );
    const now = new Date();

    const _nb_views_exp =
      (now.getTime() - _nb_views_tm.getTime()) / (1000 * 60 * 60 * 24) > 1;

    if (_nb_views_exp) localStorage.setItem("numberViews", "0");
  }

  handlePageView = () => {
    if (window.location.pathname !== this.state.currentPath) {
      this.setState({ currentPath: window.location.pathname });
      const userUid = store.getState().user?.user?.uid || null;

      if (window.location.pathname === "/profile" && !userUid) return;

      gtmPageView();
    }
  };

  render() {
    const { appLoading } = this.state;

    if (appLoading && window.location.pathname !== "/") {
      // NOTE: Deactivate global splash screen on root page.
      return (
        <SplashPage onComplete={() => this.setState({ appLoading: false })} />
      );
    }

    return (
      <>
        <AppToaster />
        <LocaleProvider locale={enUS}>
          <ThemeProvider theme={theme}>
            <Router>
              <SearchHistoryObserver />
              <SocketProvider>
                <Redirector />
                <CustomRedirector />
                <Switch>
                  <Route exact path="/">
                    <SplashPage
                      onComplete={() => this.setState({ appLoading: false })}
                    />
                  </Route>

                  <Route path="/welcome" component={WelcomePage} />

                  <Route exact path="/discovery" component={HomePage} />
                  <Route
                    path="/discovery/specialties"
                    component={HomeSpecialties}
                  />
                  <Route path="/discovery/videos" component={HomeVideos} />
                  <Route
                    exact
                    path="/discovery/publications"
                    component={HomeContentPublicationsPage}
                  />
                  <Route
                    path="/discovery/publications/:slug"
                    component={HomeContentPublicationsPage}
                  />
                  <Route
                    path="/discovery/playlists"
                    component={HomeContentPlaylistsPage}
                  />
                  <Route
                    path="/discovery/nectars"
                    component={HomeContentNectarsPage}
                  />

                  <Route exact path="/foryou" component={ForYouPage} />
                  <Route
                    path="/foryou/story/article/:slug"
                    component={ForYouArticleStoryPage}
                  />
                  <Route
                    path="/foryou/story/nectar/:slug"
                    component={ForYouNectarStoryPage}
                  />

                  <Route exact path="/video/:slug" component={VideoPage} />
                  <Route
                    path="/videos/stories"
                    component={VideoStoryListPage}
                  />
                  <Route path="/video/story/:slug" component={VideoStoryFeed} />

                  <Route path="/post/:slug" component={PostPage} />
                  <Route path="/nectar/:slug" component={NectarPage} />
                  <Route path="/journal/:uid" component={JournalPage} />
                  <Route
                    path="/playlist/public/:id"
                    component={PublicPlaylistPage}
                  />
                  <Route path="/company/:id" component={OrganizationPage} />

                  <Route exact path="/signup" component={SignupPage} />
                  <Route path="/signup/pre" component={PreSignupPage} />
                  <Route path="/signup/magiclink" component={MagicLinkPage} />
                  <Route
                    path="/signup/newpassword"
                    component={NewPasswordPage}
                  />
                  <Route
                    path="/signup/onboarding"
                    component={SignupOnboardingPage}
                  />

                  <Route
                    exact
                    path="/invite/:inviteCode"
                    render={(props) => {
                      const { inviteCode } = props.match.params;

                      return (
                        <Redirect
                          to={{
                            pathname: "/signup",
                            search: `?inviteCode=${inviteCode}`,
                          }}
                        />
                      );
                    }}
                  />

                  <Route exact path="/login" component={LoginPage} />
                  <Route path="/login/prev" component={PrevLoginPage} />
                  <Route exact path="/login/reset" component={ResetPage} />
                  <Route
                    path="/login/reset/confirm"
                    component={ConfirmResetPage}
                  />
                  <Route path="/login/video" component={LoginVideoPage} />

                  <Route
                    exact
                    path="/profile"
                    render={(props) => <ProfilePage {...props} isMe />}
                  />

                  <Route
                    exact
                    path="/profile/user/:id"
                    render={(props) => <ProfilePage {...props} isMe={false} />}
                  />

                  <Route
                    exact
                    path="/profile/notifications"
                    component={NotificationCenter}
                  />
                  <Route
                    path="/profile/notifications/:id"
                    component={NotificationRouter}
                  />

                  <Route
                    exact
                    path="/profile/messages"
                    component={MessageListPage}
                  />
                  <Route
                    path="/profile/messages/new"
                    component={NewMessagePage}
                  />
                  <Route
                    exact
                    path="/profile/messages/conversation"
                    component={DirectMessagePage}
                  />
                  <Route
                    path="/profile/messages/conversation/:id"
                    component={DirectMessagePage}
                  />

                  <Route path="/profile/journals" component={ProfileJournals} />
                  <Route
                    exact
                    path="/profile/playlist"
                    component={ProfilePlaylists}
                  />
                  <Route
                    exact
                    path="/profile/playlist/create"
                    component={CreatePlaylist}
                  />
                  <Route
                    path="/profile/playlist/:id"
                    component={PlaylistPage}
                  />

                  {/* On garde cette route au cas où */}
                  {/* TODO: ??? */}
                  <Route
                    exact
                    path="/profile/user/:uid/playlist/:id"
                    component={PlaylistPage}
                  />

                  <Route
                    path="/profile/game/activity"
                    component={ProfileGamification}
                  />
                  <Route
                    path="/user/game/activity/:user_id"
                    component={ProfileGamification}
                  />

                  <Route exact path="/settings" component={SettingsPage} />
                  <Route
                    path="/settings/request/certification"
                    component={RequestCertificationPage}
                  />
                  <Route
                    exact
                    path="/settings/edit/profile"
                    component={EditProfilePage}
                  />
                  <Route
                    path="/settings/edit/profile/avatar"
                    component={EditAvatarPage}
                  />
                  <Route
                    path="/settings/edit/profile/preferredContent"
                    component={EditPreferredContentPage}
                  />
                  <Route
                    path="/settings/edit/security"
                    component={SettingsEditSecurityPage}
                  />
                  <Route
                    path="/settings/edit/language"
                    component={SettingsEditLanguagePage}
                  />
                  <Route
                    path="/settings/account"
                    component={SettingsEditAccountPage}
                  />

                  <Route path="/policy" component={PolicyPage} />
                  <Route path="/terms" component={TermsPage} />

                  <Route exact path="/search" component={GlobalSearchPage} />
                  <Route
                    path="/search/:category"
                    component={CategorySearchPage}
                  />

                  <Route
                    exact
                    path="/gpt/discover"
                    component={GPTDiscoverPage}
                  />
                  <Route path="/gpt/discover/:slug" component={GPTNectarPage} />
                  <Route path="/gpt/chat/new" component={GPTNewChatPage} />
                  <Route
                    path="/gpt/chat/conversation/:id"
                    component={GPTConversationPage}
                  />
                  <Route
                    path="/gpt/chat/sources"
                    component={GPTChatSourcesPage}
                  />
                  <Route
                    path="/gpt/chat/history/"
                    component={GPTChatHistoryPage}
                  />

                  <Route
                    path="/room/discovery/:roomId"
                    component={RoomDiscoveryPage}
                  />

                  <Route
                    exact
                    path="/room/foryou/:roomId"
                    component={RoomForYouPage}
                  />
                  <Route
                    path="/room/foryou/:roomId/story/article/:slug"
                    component={ForYouArticleStoryPage}
                  />
                  <Route
                    path="/room/foryou/:roomId/story/nectar/:slug"
                    component={ForYouNectarStoryPage}
                  />

                  <Route
                    path="/room/highlights/:roomId"
                    component={RoomHighlightsPage}
                  />

                  <Route
                    path="/room/terms-privacy/:roomId"
                    component={RoomTermsPrivacyPage}
                  />

                  <Route
                    exact
                    path="/room/audio/:roomId"
                    component={AudioRoomHome}
                  />

                  <Route
                    exact
                    path="/room/audio/:roomId/:topic"
                    component={AudioRoomTopicPage}
                  />

                  <Route
                    path="/room/audio/:roomId/player/:slug/:time?"
                    component={AudioRoomPlayer}
                  />

                  <Route
                    exact
                    path="/infographic/:slug"
                    component={InfographicPage}
                  />

                  <Route>
                    <ErrorPage />
                  </Route>
                </Switch>
              </SocketProvider>
            </Router>
          </ThemeProvider>
        </LocaleProvider>
      </>
    );
  }
}

function mapState(state) {
  const articles = articlesApi.endpoints.getArticlesList.select({})(state);
  const specialties = state.specialties;
  return {
    articles,
    specialties,
  };
}

const mapDispatch = {
  getArticles: articlesApi.endpoints.getArticlesList.initiate,
  getMedicalSpecialties:
    specialtiesApi.endpoints.getMedicalSpecialties.initiate,
  getTags: specialtiesApi.endpoints.getTags.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(App);
