import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { useCurrentUser } from "../../user/hooks/useCurrentUser";
import { ArticleModel } from "../../article/article.types";
import { likeArticle } from "../endpoints/likeArticle";
import { unlikeArticle } from "../endpoints/unlikeArticle";
import { preflightUser } from "../utils/preflightUser";
import { refreshCachedArticle } from "../../article/utils/refreshCachedArticle";
import { setUser } from "../../../redux/user/user.reducer";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { iosGenerateHaptic, iosPlaySound } from "../../../tools/ios";
import { HapticEffect, ItemDataEventListName, ItemDataVariant, ItemsDataEvent, SoundEffect } from "../../../interfaces";
import { convertContentToItemData, gtmItemsData } from "../../../tools/reactgaEvents";
import { filterArrayDuplicates } from "../../app/utils/filterArrayDuplicates";

export function useLikeArticle(article: ArticleModel | null, gtmItemListName?: ItemDataEventListName) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentUser } = useCurrentUser();
  const [isReady, setReady] = useState(false);
  const isLikedStore = currentUser?.likes?.some((content: { slug: string }) => {
    return content.slug === article?.slug;
  });
  const [isLikedOptimistic, setLikedOptimistic] = useState(isLikedStore);
  const [isFetching, setFetching] = useState(false);

  // Setup hook and state once article is loaded
  useEffect(() => {
    if (article) {
      setLikedOptimistic(isLikedStore);
      setReady(true);
    }
  }, [article]);

  useEffect(() => {
    if (isReady && !isFetching) {
      if (isLikedOptimistic !== isLikedStore) {
        updateStatus();
      }
    }
  }, [isLikedOptimistic, isLikedStore]);

  async function toggleLike() {
    try {
      if (!article) throw new Error("Invalid article.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.likeArticle"),
      });
      if (isRedirected) return;

      setLikedOptimistic(!isLikedOptimistic);
      iosGenerateHaptic(HapticEffect.SUCCESS);
      displayToast(t(!isLikedOptimistic
        ? "toast.success.liked"
        : "toast.success.unliked"
      ), "success");

      if (!isLikedOptimistic) {
        iosPlaySound(SoundEffect.LIKE);
        if (gtmItemListName) {
          gtmItemsData(
            ItemsDataEvent.ARTICLE_LIKE,
            convertContentToItemData(
              [article],
              gtmItemListName,
              ItemDataVariant.ARTICLE,
            )
          );
        }
      }
    } catch (error) {
      console.error("Couldn't like/unlike article.", error);
      displayToast(t("error:default"));
    }
  }

  async function updateStatus() {
    try {
      if (!article) throw new Error("Invalid article.");

      setFetching(true);
      const { likes } = isLikedOptimistic
        ? await likeArticle(article?._id)
        : await unlikeArticle(article?._id);

      setFetching(false);
      const noDuplicateLikes = filterArrayDuplicates(likes);
      dispatch(setUser({ ...currentUser, likes: noDuplicateLikes }));

      // Refresh metrics if article was cached
      refreshCachedArticle(article.slug);
    } catch (error) {
      console.error("Couldn't update likes.", error);
    }
  }

  return {
    isLiked: isLikedOptimistic,
    toggleLike,
  };
}
