import { useEffect, useRef, useState } from "react";
import { store, useAppDispatch } from "../../../redux";
import { useAppLang } from "../../app/hooks/useAppLang";
import { getRandomUsers } from "../../user/endpoints/getRandomUsers";
import { useLazySearchUsersQuery } from "../endpoints/searchUsers";
import { memorizeSearchUsers } from "../search.reducer";
import { displayToast } from "../../../components/app/AppToast";

export function useSearchUsers(searchValue: string) {
  // Store
  const dispatch = useAppDispatch();
  const { t } = useAppLang();
  // State
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoadingResults, setLoadingResults] = useState(false);
  const [isLoadingFallback, setLoadingFallback] = useState(false);
  const [fallback, setFallback] = useState([]);
  // Query
  const [searchUsers] = useLazySearchUsersQuery();
  // Custom
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [!!mountRef.current, () => mountRef.current = true];

  useEffect(() => {
    // NOTE: Usual selector not reliable enough, using store state instead.
    const cache = store.getState().search.cache;
    if (!isMounted) {
      if (cache.users.results.length) {
        if (searchValue.length) {
          setResults(cache.users.results);
          setTotal(cache.users.total);
        } else {
          setFallback(cache.users.results);
        }
      } else if (searchValue.length) {
        searchForResults(true);
      } else {
        loadFallback();
        searchForFacets();
      }
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length) {
        searchForResults(true);
      } else {
        setResults([]);
        setTotal(0);
        if (!fallback.length) loadFallback();
      }
    }
  }, [searchValue]);

  function getParamsWithFilters(params: any) {
    // NOTE: No filters for users. Change this part if filters are needed.
    return params;
  }

  function loadFallback() {
    setLoadingFallback(true);
    getRandomUsers({ limit: 50 })
      .then((data) => {
        const results = data as [];
        setFallback(results);
        dispatch(memorizeSearchUsers({ results, total: 20 }));
        setLoadingFallback(false);
      })
      .catch((error) => {
        console.error("Couldn't load fallback users.", error);
      });
  }

  function searchForResults(resetResults: boolean) {
    if (resetResults) setLoadingResults(true);

    const params: any = getParamsWithFilters({
      search: searchValue,
      limit: 20,
      offset: resetResults ? 0 : results.length,
    });

    searchUsers(params)
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        const newResults = resetResults ? docs : [...results, ...docs];
        setResults(newResults);
        setTotal(total);
        setLoadingResults(false);
        dispatch(memorizeSearchUsers({ results: newResults, total }));
      })
      .catch((error) => {
        console.error("Couldn't load user results.", error);
        displayToast(t("error:default"));
        setLoadingResults(false);
      });
  }

  function searchForFacets() {
    // NOTE: No filters for users. Change this part if filters are needed.
  }

  function searchMore() {
    searchForResults(false);
  }

  return {
    results,
    fallback,
    total,
    isLoadingResults,
    isLoadingFallback,
    searchMore,
  };
}