import { useEffect } from "react";
import { Link } from "react-router-dom";
import { UserRoomModel, UserRoomType } from "../../domains/user/user.types";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLazyGetUserRoomsQuery } from "../../domains/user/endpoints/getUserRooms";
import { filterArrayDuplicates } from "../../domains/app/utils/filterArrayDuplicates";
import SafeAreaTopWrapper from "./SafeAreaTopWrapper";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as AudioIcon } from "../../assets/icons/audio-black.svg";
import { ReactComponent as ArrowCircleYellow } from "../../assets/icons/arrow-circle-yellow.svg";

export default function RoomsMenu({ isOpen }: { isOpen: boolean }) {
  const { rooms } = useCurrentUser();
  const [getUserRooms] = useLazyGetUserRoomsQuery();

  useEffect(() => {
    (async function () {
      try {
        await getUserRooms();
      } catch (error) {
        console.error("Couldn't load user rooms.", error);
      }
    })();
  }, []);

  return (
    <SlideContainer className={isOpen ? "open" : ""}>
      <FixedContainer>
        <SafeAreaTopWrapper>
          <InnerContainer>
            <span className="heading">{t("Your Rooms")}</span>
            <div className="link-list">
              {filterArrayDuplicates(
                rooms,
                (a: UserRoomModel, b: UserRoomModel) => a._id === b._id,
              )
                .map((room: UserRoomModel) => {
                  const pathname =
                    room.type === UserRoomType.AUDIO
                      ? `/room/audio/${room._id}`
                      : `/room/discovery/${room._id}`;
                  return (
                    <Link key={room._id} to={pathname}>
                      {room.organisation?.logo ? (
                        <img
                          className="logo"
                          src={room.organisation?.logo?.url ?? ""}
                          alt={room.organisation.name}
                        />
                      ) : (
                        <LogoPlaceholder />
                      )}
                      <div className="room-info">
                        <div className="room-name-container">
                          <span className="room-name">{room.name}</span>
                          {room.type === UserRoomType.AUDIO && (
                            <AudioIcon className="audio-icon" />
                          )}
                        </div>
                        <span className="room-org">
                          {t("common:by")} {room.organisation?.name}
                        </span>
                      </div>
                      <ArrowCircleYellow className="link-icon" />
                    </Link>
                  );
                })}
            </div>
            {/* <hr />
            <span className="heading">{t("Suggested Other Rooms")}</span> */}
          </InnerContainer>
        </SafeAreaTopWrapper>
      </FixedContainer>
    </SlideContainer>
  );
}

const SlideContainer = styled.div`
  top: 0;
  min-width: 0%;
  transition: min-width 0.2s ease-in;

  &.open {
    min-width: 80%;
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  width: 80%;
  max-height: 100dvh;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
  box-sizing: border-box;
  width: 80%;
  padding: 16px;

  .heading {
    display: block;
    margin-bottom: 24px;
    color: var(--BRAND-CORE-Juisci-Black, #212121);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
  }

  hr {
    margin: 24px 0;
    border-color: #d2dce2;
  }

  .link-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #212121;
      width: 100%;

      .logo {
        object-fit: contain;
        width: 30px;
        height: 30px;
        border: 1px solid #d2dce2;
        border-radius: 50%;
      }

      svg {
        width: 30px;
        height: 30px;
      }

      .room-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;
      }

      .room-name-container {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        min-width: 0;
      }

      .room-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
      }

      .room-org {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 9px;
        font-weight: 400;
      }

      .audio-icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
      }

      .link-icon {
        margin-left: auto;
        width: 30px;
        min-width: 30px;
        height: 30px;
      }
    }
  }
`;

const LogoPlaceholder = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e5e5e5;
`;
