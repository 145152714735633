import { JournalModel } from "../journal.types";
import { store } from "../../../redux";

export function getPrioritizedJournals(list: JournalModel[]) {
  const followedJournals = [...store.getState().user.user.journals].sort(
    (a: JournalModel, b: JournalModel) =>
      parseInt(b.impact_factor) - parseInt(a.impact_factor)
  );

  const notFollowedJournals = list
    .filter(
      (journal: JournalModel) =>
        !followedJournals.find(
          (followedJournal: JournalModel) => followedJournal._id === journal._id
        )
    )
    .sort(
      (a: JournalModel, b: JournalModel) =>
        parseInt(b.impact_factor) - parseInt(a.impact_factor)
    );

  let journals = [...followedJournals, ...notFollowedJournals];

  return journals;
}