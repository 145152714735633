// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../base-query";
import { SpecialtyModel, TagModel } from "../../domains/app/app.types";

export const specialtiesApi = createApi({
  reducerPath: "specialtiesApi",
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: (builder) => ({
    getMedicalSpecialties: builder.query<SpecialtyModel[], any>({
      query: () => {
        return "/tag/parents";
      },
    }),
    getTags: builder.query<TagModel[] | any, any>({
      query: () => {
        return "/tag";
      },
    }),
  }),
});
