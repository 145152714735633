import { Component } from "react";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import styled from "styled-components";
import { t } from "i18next";
import FadeIn from "react-fade-in/lib/FadeIn";
import { iosGenerateHaptic } from "../../tools/ios";
import { HapticEffect } from "../../interfaces";

class FilterSelectButton extends Component {
  state = {
    isCollapsed: true,
  };

  handleCollapse = ({ isSelected, subfilters }) => {
    if (isSelected) this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    const { specialty } = this.props;

    const subfilters = !!this.props.subfilters
      ? this.props.subfilters?.filter((el) => el.parent?.uid === specialty?.uid)
      : undefined;

    const isSelected = !!this.props[
      !!this.props.slug ? "tags" : "medical_specialties"
    ]?.find((el) => el?.uid === specialty?.uid);

    const isAllTagsSelected =
      !!subfilters && !subfilters?.find((el) => !this.props.tags.includes(el));

    if (this.props.hideTags)
      return (
        <StyledButton>
          <div
            id={`${specialty.uid}--filter-button-id`}
            className={`row ${
              isSelected && subfilters?.length ? "no-border" : ""
            }`}
            onClick={() => this.handleCollapse({ isSelected, subfilters })}
          >
            <p
              className={
                isSelected && this.state.isCollapsed && subfilters?.length
                  ? "collapsed"
                  : ""
              }
            >
              {getItemTranslation(specialty)}
            </p>
            <button
              onClick={() => {
                iosGenerateHaptic(HapticEffect.SELECTION);
                this.props.handleSelect(specialty);
              }}
              className={isSelected ? "selected" : ""}
            >
              {t(isSelected ? "common:action.unselect" : "common:action.select")}
            </button>
          </div>
          {isSelected && <div className='border-bottom' />}
        </StyledButton>
      );

    return (
      <StyledButton>
        <div
          id={`${specialty.uid}--filter-button-id`}
          className={`row ${
            isSelected && subfilters?.length ? "no-border" : ""
          }`}
          onClick={() => this.handleCollapse({ isSelected, subfilters })}
        >
          <p
            className={
              isSelected && this.state.isCollapsed && subfilters?.length
                ? "collapsed"
                : ""
            }
          >
            {getItemTranslation(specialty)}
          </p>
          <button
            onClick={() => {
              iosGenerateHaptic(HapticEffect.SELECTION);
              this.props.handleSelect(specialty);
            }}
            className={isSelected ? "selected" : ""}
          >
            {t(isSelected ? "common:action.unselect" : "common:action.select")}
          </button>
        </div>
        {isSelected && subfilters?.length && this.state.isCollapsed ? (
          <FadeIn
            visible={isSelected && subfilters?.length && this.state.isCollapsed}
            transitionDuration={500}
          >
            <div className='content-section with-tags'>
              <div className='tags-flex'>
                <div
                  className={`tag block ${isAllTagsSelected ? "selected" : ""}`}
                  onClick={() => this.props.handleSubselect("all", specialty)}
                >
                  {t("All subspecialties")}
                </div>
              </div>
              <div className='tags-flex'>
                {subfilters.map((tag, index) => (
                  <div
                    key={tag._id + "--tag-flex-key" + index}
                    className={`tag ${
                      // this.props.tags?.includes(tag) ? "selected" : ""
                      this.props.tags?.some((el) => el.uid === tag.uid)
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      iosGenerateHaptic(HapticEffect.SELECTION);
                      this.props.handleSubselect(tag);
                    }}
                  >
                    {getItemTranslation(tag)}
                  </div>
                ))}
              </div>
            </div>
          </FadeIn>
        ) : (
          <></>
        )}

        {isSelected && subfilters?.length && !this.state.isCollapsed ? (
          <div className='border-bottom' />
        ) : (
          <></>
        )}
      </StyledButton>
    );
  }
}

export default FilterSelectButton;

const StyledButton = styled.div`
  .border-bottom {
    border-bottom: 1px solid #212121;
  }

  .row.no-border {
    border: none;
  }

  .content-section.with-tags {
    padding: 0 0 24px;
    border-bottom: 1px solid #212121;
  }

  .tag.block {
    margin-bottom: 8px;

    &.selected {
      color: #ce0868;
      background: #fae6f0;
    }
  }

  .collapsed {
    color: #ff8800 !important;
    transition: color ease-in 0.3s;
  }
`;
