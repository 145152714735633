import { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { getPlaylistById } from "../../../domains/playlist/endpoints/getPlaylistById";
import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import { preflightUser } from "../../../domains/user/utils/preflightUser";
import { useRoomPreflightTemp } from "../../../domains/room/hooks/useRoomPreflightTemp";
import { memorizeAudioRoomPlaylists } from "../../../redux/user/user.reducer";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import Loader from "../../../components/Loader";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderLogoTitleTagline,
  NavHome,
  NavLang,
} from "../../../components/app/headers/layout";
import { RoomPageWrapper } from "../../../components/room/layout";
import ClampLines from "../../../components/app/ClampLines";
import { getPodcastBackground } from "../../../domains/room/utils/getPodcastBackground";
import { Trans } from "react-i18next";
import { ReactComponent as CoughColdIcon } from "../../../assets/icons/cough-cold.svg";
import { ReactComponent as AllergyIcon } from "../../../assets/icons/allergy.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play-pink.svg";

export default function AudioRoomHome() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const dispatch = useAppDispatch();
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const roomPlaylists = useAppSelector(
    (state) => state.user.cache.audioRoom.playlists
  );
  const { appLang, t } = useAppLang();
  const { isLoadingRoom, roomData, preflightUserRoom } =
    useRoomPreflightTemp(roomId);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (currentRoomId) {
          scrollTo(0, 0);

          if (!roomPlaylists.length) {
            if (roomData?.playlists && !roomPlaylists.length) {
              const list = await Promise.all(
                roomData.playlists.map(async (playlistId: string) => {
                  return await getPlaylistById(playlistId);
                })
              );
              dispatch(memorizeAudioRoomPlaylists(list));
            }
          }
        }
      } catch (error) {
        console.error("Couldn't load room playlists.", error);
        history.push("/discovery");
      }
    })();
  }, [currentRoomId, appLang]);

  if (isLoadingRoom) return <Loader />;

  return (
    <RoomPageWrapper
      style={{ backgroundColor: roomData?.primaryColor ?? "#042B0B" }}
    >
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavHome />
        </HeaderLinks>
        <HeaderLogoTitleTagline
          logo={roomData?.organisation?.logo?.url || ""}
          title={roomData?.name || ""}
          tagline={roomData?.organisation.name || ""}
        />
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      <ContentWrapper>
        <h1>{t("room:audio.sanofiOpella.welcome")}</h1>
        <ResumeListening />
        <hr />
        <h3>{t("room:audio.sanofiOpella.pickTopic")}</h3>
        <TopicButton to={`/room/audio/${roomId}/coughcold`}>
          <CoughColdIcon />
          <span>{t("room:audio.sanofiOpella.topic.coughCold")}</span>
        </TopicButton>
        <TopicButton to={`/room/audio/${roomId}/allergy`}>
          <AllergyIcon />
          <span>{t("room:audio.sanofiOpella.topic.allergy")}</span>
        </TopicButton>

        <span className="copyright">
          <Trans className="copyright" i18nKey="room:audio.sanofiOpella.copyright" />
        </span>
      </ContentWrapper>
    </RoomPageWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  h1 {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.24px;
    margin: 0;
  }

  h3 {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    margin: 0;
  }

  hr {
    width: 100%;
    background: #6d7f6e;
  }

  span {
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 110%;
    text-transform: uppercase;
  }

  .copyright {
    color: #ff78d2;
  }
`;

const TopicButton = styled(Link)`
  background: #f7efe6;
  width: 100%;
  height: 170px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #042b0b;
    text-align: center;
    font-family: "Inter";
    font-size: 19px;
    font-weight: 700;
  }
`;

interface LastPlayedPodcast {
  slug: string;
  time: number;
  _id: string;
  title: string;
}

function ResumeListening() {
  const [lastPodcast, setLastPodcast] = useState<LastPlayedPodcast | null>(
    null
  );
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { t } = useAppLang();

  useEffect(() => {
    const savedPodcast = localStorage.getItem("lastPodcast");
    if (savedPodcast) {
      const parsedPodcast = JSON.parse(savedPodcast);
      setLastPodcast(parsedPodcast);
      setBackgroundImage(getPodcastBackground(parsedPodcast.id));
    }
  }, []);

  const handleNavigate = () => {
    if (lastPodcast) {
      const timeParam = lastPodcast.time !== 0 ? `/${lastPodcast.time}` : "";
      history.push(
        `/room/audio/${roomId}/player/${lastPodcast.slug}${timeParam}`
      );
    }
  };

  if (!lastPodcast) {
    return null;
  }

  return (
    <Wrapper>
      <span>{t("room:audio.sanofiOpella.resumeLastPodcast")}</span>
      <ResumeListeningWrapper onClick={handleNavigate}>
        <PodcastCoverImage>
          {backgroundImage && <img src={backgroundImage} />}
        </PodcastCoverImage>
        <ClampLines lines={2} className="title-text">
          {lastPodcast.title}
        </ClampLines>
        <PlayIcon />
      </ResumeListeningWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    margin: 0;
  }
`;

const ResumeListeningWrapper = styled.div`
  background: #f7efe6;
  padding: 13px;
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 16px;

  .title-text {
    flex: 1;
    color: #042b0b;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.14px;
  }
`;

const PodcastCoverImage = styled.div`
  width: 40px;
  height: 40px;
  background-color: lightcoral;
  border-radius: 7px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
