import { Component } from "react";
import { t } from "i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import styled from "styled-components";
import CustomIcon from "./CustomIcon";
import JuisciLogo from "../assets/core/juisci-logo.svg";
import { CustomButton } from "./global";
import disableScroll from "disable-scroll";
import { getItemTranslation } from "../domains/app/utils/getItemTranslation";
import { pickGradient, redirectToStore } from "../tools/utils";
import { getUserById } from "../domains/user/endpoints/getUserById";
import ProfilePicture from "./ProfilePicture";

class ShareSheet extends Component {
  state = {
    showActionSheet: false,
    user: null,
  };

  async componentDidMount() {
    await this.getUserShare();
  }

  async getUserShare() {
    const query = new URLSearchParams(window.location.search);
    const userSharing = query.get("shared");

    // Si un contenu a été partagé par un utilisateur
    if (userSharing && this.state.user !== undefined) {
      const user = await getUserById(userSharing);
      if (user) this.setState({ user, showActionSheet: true });
      else this.setState({ user: undefined, showActionSheet: true });
    }
  }

  renderContent(type = "content") {
    switch (type) {
      case "download":
        return (
          <div>
            <h3>{t("shareSheetDownloadTitle")}</h3>
            <h5>{t("shareSheetDownloadSubtitle")}</h5>
            <img src={JuisciLogo} alt='' className='juisci-logo' />
            <CustomButton
              className='xsmall custom-button'
              onClick={() => redirectToStore()}
            >
              {t("shareSheetDownloadButton")}
            </CustomButton>
          </div>
        );

      default:
        return (
          <div>
            <h3>
              {this.state.user?.firstname
                ? `${this.state.user.firstname} ${this.state.user.lastname[0]}.`
                : t("A peer")}
            </h3>
            <h5>
              {type === "profile"
                ? t("shareSheetProfileType")
                : type === "playlist"
                ? t("shareSheetPlaylistType")
                : t("shareSheetHasShared")}
            </h5>
            <div className='user-informations'>
              {!this.state.user?.firstname ? (
                <CustomIcon
                  scale={1.5}
                  iconName='user-circle'
                  color='#000000'
                />
              ) : (
                <ProfilePicture
                  user={this.state.user}
                  height={48}
                  style={{
                    background: pickGradient(
                      this.state.user.firstname.length % 5
                    ),
                    margin: 0,
                    padding: 0,
                  }}
                />
              )}
              {this.state.user?.firstname && (
                <p>
                  <b>
                    {getItemTranslation(this.state.user.profession)}
                  </b>
                  <br />
                  {getItemTranslation(this.state.user.main_specialty)}
                </p>
              )}
            </div>
          </div>
        );
    }
  }

  handleClose = () => {
    disableScroll.off();
    this.setState({
      showActionSheet: false,
    });
  };

  render() {
    return (
      <div>
        <BottomSheet
          maxHeight={window.innerHeight * 0.8}
          open={this.state.showActionSheet}
          onDismiss={() => this.handleClose()}
        >
          <StyledSheetContent>
            <div className='close-area'>
              <CustomIcon
                iconName='close_alt'
                color='#90A4AE'
                onClick={() => this.handleClose()}
              />
            </div>
            {this.renderContent(this.props.type)}
          </StyledSheetContent>
        </BottomSheet>
      </div>
    );
  }
}

export default ShareSheet;

const StyledSheetContent = styled.div`
  background: #ffffff;
  padding: 4px 48px 64px;
  box-sizing: border-box;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .close-area {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.025em;
    text-align: center;
    margin: 4px 0;
  }

  h5 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.0001em;
    text-align: center;
    margin: 0;
  }

  .juisci-logo {
    width: 100%;
    margin: 32px 0 21px;
    height: 64px;
    object-fit: contain;
  }

  .custom-button {
    margin: auto;
    display: block;
  }

  .user-informations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    margin-top: 32px;

    p {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0;
    }
  }
`;
